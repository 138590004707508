import React, { FC } from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import styled from "styled-components";

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

interface LoadingProps {
  size?: string | number;
}

const LoadingComponent: FC<LoadingProps> = ({ size }) => {
  const antIcon = <LoadingOutlined style={{ fontSize: size }} spin />;
  return (
    <LoadingContainer>
      <Spin indicator={antIcon} />
    </LoadingContainer>
  );
};

LoadingComponent.defaultProps = {
  size: 32,
};

export default LoadingComponent;
