import { fetchRankings } from "./ranking-services";
import { OnFetchRankings, OnFetchRankingsError } from "./ranking-events";
import { handleError } from "./../../shared/utils";

export const fetchRankingsAction = async () => {
  let response;

  try {
    response = await fetchRankings();
  } catch (error) {
    handleError(error);
    OnFetchRankingsError.dispatch(error);
  }

  if (response) {
    const json = await response.json();

    OnFetchRankings.dispatch(json);
  }
};
