import { MessageData } from "./messages-types";
import { handleError } from "./../../shared/utils";
import {
  OnCreateMessage,
  OnCreateMessageError,
  OnUpdateMessage,
  OnUpdateMessageError,
} from "./messages-events";
import { createMessage, updateMessage } from "./messages-services";

export const createMessageAction = async (data: MessageData) => {
  let response;

  try {
    response = await createMessage(data);
  } catch (error) {
    handleError(error);
    OnCreateMessageError.dispatch(error);
  }

  if (response) {
    const json = await response.json();

    OnCreateMessage.dispatch(json);
  }
};

export const updateMessageAction = async (id: number) => {
  let response;

  try {
    response = await updateMessage(id);
  } catch (error) {
    handleError(error);
    OnUpdateMessageError.dispatch(error);
  }

  if (response) {
    const json = await response.json();

    OnUpdateMessage.dispatch(json);
  }
};
