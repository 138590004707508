import { createEvent } from "@cobuildlab/react-simple-state";
import { Balance } from "@devsplan/wealthclub-shared";

export const OnFetchDeposits = createEvent<Balance[]>();
export const OnFetchDepositsError = createEvent();

export const OnUpdateDeposit = createEvent();
export const OnUpdateDepositError = createEvent();

export const OnDeleteDeposit = createEvent();
export const OnDeleteDepositError = createEvent();
