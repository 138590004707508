import { notification } from "antd";
import { OnLogInError, OnLogIn } from "./login-events";
import { handleError, setTokens } from "./../../shared/utils";
import { login } from "./login-services";
import { LoginData } from "./login-types";

export const loginAction = async (data: LoginData) => {
  let response;

  try {
    response = await login(data);
  } catch (error) {
    handleError(error);
    OnLogInError.dispatch(error);
  }

  if (response) {
    const json = await response.json();

    if (response.status === 401) {
      notification.error({
        message: "Datos Incorrectos",
        description: "Correo y contraseña invalidos",
      });
    }

    if (response.status === 200) {
      const token = json.access_token;

      setTokens(token, "", json.email);
    }

    OnLogIn.dispatch(json);
  }
};
