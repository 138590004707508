import { createEvent } from "@cobuildlab/react-simple-state";
import { Investment } from "@devsplan/wealthclub-shared";
import { LegacyTransaction } from "../../shared/types";

export const OnFetchInvestments = createEvent<Investment[]>();
export const OnFetchInvestmentsError = createEvent();

export const OnFetchLegacyInvestments = createEvent<LegacyTransaction[]>();
export const OnFetchLegacyInvestmentsError = createEvent();

export const OnUpdateInvestment = createEvent();
export const OnUpdateInvestmentError = createEvent();

export const OnUpdateLegacyInvestment = createEvent();
export const OnUpdateLegacyInvestmentError = createEvent();

export const OnCreateInvestment = createEvent();
export const OnCreateInvestmentError = createEvent();

export const OnDeleteInvestment = createEvent();
export const OnDeleteInvestmentError = createEvent();

// Period Investments

export const OnFetchPeriodInvestments = createEvent<Investment[]>();
export const OnFetchPeriodInvestmentsError = createEvent();

export const OnUpdatePeriodInvestment = createEvent();
export const OnUpdatePeriodInvestmentError = createEvent();

export const OnDeletePeriodInvestment = createEvent();
export const OnDeletePeriodInvestmentError = createEvent();
