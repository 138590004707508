import { api, apiUrl } from "./../../api/index";

const url = `${apiUrl}/contacts`;

export const fetchContacts = async () => {
  return new Promise(async (resolve, reject) => {
    return api(url, {
      method: "GET",
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          reject(res.statusText);
        }
      })
      .then((json) => resolve(json));
  });
};
