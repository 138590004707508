import React, { useCallback, useEffect, useState } from "react";
import { useSubscription } from "@cobuildlab/react-simple-state";
import {
  Button,
  Card,
  Form,
  Modal,
  Row,
  Table,
  Col,
  Input,
  Typography,
  notification,
  Popconfirm,
  Tooltip,
} from "antd";
import { ColumnType } from "antd/lib/table";
import { useMedia } from "react-use";
import { CustomButton } from "../../components";
import ResponsiveTable from "../../components/ResponsiveTable";
import { IS_TABLET } from "../../shared/constants";
import { Tutorial } from "../../shared/types";
import {
  fetchTutorialsAction,
  updateTutorialAction,
  createTutorialAction,
  deleteTutorialAction,
} from "./tutorials-actions";
import {
  OnFetchTutorials,
  OnUpdateTutorial,
  OnUpdateTutorialError,
  OnCreateTutorial,
  OnCreateTutorialError,
  OnDeleteTutorial,
  OnDeleteTutorialError,
} from "./tutorials-events";
import { Span } from "../../components/Span";
import { renderDate } from "../../components/Columns";

const message = "Este campo es requerido!";

const TutorialsView = () => {
  const [tutorials, setTutorials] = useState<Tutorial[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [loadingForm, setLoadingForm] = useState<boolean>(false);
  const [tutorial, setTutorial] = useState<Tutorial | undefined>();
  const [form] = Form.useForm();
  const isTablet = useMedia(IS_TABLET);
  const columns: ColumnType<Tutorial>[] = [
    {
      title: "#",
      dataIndex: "id",
      sorter: (a, b) => a.id - b.id,
      fixed: "left",
      width: 30,
    },
    {
      title: "Fecha",
      dataIndex: "created_at",
      render: renderDate,
      width: 50,
    },
    {
      title: "Título",
      dataIndex: "title",
      sorter: (a, b) => a.title.length - b.title.length,
      width: 150,
      render: (value) => (
        <Tooltip title={value}>
          <Span ellipsis width={220} lineHeight={1.5}>
            {value}
          </Span>
        </Tooltip>
      ),
    },
    {
      title: "URL video",
      dataIndex: "url",
      sorter: (a, b) => a.url.length - b.url.length,
      width: 180,
      render: (value) => (
        <Tooltip title={value}>
          <Span ellipsis width={400} lineHeight={1.5}>
            {value}
          </Span>
        </Tooltip>
      ),
    },
    {
      title: "Action",
      width: 80,
      fixed: "right",
      render: (_, record: Tutorial) => (
        <Row justify="space-between" align="middle">
          <Button type="link" onClick={() => handleOnManageTutorial(record)}>
            Editar
          </Button>
          <Popconfirm
            title={`¿Esta seguro de eliminar este tutorial?`}
            onConfirm={() => handleOnDeleteTutorial(record.id)}
            okText="Si"
            cancelText="No"
          >
            <Button type="link">Eliminar</Button>
          </Popconfirm>
        </Row>
      ),
    },
  ];

  const handleOnManageTutorial = (tutorial?: Tutorial) => {
    setTutorial(tutorial || undefined);
    form.setFieldsValue({
      title: tutorial ? tutorial.title : "",
      url: tutorial ? tutorial.url : "",
    });
    setShowModal(true);
  };

  const handleOnCreateOrUpdateTutorial = useCallback(() => {
    form.validateFields().then((values) => {
      setLoadingForm(false);
      if (tutorial) {
        updateTutorialAction({
          id: tutorial.id,
          payload: { ...values },
        });
      } else {
        createTutorialAction({ payload: { ...values } });
      }
    });
  }, [tutorial, form]);

  const handleOnDeleteTutorial = (id: number) => {
    setLoading(true);
    deleteTutorialAction({
      id: id,
    });
  };

  useEffect(() => {
    setLoading(true);
    fetchTutorialsAction();
  }, []);

  useSubscription(OnFetchTutorials, (data) => {
    if (data) {
      setLoading(false);
      setTutorials([...data.data.slice().sort((a, b) => b.id - a.id).map((t, index) => {
        return {
          ...t,
          index: index + 1,
        }
      })]);
    }
  });

  useSubscription(OnCreateTutorial, () => {
    setLoadingForm(false);
    setTutorial(undefined);
    setShowModal(false);
    fetchTutorialsAction();
    notification.success({
      message: "Creado",
      description: "Tutorial creado con exito",
    });
  });

  useSubscription(OnUpdateTutorial, () => {
    setLoadingForm(false);
    setTutorial(undefined);
    setShowModal(false);
    fetchTutorialsAction();
    notification.success({
      message: "Actualizado",
      description: "Tutorial actualizado con exito",
    });
  });

  useSubscription(OnDeleteTutorial, () => {
    setLoadingForm(false);
    setTutorial(undefined);
    setShowModal(false);
    fetchTutorialsAction();
    notification.success({
      message: "Eliminado",
      description: "Tutorial eliminado con exito",
    });
  });

  useSubscription(OnUpdateTutorialError, () => {
    setLoadingForm(false);
    setShowModal(false);
    setTutorial(undefined);
  });

  useSubscription(OnCreateTutorialError, () => {
    setLoadingForm(false);
    setShowModal(false);
    setTutorial(undefined);
  });

  useSubscription(OnDeleteTutorialError, () => {
    setLoadingForm(false);
    setShowModal(false);
    setTutorial(undefined);
  });

  return (
    <Card
      title={
        <Row justify="space-between" align="middle">
          <Typography.Title level={4} style={{ margin: 0 }}>
            Tutoriales
          </Typography.Title>
        </Row>
      }
      extra={
        <Button
          type="primary"
          size="large"
          onClick={() => {
            handleOnManageTutorial();
          }}
        >
          Crear tutorial
        </Button>
      }
    >
      {isTablet ? (
        <ResponsiveTable
          dataSource={tutorials}
          columns={columns}
          loading={loading}
        />
      ) : (
        <div>
          <Table
            bordered
            size="small"
            columns={columns}
            dataSource={tutorials}
            loading={loading}
            scroll={{ x: 1000 }}
          />
        </div>
      )}
      <Modal
        visible={showModal}
        title={tutorial ? `Editar tutorial #${tutorial.id}` : "Crear tutorial"}
        onCancel={() => {
          setShowModal(false);
          setTutorial(undefined);
        }}
        footer={null}
        width={800}
      >
        <Form layout="vertical" form={form}>
          <Row gutter={[16, 16]}>
            <Col xs={24}>
              <Form.Item
                name="title"
                label="Título"
                rules={[{ required: true, message }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24}>
              <Form.Item
                name="url"
                label="URL"
                rules={[{ required: true, message }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <CustomButton
              type="primary"
              htmlType="submit"
              loading={loadingForm}
              onClick={handleOnCreateOrUpdateTutorial}
            >
              Guardar
            </CustomButton>
          </Form.Item>
        </Form>
      </Modal>
    </Card>
  );
};

export default TutorialsView;
