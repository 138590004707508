import {
  fetchTutorials,
  updateTutorial,
  createTutorial,
  deleteTutorial,
} from "./tutorials-services";
import {
  OnFetchTutorials,
  OnFetchTutorialsError,
  OnUpdateTutorial,
  OnUpdateTutorialError,
  OnCreateTutorial,
  OnCreateTutorialError,
  OnDeleteTutorial,
  OnDeleteTutorialError,
} from "./tutorials-events";
import { handleError } from "../../shared/utils";

export const fetchTutorialsAction = async () => {
  let response: any;

  try {
    response = await fetchTutorials();
  } catch (error) {
    handleError(error);
    OnFetchTutorialsError.dispatch(error);
  }

  if (response) {
    const json = await response.json();

    OnFetchTutorials.dispatch(json);
  }
};

export const createTutorialAction = async (data: {
  payload: { title: string; url: string };
}) => {
  let response: any;

  try {
    response = await createTutorial(data);
  } catch (error) {
    handleError(error);
    OnCreateTutorialError.dispatch(error);
  }

  if (response) {
    const json = await response.json();

    OnCreateTutorial.dispatch(json);
  }
};

export const updateTutorialAction = async (data: {
  id: number;
  payload: object;
}) => {
  let response: any;

  try {
    response = await updateTutorial(data);
  } catch (error) {
    handleError(error);
    OnUpdateTutorialError.dispatch(error);
  }

  if (response) {
    const json = await response.json();

    OnUpdateTutorial.dispatch(json);
  }
};

export const deleteTutorialAction = async (data: { id: number }) => {
  let response: any;

  try {
    response = await deleteTutorial(data);
  } catch (error) {
    handleError(error);
    OnDeleteTutorialError.dispatch(error);
  }

  if (response) {
    const json = await response.json();

    OnDeleteTutorial.dispatch(json);
  }
};
