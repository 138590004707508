import { api, apiUrl } from "./../../api/index";

const url = `${apiUrl}/card_requests`;

export const fetchCardRequests = async ({
  page,
  pageSize = 10,
  search = "",
}: {
  page: number;
  pageSize?: number;
  search?: string;
}) => {
  return api(
    `${url}?page=${page}&per_page=${pageSize || 10}${
      search !== "" ? `&search=${search}` : ""
    }`,
    {
      method: "GET",
    }
  ).then((res) => res.json());
};
