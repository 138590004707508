import { api, apiUrl } from "./../../api/index";
import { NotificationData } from "./notifications-types";

const url = `${apiUrl}/notifications`;

export const createNotification = (data: NotificationData) => {
  return api(url, {
    method: "POST",
    body: JSON.stringify(data),
  });
};

export const fetchMassiveNotifications = () => {
  return api(`${url}/massive`, {
    method: "GET",
  });
};

export const deleteNotification = (id: number) => {
  return api(`${url}/${id}`, {
    method: "DELETE",
  });
};
