import React, { useCallback, useEffect, useState } from "react";
import { useSubscription } from "@cobuildlab/react-simple-state";
import { IS_TABLET } from "@devsplan/wealthclub-shared";
import { Card, Row, Col, Typography, Table, Input } from "antd";
import { ColumnType } from "antd/lib/table";
import { useMedia } from "react-use";
import {
  renderCurrency,
  renderDate,
  renderUserLink,
} from "../../components/Columns";
import ResponsiveTable from "../../components/ResponsiveTable";
import { CardRequest, Pagination } from "../../shared/types";
import moment from "moment";
import {
  OnFetchCardRequests,
  OnFetchCardRequestsError,
} from "./card-requests-events";
import { fetchCardRequestsAction } from "./card-requests-actions";
import TablePagination from "../../components/TablePagination";

const BonusView = () => {
  const [cardRequests, setCardRequests] = useState<CardRequest[]>([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [pagination, setPagination] = useState<Pagination>();

  const isTablet = useMedia(IS_TABLET);

  const columns: ColumnType<CardRequest>[] = [
    {
      title: "#",
      dataIndex: "index",
    },
    {
      title: "Fecha",
      dataIndex: "created_at",
      render: renderDate,
    },
    {
      title: "Usuario",
      dataIndex: "user_id",
      render: renderUserLink,
    },
    {
      title: "Monto",
      dataIndex: "amount",
      render: renderCurrency,
    },
  ];

  const handleOnSearch = useCallback(() => {
    setLoading(true);
    fetchCardRequestsAction({ page: 1, search });
  }, [search]);

  useEffect(() => {
    setLoading(true);
    fetchCardRequestsAction({ page: 1 });
  }, []);

  useSubscription(OnFetchCardRequests, (data) => {
    if (data) {
      setCardRequests(
        data.data
          .slice()
          .sort((x, y) => {
            const dateA = moment(x.created_at);
            const dateB = moment(y.created_at);

            return dateB.diff(dateA, "seconds");
          })
          .map((b, index) => {
            return {
              ...b,
              index: data.data.length + 1 - (index + 1),
            };
          })
      );
      setPagination(data);
      setLoading(false);
    }
  });

  useSubscription(OnFetchCardRequestsError, () => {
    setLoading(false);
  });

  return (
    <Card
      title={
        <Row justify="space-between">
          <Typography.Title level={4} style={{ margin: 0 }}>
            Solicitudes de Tarjeta
          </Typography.Title>
        </Row>
      }
    >
      <Row style={{ marginBottom: 16 }} gutter={[16, 16]}>
        <Col xs={24} sm={24} md={12}>
          <Input.Search
            placeholder="Buscar por ID, Nombre o Correo"
            size="middle"
            style={{ width: "100%" }}
            onPressEnter={handleOnSearch}
            onSearch={handleOnSearch}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            allowClear
          />
        </Col>
      </Row>
      {isTablet ? (
        <ResponsiveTable
          loading={loading}
          dataSource={cardRequests}
          columns={columns}
        />
      ) : (
        <>
          <Table
            bordered
            size="small"
            loading={loading}
            dataSource={cardRequests}
            columns={columns}
            scroll={{ x: 1000 }}
            pagination={false}
          />
          {pagination && (
            <TablePagination
              pagination={pagination}
              onChangePage={(page, pageSize) => {
                fetchCardRequestsAction({ page, pageSize });
              }}
            />
          )}
        </>
      )}
    </Card>
  );
};

export default BonusView;
