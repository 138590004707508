import { GlobalOutlined } from "@ant-design/icons";
import { useSubscription } from "@cobuildlab/react-simple-state";
import { IS_TABLET, User } from "@devsplan/wealthclub-shared";
import { Card, Col, Row, Select, Table, Typography } from "antd";
import { ColumnType } from "antd/lib/table";
import React, { useEffect, useState } from "react";
import { useMedia } from "react-use";
import { renderCurrency, renderUserLink } from "../../components/Columns";
import ResponsiveTable from "../../components/ResponsiveTable";
import { COUNTRIES, COUNTRIES_FLAGS } from "../../shared/constants";
import { Leader } from "../../shared/types";
import { fetchLeadersAction } from "./leaders-actions";
import { OnFetchLeaders, OnFetchLeadersError } from "./leaders-events";

const LeadersView = () => {
  const [leaders, setLeaders] = useState<Leader[]>([]);
  const [leadersFiltered, setLeadersFiltered] = useState<Leader[]>([]);
  const [loading, setLoading] = useState(false);
  const [country, setCountry] = useState("Todos");
  const isTablet = useMedia(IS_TABLET);
  const columns: ColumnType<Leader>[] = [
    {
      title: "#",
      dataIndex: "index",
      fixed: "left",
      width: 40,
    },
    {
      title: "Usuario",
      dataIndex: "user_id",
      fixed: "left",
      width: 70,
      render: renderUserLink,
    },
    {
      title: "Nombre",
      dataIndex: "user",
      width: 70,
      render: (value: User) => {
        return (
          <p style={{ margin: 0 }}>
            {value.name} {value.last_name}
          </p>
        );
      },
    },
    ...(country === "Todos"
      ? [
          {
            title: "País",
            width: 70,
            dataIndex: ["representant", "country"],
            render: (value: string) => {
              const Flag = COUNTRIES_FLAGS[value.toLowerCase()];

              if (Flag) {
                return (
                  <Row justify="start" align="middle">
                    <Flag width="32px" height="32px" />
                    <span style={{ marginLeft: 16 }}>{value}</span>
                  </Row>
                );
              }

              return null;
            },
          },
        ]
      : []),
    {
      title: "Referidos directos",
      dataIndex: "referrals_direct",
      width: 70,
    },
    {
      title: "Equipo",
      dataIndex: "sub_referrals",
      width: 70,
    },
    {
      title: "Volumen Legacy",
      dataIndex: "volume_legacy",
      width: 70,
      render: renderCurrency,
    },
    {
      title: "Volumen Gold",
      dataIndex: "volume_gold",
      width: 70,
      render: renderCurrency,
    },
  ];
  const CountryFlag =
    COUNTRIES_FLAGS[country.toLowerCase().replace(/ /g, "")] || null;

  useEffect(() => {
    setLoading(true);
    fetchLeadersAction();
  }, []);

  useEffect(() => {
    if (country === "Todos") {
      setLeadersFiltered([...leaders]);
    } else {
      setLeadersFiltered(
        leaders.filter((l) => {
          return (
            l.representant.country.toLowerCase().replace(/ /g, "") ===
            country.toLowerCase().replace(/ /g, "")
          );
        })
      );
    }
  }, [country, leaders]);

  useSubscription(OnFetchLeaders, (data) => {
    if (data) {
      setLoading(false);
      setLeaders([
        ...data.map((l, index) => {
          return {
            ...l,
            index: (data.length + 1) - (index + 1),
          };
        }),
      ]);
      setLeadersFiltered([...data]);
    }
  });

  useSubscription(OnFetchLeadersError, () => {
    setLoading(false);
  });

  return (
    <Card
      title={
        <Row justify="space-between" align="middle">
          <Col xs={12}>
            <Row align="middle">
              <Typography.Title level={4} style={{ margin: 0, marginRight: 8 }}>
                Lideres de {country === "Todos" ? "todos los paises" : country}
              </Typography.Title>
              {CountryFlag && <CountryFlag width="32px" height="32px" />}
            </Row>
          </Col>
          <Col xs={6}>
            <Select
              defaultValue="Todos"
              style={{ width: "100%" }}
              placeholder="Cambia el pais"
              onChange={(value) => {
                setCountry(value.toString());
              }}
              size="large"
            >
              <Select.Option value={"Todos"}>
                <Row justify="start" align="middle">
                  <GlobalOutlined style={{ fontSize: 18 }} />
                  <span style={{ marginLeft: 8 }}>Todos</span>
                </Row>
              </Select.Option>
              {COUNTRIES.map((c) => {
                const value = c.toLowerCase().replace(/ /g, "");
                const Flag = COUNTRIES_FLAGS[value];

                return (
                  <Select.Option value={c}>
                    <Row justify="start" align="middle">
                      <Flag width="18px" height="18px" />
                      <span style={{ marginLeft: 8 }}>{c}</span>
                    </Row>
                  </Select.Option>
                );
              })}
            </Select>
          </Col>
        </Row>
      }
    >
      {isTablet ? (
        <ResponsiveTable
          dataSource={leadersFiltered}
          columns={columns}
          loading={loading}
        />
      ) : (
        <div>
          <Table
            bordered
            size="small"
            columns={columns}
            dataSource={leadersFiltered}
            loading={loading}
            scroll={{ x: 1000 }}
          />
        </div>
      )}
    </Card>
  );
};

export default LeadersView;
