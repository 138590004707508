import React, { useState } from "react";
import { useSubscription } from "@cobuildlab/react-simple-state";
import { Modal, Button, Form, notification } from "antd";
import { FormContainer } from "../../components";
import { OnCreateNotification } from "./notifications-events";
import SendNotifications from "./SendNotifications";
import { BellOutlined } from "@ant-design/icons";

const ButtonNotifications = () => {
  const [showModal, setShowModal] = useState(false);
  const [form] = Form.useForm();

  const handleOnclick = () => {
    setShowModal(true);
  };

  useSubscription(OnCreateNotification, () => {
    setShowModal(false);
    notification.success({
      message: "Exito",
      description: "Notificación creada con exito",
    });
    form.resetFields();
  });

  return (
    <>
      <div>
        <Button
          onClick={handleOnclick}
          type="primary"
          style={{ marginRight: 10 }}
        >
          Enviar Notificación
          <BellOutlined style={{ fontSize: 16 }} />
        </Button>
        <Modal
          title="Enviar Notificación"
          width={600}
          visible={showModal}
          onCancel={() => {
            setShowModal(false);
            form.resetFields();
          }}
          cancelText="Cancelar"
          okText="Enviar"
          onOk={() => form.submit()}
          destroyOnClose
        >
          <FormContainer>
            <SendNotifications form={form} />
          </FormContainer>
        </Modal>
      </div>
    </>
  );
};

export default ButtonNotifications;
