import { api, apiUrl } from "../../api/index";

const notificationsUrl = `${apiUrl}/admin/notifications`;

export const updateNotification = (id: number, status: number) => {
  return api(`${notificationsUrl}/update/${id}`, {
    method: "PUT",
    body: JSON.stringify({ status }),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const fetchNotifications = () => {
  return api(notificationsUrl, {
    method: "GET",
  });
};

export const deleteNotification = (id: number) => {
  return api(`${notificationsUrl}/destroy/${id}`, {
    method: "DELETE",
  });
};
