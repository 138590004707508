import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Descriptions,
  Card,
  Table,
  Row,
  Col,
  Statistic,
  Button,
  Tabs,
  Typography,
} from "antd";
import {
  fetchUserDetailsAction,
  fetchUserInfoAccountantAction,
} from "./users-actions";
import {
  OnFetchUserDetails,
  OnFetchUserInfoAccountant,
  OnFetchUserInfoAccountantError,
} from "./users-events";
import { useSubscription } from "@cobuildlab/react-simple-state";
import LoadingComponent from "../../components/Loading";
import {
  renderApproved,
  renderCurrency,
  renderDate,
  renderPaymentType,
  renderPaymentTypeSettings,
  renderStatus,
  renderTextEmpty,
  renderUpperCase,
  renderUserLink,
  renderYesNo,
} from "../../components/Columns";
import { setFormatCurrency } from "../../shared/utils";
import { ArrowLeftOutlined } from "@ant-design/icons";
import moment from "moment";
import { ColumnType } from "antd/lib/table";
import { useMedia } from "react-use";
import { IS_TABLET, USER_LEVELS } from "../../shared/constants";
import ResponsiveTable from "../../components/ResponsiveTable";
import { Balance, Cycle, User } from "@devsplan/wealthclub-shared";
import ModalCycle from "../../components/ModalCycle";
import Margin from "../../components/Margin";
import { UserInfoAccountant } from "../../shared/types";

const UserDetailsView = () => {
  const [user, setUser] = useState<User | undefined>();
  // @ts-ignore
  const [loading, setLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState(false);
  const [infoAccountant, setInfoAccountant] = useState<UserInfoAccountant[]>(
    []
  );
  const [cycle, setCycle] = useState<Cycle>();
  const { id }: any = useParams();
  const history = useHistory();
  const isTablet = useMedia(IS_TABLET);

  const userBalances = user?.balance_transactions
    .slice()
    .sort((a, b) => {
      return moment(b.created_at).diff(moment(a.created_at), "seconds");
    })
    .map((balance, index) => ({
      ...balance,
      index: user.balance_transactions.length + 1 - (index + 1),
    }));

  const columnsCycles: ColumnType<Cycle>[] = [
    {
      title: "#",
      dataIndex: "number",
    },
    {
      title: "Fecha",
      dataIndex: ["data", "created_date"],
      render: renderDate,
    },
    {
      title: "Fecha de cobro",
      dataIndex: ["data", "finished_date"],
      render: renderDate,
    },
    {
      title: "Inversión",
      dataIndex: ["data", "amount"],
      render: renderCurrency,
    },
    {
      title: "Monto a cobrar",
      dataIndex: ["data", "earn_goal"],
      render: renderCurrency,
    },
    {
      title: "V.C.1N.",
      dataIndex: ["data", "volume_cycle"],
      render: renderCurrency,
    },
    {
      title: "Prior. referidos",
      dataIndex: "priority",
      render: renderYesNo,
    },
    {
      title: "Estado",
      dataIndex: "status",
      width: 150,
      render: renderStatus,
    },
    {
      title: "Action",
      render: (_, record: Cycle) => (
        <Row justify="space-between">
          <Button
            type="link"
            onClick={() => {
              handleOnClickCycleDetails(record);
            }}
          >
            Ver más
          </Button>
        </Row>
      ),
    },
  ];
  const columnsLegacyCycles: ColumnType<Cycle>[] = [
    {
      title: "#",
      dataIndex: "number",
    },
    {
      title: "Fecha",
      dataIndex: "created_at",
      render: renderDate,
    },
    {
      title: "Fecha de cobro",
      dataIndex: ["data", "finished_date"],
      render: renderDate,
    },
    {
      title: "Inversión",
      dataIndex: ["data", "amount"],
      render: renderCurrency,
    },
    {
      title: "Monto a cobrar",
      dataIndex: ["data", "earn_goal"],
      render: renderCurrency,
    },
    {
      title: "Dinero Ganado",
      dataIndex: ["data", "earned"],
      render: renderCurrency,
    },
    {
      title: "Prioridad ref",
      dataIndex: "priority",
      render: renderYesNo,
    },
    {
      title: "Nivel",
      dataIndex: ["data", "level"],
    },
    {
      title: "Referidos",
      dataIndex: ["data", "referrals"],
    },
    {
      title: "Estado",
      dataIndex: "status",
      width: 150,
      render: renderStatus,
    },
    {
      title: "Action",
      render: (_, record: Cycle) => (
        <Row justify="space-between">
          <Button
            type="link"
            onClick={() => {
              handleOnClickCycleDetails(record);
            }}
          >
            Ver más
          </Button>
        </Row>
      ),
    },
  ];
  const columnsBalances: ColumnType<Balance>[] = [
    {
      title: "#",
      dataIndex: "index",
      width: 40,
    },
    {
      title: "Código",
      dataIndex: "code",
      render: (value: string) => (value ? value : "N/A"),
    },
    {
      title: "Fecha",
      dataIndex: "created_at",
      render: renderDate,
    },
    {
      title: "Concepto",
      dataIndex: "type",
      render: renderUpperCase,
    },
    {
      title: "Detalles",
      render: (_, record) => {
        if (record.cycle_id) {
          return (
            <div>
              <Typography.Text type="secondary">
                Ganado del ciclo
              </Typography.Text>
              <Typography.Text strong>{record?.cycle?.number}</Typography.Text>
            </div>
          );
        }

        if (record.gold_purchase_id) {
          if (record.from_user_id === user?.id) {
            return (
              <div>
                <Typography.Text>Compra GOLD </Typography.Text>
                <Typography.Text type="danger" strong>
                  {setFormatCurrency(record?.gold_purchase?.amount || 0)}
                </Typography.Text>
              </div>
            );
          }

          return (
            <div>
              <Typography.Text type="secondary">
                Compra GOLD de{" "}
              </Typography.Text>
              <Typography.Text type="success" strong>
                {setFormatCurrency(record?.gold_purchase?.amount || 0)}
              </Typography.Text>
              <Typography.Text type="secondary"> del usuario </Typography.Text>
              <Typography.Text strong>{record?.from_user_id}</Typography.Text>
            </div>
          );
        }

        if (record.investment_id) {
          return (
            <div>
              <Typography.Text type="secondary">
                Comisión Legacy 2.0 de{" "}
              </Typography.Text>
              <Typography.Text type="success" strong>
                {setFormatCurrency(record?.investment?.amount || 0)}
              </Typography.Text>
              <Typography.Text type="secondary"> del usuario </Typography.Text>
              <Typography.Text type="secondary" strong>
                {record?.from_user_id}
              </Typography.Text>
            </div>
          );
        }

        return <div>Sin detalles</div>;
      },
    },
    {
      title: "Método de pago",
      dataIndex: "payment_type",
      render: renderPaymentType,
    },
    {
      title: "Monto",
      dataIndex: "balance",
      render: (value: number) => (
        <Statistic
          value={value}
          valueStyle={{
            color: value > 0 ? "#3f8600" : "#cf1322",
          }}
          prefix="$"
        />
      ),
    },
    {
      title: "Estado",
      dataIndex: "status",
      render: renderApproved,
    },
    {
      title: "Balance",
      dataIndex: "balance",
      render: (value: number, record) => {
        let balance = 0;
        // @ts-ignore
        const indexOf = userBalances ? userBalances.indexOf(record) : 0;

        console.log(userBalances, indexOf);

        if (userBalances) {
          for (let i = 0; i < indexOf; i++) {
            const b = userBalances[i];

            if (b) {
              balance += b.balance;
            }
          }
        }

        return setFormatCurrency(Math.abs(balance - (user?.balance || 0)));
      },
    },
  ];
  const columnsReferrals: ColumnType<User>[] = [
    {
      title: "ID",
      dataIndex: "id",
      render: renderUserLink,
      fixed: "left",
      width: 100,
    },
    {
      title: "Fecha de ingreso",
      dataIndex: "created_at",
      render: renderDate,
      width: 100,
    },
    {
      title: "Correo",
      dataIndex: "email",
      width: 250,
    },
    // {
    //   title: "Nivel",
    //   dataIndex: ["plan", "level"],
    //   width: 100,
    // },
    // {
    //   title: "V.A.E",
    //   dataIndex: ["plan", "user_volume_team"],
    //   render: renderCurrency,
    //   width: 100,
    // },
    // {
    //   title: "I.A.P",
    //   dataIndex: "total_invested",
    //   render: renderCurrency,
    //   width: 100,
    // },
    // {
    //   title: "Inversión actual",
    //   dataIndex: ["plan", "current_investment"],
    //   render: renderCurrency,
    //   width: 100,
    // },
    // {
    //   title: "Referidos",
    //   dataIndex: ["referrals", "length"],
    //   width: 100,
    // },
    // {
    //   title: "Inversionistas",
    //   dataIndex: "sub_childs_investors",
    //   width: 100,
    // },
    // {
    //   title: "Inversionistas Gold",
    //   dataIndex: "sub_childs_investors_gold",
    //   width: 100,
    // },
    {
      title: "Ciclo 2.0 Referente",
      dataIndex: "cycle_id",
      width: 100,
    },
    // {
    //   title: "Ciclo 1.0 Referente",
    //   dataIndex: "legacy_cycle_id",
    //   width: 100,
    // },
    // {
    //   title: "Estado",
    //   dataIndex: "status",
    //   render: renderStatus,
    //   fixed: "right",
    //   width: 100,
    // },
  ];
  const columnsInfoAccountant: ColumnType<UserInfoAccountant>[] = [
    {
      title: "#",
      dataIndex: "index",
      width: 40,
    },
    {
      title: "Fecha",
      dataIndex: "created_at",
      render: renderDate,
    },
    {
      title: "Monto",
      dataIndex: "amount",
      render: (value: number) => (
        <Statistic
          value={value}
          valueStyle={{
            color: value > 0 ? "#3f8600" : "#cf1322",
          }}
          prefix="$"
        />
      ),
    },
    {
      title: "Sistema",
      dataIndex: "type",
      render: (value: string) => {
        const types = {
          safe: "Caja Fuerte",
          legacy: "Legacy 2.0",
          legacy1: "Legacy 1.0",
        };

        // @ts-ignore
        return types[value];
      },
    },
    {
      title: "Detalles",
      dataIndex: "details",
      render: renderUpperCase,
    },
    {
      title: "Método de pago",
      dataIndex: "payment_type",
      render: (value: string) =>
        value === "Undefined" ? "-" : value.toUpperCase(),
    },
  ];

  const handleOnClickCycleDetails = (cycle: Cycle) => {
    setCycle(cycle);
    setShowModal(true);
  };

  useEffect(() => {
    if (id) {
      setLoading(true);
      fetchUserDetailsAction(id);
      fetchUserInfoAccountantAction(id);
    }
  }, [id]);

  useSubscription(
    OnFetchUserDetails,
    (user) => {
      if (user) {
        setLoading(false);
        setUser(user);
      }
    },
    []
  );

  useSubscription(OnFetchUserInfoAccountant, (data) => {
    if (data) {
      setInfoAccountant(
        data
          .slice()
          .sort((a, b) => {
            return moment(b.created_at).diff(moment(a.created_at), "seconds");
          })
          .map((record, index) => ({
            ...record,
            index: data.length + 1 - (index + 1),
          }))
      );
    }
  });

  useSubscription(OnFetchUserInfoAccountantError, () => {
    setLoading(false);
  });

  let balanceDeposit = user
    ? user.balance_deposit +
      user.balance_invested +
      user.balance_reinvested_gold
    : 0;
  let balanceEarnings = user
    ? user.balance_earning + user.balance_paid + user.balance_withdrawal
    : 0;
  let balance = (user?.balance || 0) + Math.abs(user?.balance_withdrawal || 0);

  if (balanceDeposit < 0) {
    balanceEarnings = balanceDeposit + balanceEarnings;
    balanceDeposit = 0;
  }

  if (balanceEarnings <= 0) {
    balanceEarnings = 0;
  }

  if (user && !loading) {
    return (
      <>
        <Button
          type="link"
          onClick={() => history.goBack()}
          style={{ marginBottom: 8, padding: 0 }}
        >
          <Row align="middle">
            <ArrowLeftOutlined style={{ fontSize: 16, marginRight: 8 }} />
            Volver
          </Row>
        </Button>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={12}>
            <Card title="Datos Personales">
              <Descriptions layout="vertical" bordered size="small">
                <Descriptions.Item label="Nombre">
                  {user.name} {user.last_name}
                </Descriptions.Item>
                <Descriptions.Item label="Correo">
                  {user.email}
                </Descriptions.Item>
                <Descriptions.Item label="Correo Paypal">
                  {renderTextEmpty(user.email_paypal)}
                </Descriptions.Item>
                <Descriptions.Item label="Pais">
                  {renderTextEmpty(user.country)}
                </Descriptions.Item>
                <Descriptions.Item label="City">
                  {renderTextEmpty(user.city)}
                </Descriptions.Item>
                <Descriptions.Item label="Dirección">
                  {renderTextEmpty(user.address)}
                </Descriptions.Item>
                <Descriptions.Item label="Código postal">
                  {renderTextEmpty(user.zip_code)}
                </Descriptions.Item>
                <Descriptions.Item label="Teléfono">
                  {renderTextEmpty(user.phone)}
                </Descriptions.Item>
                <Descriptions.Item label="Beneficiario">
                  {/* @ts-ignore */}
                  {user.beneficiary_name} - {user.beneficiary_email}
                </Descriptions.Item>
              </Descriptions>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={12}>
            <Card title="Datos de la Cuenta">
              <Descriptions layout="vertical" bordered size="small">
                <Descriptions.Item label="ID">{user.id}</Descriptions.Item>
                <Descriptions.Item label="Fecha de ingreso">
                  {renderDate(user?.created_at)}
                </Descriptions.Item>
                <Descriptions.Item label="V.A.E">
                  {setFormatCurrency(user?.plan.user_volume_team)}
                </Descriptions.Item>
                <Descriptions.Item label="V.A.P">
                  {setFormatCurrency(user?.total_invested)}
                </Descriptions.Item>
                <Descriptions.Item label="Nivel">
                  {USER_LEVELS[user?.plan.plan - 1]}
                </Descriptions.Item>
                <Descriptions.Item label="Plan ID">
                  {user?.plan.plan}
                </Descriptions.Item>
                <Descriptions.Item label="Referente">
                  {user?.parent_id}
                </Descriptions.Item>
                <Descriptions.Item label="Referidos">
                  {user?.referrals.length}
                </Descriptions.Item>
                <Descriptions.Item label="Código de afiliación">
                  {user?.affiliate_code}
                </Descriptions.Item>
                <Descriptions.Item label="Estado">
                  {renderStatus(user.status)}
                </Descriptions.Item>
                <Descriptions.Item label="Nivel de verificación">
                  {user?.level_verification}
                </Descriptions.Item>
              </Descriptions>
            </Card>
          </Col>
        </Row>
        <Margin top="24px">
          <Card title="Caja Fuerte" bodyStyle={{ padding: 16 }}>
            <Margin bottom="16px">
              <Descriptions layout="vertical" bordered size="small">
                <Descriptions.Item label="Método de pago">
                  {renderPaymentTypeSettings(null, user, 1, true)}
                </Descriptions.Item>
                <Descriptions.Item label="Caja fuerte bloqueada">
                  {renderYesNo(user.settings?.balance_locked)}
                </Descriptions.Item>
                <Descriptions.Item label="Retiro mínimo">
                  {setFormatCurrency(user.settings?.balance_min_withdrawal)}
                </Descriptions.Item>
              </Descriptions>
            </Margin>
            <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
              <Col xs={24} sm={24} md={6}>
                <Card>
                  <Statistic
                    title="Total en Caja Fuerte"
                    value={balance}
                    prefix="$"
                  />
                </Card>
              </Col>
              <Col xs={24} sm={24} md={6}>
                <Card>
                  <Statistic
                    title="A invertir"
                    value={balanceDeposit}
                    prefix="$"
                  />
                </Card>
              </Col>
              <Col xs={24} sm={24} md={6}>
                <Card>
                  <Statistic
                    title="Ganancias"
                    value={balanceEarnings}
                    prefix="$"
                  />
                </Card>
              </Col>
              <Col xs={24} sm={24} md={6}>
                <Card>
                  <Statistic
                    title="A cobrar"
                    value={Math.abs(user?.balance_withdrawal || 0)}
                    prefix="$"
                  />
                </Card>
              </Col>
            </Row>
            {isTablet && user?.balance_transactions ? (
              <ResponsiveTable
                // @ts-ignore
                dataSource={userBalances}
                columns={columnsBalances}
              />
            ) : (
              <Table
                bordered
                size="small"
                dataSource={userBalances}
                columns={columnsBalances}
                pagination={{
                  pageSize: 10,
                }}
              />
            )}
          </Card>
        </Margin>
        <Margin top="24px">
          <Card title="Información contable">
            {isTablet ? (
              <ResponsiveTable
                dataSource={
                  infoAccountant
                    ? infoAccountant.slice().sort((a, b) => {
                        return moment(b.created_at).diff(
                          moment(a.created_at),
                          "seconds"
                        );
                      })
                    : []
                }
                columns={columnsInfoAccountant}
              />
            ) : (
              <Table
                bordered
                size="small"
                dataSource={
                  infoAccountant
                    ? infoAccountant.slice().sort((a, b) => {
                        return moment(b.created_at).diff(
                          moment(a.created_at),
                          "seconds"
                        );
                      })
                    : []
                }
                columns={columnsInfoAccountant}
              />
            )}
          </Card>
        </Margin>
        <Margin top="24px">
          <Card title="Legacy">
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={24} md={8}>
                <Card>
                  <Statistic
                    title="Inversión actual"
                    value={user?.current_investment}
                    prefix="$"
                  />
                </Card>
              </Col>
            </Row>
            <Row
              gutter={[16, 16]}
              justify="space-between"
              style={{ marginTop: 8 }}
            >
              <Col xs={24} md={24}>
                <Tabs>
                  <Tabs.TabPane tab="Ciclos Legacy 2.0" key="0">
                    {isTablet && user?.cycles ? (
                      <ResponsiveTable
                        dataSource={user?.cycles}
                        columns={columnsCycles}
                      />
                    ) : (
                      <Table
                        bordered
                        size="small"
                        dataSource={user?.cycles}
                        columns={columnsCycles}
                      />
                    )}
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Ciclos Legacy 1.0" key="1">
                    {isTablet && user?.legacy_cycles ? (
                      <ResponsiveTable
                        dataSource={user?.legacy_cycles}
                        columns={columnsLegacyCycles}
                      />
                    ) : (
                      <Table
                        bordered
                        size="small"
                        dataSource={user?.legacy_cycles}
                        columns={columnsLegacyCycles}
                      />
                    )}
                  </Tabs.TabPane>
                </Tabs>
              </Col>
            </Row>
          </Card>
        </Margin>
        <Margin top="24px">
          <Card title="Referidos" bodyStyle={{ padding: 16 }}>
            <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
              <Col xs={24} sm={24} md={8}>
                <Card>
                  <Statistic
                    title="Total Referidos"
                    value={user?.referrals.length}
                  />
                </Card>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <Card>
                  <Statistic
                    title="Total Inversionistas Legacy"
                    value={user?.sub_childs_investors}
                  />
                </Card>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <Card>
                  <Statistic
                    title="Total Inversionistas Gold"
                    value={user?.sub_childs_investors_gold}
                  />
                </Card>
              </Col>
            </Row>
            {isTablet && user?.referrals ? (
              <ResponsiveTable
                dataSource={user?.referrals}
                columns={columnsReferrals}
              />
            ) : (
              <Table
                bordered
                size="small"
                dataSource={user?.referrals}
                columns={columnsReferrals}
              />
            )}
          </Card>
        </Margin>
        {cycle && (
          <ModalCycle
            visible={showModal}
            onCancel={() => setShowModal(false)}
            cycle={cycle}
            columns={columnsReferrals}
          />
        )}
      </>
    );
  }

  return <LoadingComponent />;
};

export default UserDetailsView;
