import { handleError } from "./../../shared/utils";
import { OnFetchReport, OnFetchReportError } from "./reports-events";
import { fetchReport } from "./reports-services";

export const fetchReportAction = async (data: {
  report: string;
  page: number;
  order: string;
  orderType: string;
  pageSize?: number;
  search?: string;
}) => {
  let response;

  try {
    response = await fetchReport(data);
  } catch (error) {
    handleError(error);
    OnFetchReportError.dispatch(error);
  }

  if (response) {
    const json = await response.json();

    OnFetchReport.dispatch(json);
  }
};
