import {
  deleteDeposit,
  fetchDeposits,
  updateDeposit,
} from "./deposits-services";
import {
  OnDeleteDeposit,
  OnDeleteDepositError,
  OnFetchDeposits,
  OnFetchDepositsError,
  OnUpdateDeposit,
  OnUpdateDepositError,
} from "./deposits-events";
import { handleError, handleResponseError } from "./../../shared/utils";

export const fetchDepositsAction = async () => {
  let response;

  try {
    response = await fetchDeposits();
  } catch (error) {
    handleError(error);
    OnFetchDepositsError.dispatch(error);
  }

  if (response) {
    const json = await response.json();

    OnFetchDeposits.dispatch(json);
  }
};

export const updateDepositAction = async (id: number, status: boolean) => {
  let response;

  try {
    response = await updateDeposit(id, { status });
  } catch (error) {
    handleError(error);
    OnUpdateDepositError.dispatch(error);
  }

  if (response) {
    const json = await response.json();

    OnUpdateDeposit.dispatch(json);
  }
};

export const deleteDepositAction = async (id: number) => {
  const response = await deleteDeposit(id);

  if (response.ok) {
    const json = await response.json();

    OnDeleteDeposit.dispatch(json);
  } else {
    handleResponseError(response);
    OnDeleteDepositError.dispatch(null);
  }
};
