import {
  Cycle,
  IS_TABLET,
  setFormatCurrency,
  User,
} from "@devsplan/wealthclub-shared";
import { Card, Col, Descriptions, Modal, Progress, Row, Table } from "antd";
import { ColumnType } from "antd/lib/table";
import moment from "moment";
import React, { FC } from "react";
import { useMedia } from "react-use";
import { renderStatus, renderYesNo } from "./Columns";
import ResponsiveTable from "./ResponsiveTable";

interface Props {
  visible: boolean;
  cycle: Cycle;
  onCancel(): void;
  columns: ColumnType<User>[];
}

const ModalCycle: FC<Props> = ({ visible, columns, onCancel, cycle }) => {
  const isTablet = useMedia(IS_TABLET);
  // @ts-ignore
  const cycleReferrals = cycle?.data.referrals_data;

  return (
    <Modal
      title={`Ciclo #${cycle?.id}`}
      visible={visible}
      onCancel={onCancel}
      footer={null}
      width={1000}
    >
      <Descriptions bordered size="small" layout="vertical">
        <Descriptions.Item label="ID">{cycle.id}</Descriptions.Item>
        <Descriptions.Item label="Numero">{cycle.number}</Descriptions.Item>
        <Descriptions.Item label="Fecha">
          {moment(cycle.created_at).format("DD/MM/YYYY")}
        </Descriptions.Item>
        <Descriptions.Item label="Inversión">
          {setFormatCurrency(cycle.data.amount)}
        </Descriptions.Item>
        <Descriptions.Item label="Monto a cobrar">
          {setFormatCurrency(cycle.data.earn_goal)}
        </Descriptions.Item>
        <Descriptions.Item label="Dinero ganado">
          {setFormatCurrency(cycle.data.earned)}
        </Descriptions.Item>
        <Descriptions.Item label="Referidos">
          {cycle.data.referrals}
        </Descriptions.Item>
        <Descriptions.Item label="V.C.1N.">
          {setFormatCurrency(cycle.data.volume_cycle)}
        </Descriptions.Item>
        <Descriptions.Item label="Retorno ">
          {cycle.data.cycle_return * 100}%
        </Descriptions.Item>
        <Descriptions.Item label="Reinversion pendiente">
          {renderYesNo(cycle.reinvestment_pending)}
        </Descriptions.Item>
        <Descriptions.Item label="Prior. referidos">
          {renderYesNo(cycle.priority)}
        </Descriptions.Item>
        <Descriptions.Item label="Estado">
          {renderStatus(cycle.status)}
        </Descriptions.Item>
      </Descriptions>
      <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
        <Col xs={8}>
          <Card title="Meta de ganancias" bodyStyle={{ padding: 24 }}>
            <Progress percent={cycle.data.earned_percent} />
          </Card>
        </Col>
        <Col xs={8}>
          <Card title="Desarrollo máximo" bodyStyle={{ padding: 24 }}>
            <Progress percent={cycle.data.max_development_percent} />
          </Card>
        </Col>
        <Col xs={8}>
          <Card title="Próximo pago" bodyStyle={{ padding: 24 }}>
            <Progress percent={cycle.data.total_days_percent} />
          </Card>
        </Col>
      </Row>
      <h2 style={{ marginTop: 16 }}>Referidos durante este ciclo</h2>
      {isTablet && cycleReferrals ? (
        <ResponsiveTable dataSource={cycleReferrals} columns={columns} />
      ) : (
        <Table
          bordered
          size="small"
          dataSource={cycleReferrals}
          columns={columns}
          scroll={{ x: 1000 }}
        />
      )}
    </Modal>
  );
};

export default ModalCycle;
