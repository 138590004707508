import { api, apiUrl } from "./../../api/index";

const ticketsUrl = `${apiUrl}/tickets`;

export const fetchTickets = () => {
  return api(`${ticketsUrl}/all`, {
    method: "GET",
  });
};

export const deleteTicket = (id: number) => {
  return api(`${ticketsUrl}/${id}`, {
    method: "DELETE",
  });
};

export const updateTicket = (id: number, status: string) => {
  return api(`${ticketsUrl}/${id}`, {
    method: "PUT",
    body: JSON.stringify({ status }),
  });
};
