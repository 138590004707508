import { useSubscription } from "@cobuildlab/react-simple-state";
import { useEffect, useState } from "react";
import { fetchKpisAction } from "../../api/kpis/kpis-actions";
import { OnFetchKpis } from "../../api/kpis/kpis-events";
import { Kpis } from "../../shared/types";

export const useKpis = (): [Kpis | undefined, boolean] => {
  const [kpis, setKpis] = useState<Kpis | undefined>(undefined);
  const [loadingKpis, setLoadingKpis] = useState<boolean>(false);

  useEffect(() => {
    const eventKpis = OnFetchKpis.get();

    if (!eventKpis) {
      setLoadingKpis(true);
      fetchKpisAction();
    } else {
      setKpis(eventKpis);
    }
  }, []);

  useSubscription(
    OnFetchKpis,
    (kpis) => {
      if (kpis) {
        setKpis(kpis);
        setLoadingKpis(false);
      }
    },
    []
  );

  return [kpis, loadingKpis];
};
