import {
  deletePayment,
  fetchDonePayments,
  fetchNextPayments,
  fetchPayments,
  updatePayment,
} from "./payments-services";
import {
  OnFetchPayments,
  OnFetchPaymentsError,
  OnUpdatePayment,
  OnUpdatePaymentError,
  OnFetchNextPayments,
  OnFetchNextPaymentsError,
  OnFetchDonePayments,
  OnFetchDonePaymentsError,
  OnDeletePayment,
  OnDeletePaymentError,
} from "./payments-events";
import { handleError } from "./../../shared/utils";

export const fetchPaymentsAction = async (data: {
  page: number;
  pageSize?: number;
}) => {
  let response;

  try {
    response = await fetchPayments(data);
  } catch (error) {
    handleError(error);
    OnFetchPaymentsError.dispatch(error);
  }

  if (response) {
    const json = await response.json();

    OnFetchPayments.dispatch(json);
  }
};

export const updatePaymentAction = async (id: number, data: object) => {
  let response;

  try {
    response = await updatePayment(id, data);
  } catch (error) {
    handleError(error);
    OnUpdatePaymentError.dispatch(error);
  }

  if (response) {
    const json = await response.json();

    OnUpdatePayment.dispatch(json);
  }
};

export const fetchNextPaymentsAction = async () => {
  let response;

  try {
    response = await fetchNextPayments();
  } catch (error) {
    handleError(error);
    OnFetchNextPaymentsError.dispatch(error);
  }

  if (response) {
    const json = await response.json();

    OnFetchNextPayments.dispatch(json);
  }
};

export const fetchDonePaymentsAction = async () => {
  let response;

  try {
    response = await fetchDonePayments();
  } catch (error) {
    handleError(error);
    OnFetchDonePaymentsError.dispatch(error);
  }

  if (response) {
    const json = await response.json();

    OnFetchDonePayments.dispatch(json);
  }
};

export const deletePaymentAction = async (id: number) => {
  let response;

  try {
    response = await deletePayment(id);
  } catch (error) {
    handleError(error);
    OnDeletePaymentError.dispatch(error);
  }

  if (response) {
    const json = await response.json();

    OnDeletePayment.dispatch(json);
  }
};
