import React, { useEffect, useState } from "react";
import { useSubscription } from "@cobuildlab/react-simple-state";
import { IS_TABLET, PaypalPayment } from "@devsplan/wealthclub-shared";
import { Card, Descriptions, Row, Table, Typography } from "antd";
import { ColumnType } from "antd/lib/table";
import { useMedia } from "react-use";
import {
  renderCurrency,
  renderDate,
  renderEmpty,
  // renderEmpty,
  renderUserLink,
} from "../../components/Columns";
import ResponsiveTable from "../../components/ResponsiveTable";
import { fetchPaypalPaymentsAction } from "./paypal-actions";
import {
  OnFetchPaypalPayments,
  OnFetchPaypalPaymentsError,
} from "./paypal-events";
import moment from "moment";

const PayPalPaymentsView = () => {
  const [paypalPayments, setPaypalPayments] = useState<PaypalPayment[]>([]);
  const [loading, setLoading] = useState(false);
  const isTablet = useMedia(IS_TABLET);

  const columns: ColumnType<PaypalPayment>[] = [
    {
      title: "#",
      dataIndex: "id",
      fixed: "left",
      width: 70,
    },
    {
      title: "Fecha",
      dataIndex: "created_at",
      render: renderDate,
      width: 100,
    },
    {
      title: "Usuario",
      dataIndex: "user_id",
      render: renderUserLink,
      width: 100,
    },
    {
      title: "Email",
      dataIndex: "email",
      width: 300,
    },
    {
      title: "Detalles",
      render: (_, record) => {
        if (record.balance_id) {
          return <div>Deposito #{record.balance_id}</div>;
        }

        if (record.investment_id) {
          return <div>Inversión #{record.investment_id} Legacy 2.0</div>;
        }

        // @ts-ignore
        if (record.period_investment_id) {
          // @ts-ignore
          return <div>Inversión #{record.period_investment_id} Legacy 4.0</div>;
        }

        if (record.legacy_investment_id) {
          return <div>Inversión Legacy 1.0 #{record.legacy_investment_id}</div>;
        }

        return null;
      },
      width: 100,
    },
    // {
    //   title: "Payment ID",
    //   dataIndex: "payment_id",
    //   render: renderEmpty,
    //   width: 170,
    // },
    {
      title: "Monto",
      dataIndex: "amount",
      render: renderCurrency,
      width: 120,
    },
    {
      title: "Estado",
      dataIndex: "status",
      width: 120,
    },
  ];

  useEffect(() => {
    setLoading(true);
    fetchPaypalPaymentsAction();
  }, []);

  useSubscription(OnFetchPaypalPayments, (data) => {
    if (data) {
      setLoading(false);
      setPaypalPayments(
        data.slice().sort((a, b) => {
          const dateA = moment(a.created_at);
          const dateB = moment(b.created_at);

          return dateB.diff(dateA, "seconds");
        })
      );
    }
  });

  useSubscription(OnFetchPaypalPaymentsError, () => {
    setLoading(false);
  });

  return (
    <Card
      title={
        <Row justify="space-between" align="middle">
          <Typography.Title level={4} style={{ margin: 0 }}>
            Pagos PayPal
          </Typography.Title>
        </Row>
      }
    >
      {isTablet ? (
        <ResponsiveTable
          dataSource={paypalPayments}
          columns={columns}
          loading={loading}
        />
      ) : (
        <Table
          size="small"
          bordered
          loading={loading}
          columns={columns}
          dataSource={paypalPayments.map((item, itemIndex) => ({
            ...item,
            key: itemIndex,
          }))}
          scroll={{ x: 1000 }}
          expandable={{
            expandIcon: undefined,
            expandRowByClick: true,
            expandedRowRender: (record) => (
              <Descriptions
                bordered
                size="small"
                layout="vertical"
                style={{ width: "100%" }}
                column={4}
              >
                <Descriptions.Item label="Nombre">
                  {record.name}
                </Descriptions.Item>
                <Descriptions.Item label="Order ID">
                  {record.order_id}
                </Descriptions.Item>
                <Descriptions.Item label="Payer ID">
                  {record.payer_id}
                </Descriptions.Item>
                <Descriptions.Item label="Payment ID">
                  {renderEmpty(record.payment_id)}
                </Descriptions.Item>
              </Descriptions>
            ),
          }}
        />
      )}
    </Card>
  );
};

export default PayPalPaymentsView;
