import React, { useCallback, useEffect, useState } from "react";
import { useSubscription } from "@cobuildlab/react-simple-state";
import {
  Button,
  Card,
  Form,
  Modal,
  Row,
  Table,
  Col,
  Input,
  Typography,
  notification,
  Popconfirm,
  Tooltip,
  Upload,
  message,
  Select,
} from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { ColumnType } from "antd/lib/table";
import { useMedia } from "react-use";
import { CustomButton } from "../../components";
import ResponsiveTable from "../../components/ResponsiveTable";
import { IS_TABLET } from "../../shared/constants";
import { Download } from "../../shared/types";
import {
  fetchDownloadsAction,
  updateDownloadAction,
  createDownloadAction,
  deleteDownloadAction,
} from "./downloads-actions";
import {
  OnFetchDownloads,
  OnUpdateDownload,
  OnUpdateDownloadError,
  OnCreateDownload,
  OnCreateDownloadError,
  OnDeleteDownload,
  OnDeleteDownloadError,
} from "./downloads-events";
import { fileUrl } from "./downloads-services";
import { Span } from "../../components/Span";
import { renderDate } from "../../components/Columns";
import { getTokens } from "../../shared/utils";
import { UploadFile } from "antd/lib/upload/interface";

const requiredMessage = "Este campo es requerido!";

const { Dragger } = Upload;

const DownloadsView = () => {
  const [downloads, setDownloads] = useState<Download[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [loadingForm, setLoadingForm] = useState<boolean>(false);
  const [download, setDownload] = useState<Download | undefined>();
  const [form] = Form.useForm();
  const isTablet = useMedia(IS_TABLET);
  const columns: ColumnType<Download>[] = [
    {
      title: "#",
      dataIndex: "id",
      sorter: (a, b) => a.id - b.id,
      fixed: "left",
      width: 30,
    },
    {
      title: "Fecha",
      dataIndex: "created_at",
      render: renderDate,
      width: 50,
    },
    {
      title: "Título",
      dataIndex: "title",
      sorter: (a, b) => a.title.length - b.title.length,
      width: 150,
      render: (value) => (
        <Tooltip title={value}>
          <Span ellipsis width={220} lineHeight={1.5}>
            {value}
          </Span>
        </Tooltip>
      ),
    },
    {
      title: "URL descarga",
      dataIndex: "url",
      sorter: (a, b) => a.url.length - b.url.length,
      width: 180,
      render: (value) => (
        <Tooltip title={value}>
          <Span ellipsis width={400} lineHeight={1.5}>
            {value}
          </Span>
        </Tooltip>
      ),
    },
    {
      title: "Action",
      width: 80,
      fixed: "right",
      render: (_, record: Download) => (
        <Row justify="space-between" align="middle">
          <Button type="link" onClick={() => handleOnManageDownload(record)}>
            Editar
          </Button>
          <Popconfirm
            title={`¿Esta seguro de eliminar esta descarga?`}
            onConfirm={() => handleOnDeleteDownload(record.id)}
            okText="Si"
            cancelText="No"
          >
            <Button type="link">Eliminar</Button>
          </Popconfirm>
        </Row>
      ),
    },
  ];
  const [fileList, setFileList] = useState<any[]>([]);
  const [filePreview, setFilePreview] = useState<UploadFile | undefined>();

  const draggerProps = {
    name: "file",
    multiple: false,
    maxCount: 1,
    action: fileUrl,
    headers: { Authorization: "Bearer " + getTokens().token },
    onChange(info: any) {
      const { status } = info.file;
      if (status === "done") {
        message.success(`Archivo ${info.file.name} cargado con éxito.`);
        setFileList(info.fileList);
      } else if (status === "error") {
        message.error(`Error al cargar el archivo ${info.file.name}.`);
        setFileList([]);
      }
    },
    onRemove(file: any) {
      setFileList([]);
    },
    onDrop(e: any) {
      //console.log("Dropped files", e.dataTransfer.files);
    },
  };
  const draggerPropsPreview = {
    name: "file",
    multiple: false,
    maxCount: 1,
    action: fileUrl,
    headers: { Authorization: "Bearer " + getTokens().token },
    onChange(info: any) {
      const { status } = info.file;
      if (status === "done") {
        message.success(`Archivo ${info.file.name} cargado con éxito.`);
        setFilePreview(info.file);
      } else if (status === "error") {
        message.error(`Error al cargar el archivo ${info.file.name}.`);
        setFilePreview(undefined);
      }
    },
    onRemove(file: any) {
      setFilePreview(undefined);
    },
    onDrop(e: any) {
      //console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const handleOnManageDownload = (download?: Download) => {
    setDownload(download || undefined);
    form.setFieldsValue({
      title: download ? download.title : "",
    });
    setShowModal(true);
  };

  const handleOnCreateOrUpdateDownload = useCallback(() => {
    form.validateFields().then((values) => {
      setLoadingForm(false);
      const {
        title,
        type,
        url: { file: { response } } = { file: { response: "" } },
      } = values;
      if (download) {
        updateDownloadAction({
          id: download.id,
          payload: { title, url: fileList.length ? response : download.url },
        });
      } else {
        createDownloadAction({
          payload: {
            type,
            title,
            url: response,
            preview_url: filePreview?.response,
          },
        });
      }
    });
  }, [download, form, fileList.length, filePreview]);

  const handleOnDeleteDownload = (id: number) => {
    setLoading(true);
    deleteDownloadAction({
      id: id,
    });
  };

  const handleOnCancel = () => {
    setFileList([]);
    setLoadingForm(false);
    setDownload(undefined);
    setShowModal(false);
  };

  useEffect(() => {
    setLoading(true);
    fetchDownloadsAction();
  }, []);

  useSubscription(OnFetchDownloads, (data) => {
    if (data) {
      setLoading(false);
      setDownloads([...data.data.slice().sort((a, b) => b.id - a.id).map((d, index) => {return{...d, index: index + 1 }})]);
    }
  });

  useSubscription(OnCreateDownload, () => {
    handleOnCancel();
    fetchDownloadsAction();
    notification.success({
      message: "Creado",
      description: "Descarga creada con exito",
    });
  });

  useSubscription(OnUpdateDownload, () => {
    handleOnCancel();
    fetchDownloadsAction();
    notification.success({
      message: "Actualizado",
      description: "Descarga actualizada con exito",
    });
  });

  useSubscription(OnDeleteDownload, () => {
    handleOnCancel();
    fetchDownloadsAction();
    notification.success({
      message: "Eliminado",
      description: "Descarga eliminada con exito",
    });
  });

  useSubscription(OnUpdateDownloadError, () => {
    handleOnCancel();
  });

  useSubscription(OnCreateDownloadError, () => {
    handleOnCancel();
  });

  useSubscription(OnDeleteDownloadError, () => {
    handleOnCancel();
  });

  return (
    <Card
      title={
        <Row justify="space-between" align="middle">
          <Typography.Title level={4} style={{ margin: 0 }}>
            Descargas
          </Typography.Title>
        </Row>
      }
      extra={
        <Button
          type="primary"
          size="large"
          onClick={() => {
            handleOnManageDownload();
          }}
        >
          Crear descarga
        </Button>
      }
    >
      {isTablet ? (
        <ResponsiveTable
          dataSource={downloads}
          columns={columns}
          loading={loading}
        />
      ) : (
        <div>
          <Table
            bordered
            size="small"
            columns={columns}
            dataSource={downloads}
            loading={loading}
            scroll={{ x: 1000 }}
          />
        </div>
      )}

      {showModal && (
        <Modal
          visible={showModal}
          title={
            download ? `Editar descarga #${download.id}` : "Crear descarga"
          }
          onCancel={() => {
            handleOnCancel();
          }}
          footer={null}
          width={800}
        >
          <Form layout="vertical" form={form}>
            <Row gutter={[16, 16]}>
              <Col xs={24}>
                <Form.Item
                  name="title"
                  label="Título"
                  rules={[{ required: true, message: requiredMessage }]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={24}>
                <Form.Item
                  name="url"
                  label="URL"
                  rules={[{ required: !download, message: requiredMessage }]}
                >
                  <Dragger {...draggerProps}>
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                      Haga clic o arrastre el archivo a esta área para cargarlo
                    </p>
                  </Dragger>
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item name="preview_url" label="Portada">
                  <Dragger {...draggerPropsPreview}>
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                      Haga clic o arrastre el archivo a esta área para cargarlo
                    </p>
                  </Dragger>
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item name="type" label="Tipo de archivo">
                  <Select>
                    <Select.Option value="image">Imagen</Select.Option>
                    <Select.Option value="video">Video</Select.Option>
                    <Select.Option value="pdf">PDF</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <CustomButton
                type="primary"
                htmlType="submit"
                loading={loadingForm}
                disabled={!download && !fileList.length}
                onClick={handleOnCreateOrUpdateDownload}
              >
                Guardar
              </CustomButton>
            </Form.Item>
          </Form>
        </Modal>
      )}
    </Card>
  );
};

export default DownloadsView;
