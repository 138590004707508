import { handleError } from "./../../shared/utils";
import {
  OnDeleteTicket,
  OnDeleteTicketError,
  OnFetchTickets,
  OnFetchTicketsError,
  OnUpdateTicket,
  OnUpdateTicketError,
} from "./tickets-events";
import { deleteTicket, fetchTickets, updateTicket } from "./tickets-services";

export const fetchTicketsAction = async () => {
  let response;

  try {
    response = await fetchTickets();
  } catch (error) {
    handleError(error);
    OnFetchTicketsError.dispatch(error);
  }

  if (response) {
    const json = await response.json();

    OnFetchTickets.dispatch(json);
  }
};

export const deleteTicketAction = async (id: number) => {
  let response;

  try {
    response = await deleteTicket(id);
  } catch (error) {
    handleError(error);
    OnDeleteTicketError.dispatch(error);
  }

  if (response) {
    const json = await response.json();

    OnDeleteTicket.dispatch(json);
  }
};

export const updateTicketAction = async (id: number, status: string) => {
  let response;

  try {
    response = await updateTicket(id, status);
  } catch (error) {
    handleError(error);
    OnUpdateTicketError.dispatch(error);
  }

  if (response) {
    const json = await response.json();

    OnUpdateTicket.dispatch(json);
  }
};
