import { Input, Row } from "antd";
import React, { FC } from "react";

interface Props {
  placeholder: string;
  dataOriginal: any;
  data: any;
  keys: string[];
  onChange(data: any): void;
}

const TableSearch: FC<Props> = ({
  placeholder,
  onChange,
  data,
  keys,
  dataOriginal,
}) => {
  const handleOnSearch = (event: any) => {
    const search = event.target.value.toLowerCase();
    const dataFiltered = data.filter((object: any) => {
      let match = false;

      keys.forEach((key: string) => {
        const value = object[key];

        if (
          value.toString() === search ||
          value.toString().toLowerCase().startsWith(search)
        ) {
          match = true;
        }
      });

      return match;
    });

    onChange(dataFiltered);
  };

  const handleOnChange = (event: any) => {
    const search = event.target.value;

    if (search === "") {
      onChange(dataOriginal);
    }
  };

  return (
    <Row>
      <Input.Search
        placeholder={placeholder}
        size="middle"
        style={{ width: "100%" }}
        onPressEnter={handleOnSearch}
        onChange={handleOnChange}
        allowClear
      />
    </Row>
  );
};

export default TableSearch;
