import { createEvent } from "@cobuildlab/react-simple-state";
import { Cycle, Balance } from "@devsplan/wealthclub-shared";

export const OnFetchPayments = createEvent();
export const OnFetchPaymentsError = createEvent();

export const OnUpdatePayment = createEvent();
export const OnUpdatePaymentError = createEvent();

export const OnFetchNextPayments = createEvent<Cycle[]>();
export const OnFetchNextPaymentsError = createEvent();

export const OnFetchDonePayments = createEvent<Balance[]>();
export const OnFetchDonePaymentsError = createEvent();

export const OnDeletePayment = createEvent<Balance[]>();
export const OnDeletePaymentError = createEvent();
