import { createEvent } from "@cobuildlab/react-simple-state";
import { Notification } from "@devsplan/wealthclub-shared";

export const OnUpdateNotification = createEvent();
export const OnUpdateNotificationError = createEvent();

export const OnFetchNotifications = createEvent<Notification[]>();
export const OnFetchNotificationsError = createEvent();

export const OnDeleteNotification = createEvent();
export const OnDeleteNotificationError = createEvent();
