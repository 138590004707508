import {
  fetchDownloads,
  updateDownload,
  createDownload,
  deleteDownload,
} from "./downloads-services";
import {
  OnFetchDownloads,
  OnFetchDownloadsError,
  OnUpdateDownload,
  OnUpdateDownloadError,
  OnCreateDownload,
  OnCreateDownloadError,
  OnDeleteDownload,
  OnDeleteDownloadError,
} from "./downloads-events";
import { handleError } from "../../shared/utils";

export const fetchDownloadsAction = async () => {
  let response: any;

  try {
    response = await fetchDownloads();
  } catch (error) {
    handleError(error);
    OnFetchDownloadsError.dispatch(error);
  }

  if (response) {
    const json = await response.json();

    OnFetchDownloads.dispatch(json);
  }
};

export const createDownloadAction = async (data: {
  payload: { title: string; url: string; preview_url?: string; type?: string };
}) => {
  let response: any;

  try {
    response = await createDownload(data);
  } catch (error) {
    handleError(error);
    OnCreateDownloadError.dispatch(error);
  }

  if (response) {
    const json = await response.json();

    OnCreateDownload.dispatch(json);
  }
};

export const updateDownloadAction = async (data: {
  id: number;
  payload: object;
}) => {
  let response: any;

  try {
    response = await updateDownload(data);
  } catch (error) {
    handleError(error);
    OnUpdateDownloadError.dispatch(error);
  }

  if (response) {
    const json = await response.json();

    OnUpdateDownload.dispatch(json);
  }
};

export const deleteDownloadAction = async (data: { id: number }) => {
  let response: any;

  try {
    response = await deleteDownload(data);
  } catch (error) {
    handleError(error);
    OnDeleteDownloadError.dispatch(error);
  }

  if (response) {
    const json = await response.json();

    OnDeleteDownload.dispatch(json);
  }
};
