import {
  fetchCryptoGoldSubsidies,
  fetchGoldSubsidies,
} from "./dashboard-services";
import {
  OnFetchCryptoGoldSubsidies,
  OnFetchCryptoGoldSubsidiesError,
  OnFetchGoldSubsidies,
  OnFetchGoldSubsidiesError,
} from "./dashboard-events";
import { handleError } from "../../shared/utils";

export const fetchGoldSubsidiesAction = async () => {
  let response;

  try {
    response = await fetchGoldSubsidies();
  } catch (error) {
    handleError(error);
    OnFetchGoldSubsidiesError.dispatch(error);
  }

  if (response) {
    const json = await response.json();

    OnFetchGoldSubsidies.dispatch(json);
  }
};

export const fetchCryptoGoldSubsidiesAction = async () => {
  let response;

  try {
    response = await fetchCryptoGoldSubsidies();
  } catch (error) {
    handleError(error);
    OnFetchCryptoGoldSubsidiesError.dispatch(error);
  }

  if (response) {
    const json = await response.json();

    OnFetchCryptoGoldSubsidies.dispatch(json);
  }
};
