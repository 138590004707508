import React, { useEffect, useState } from "react";
import { Card, Row, Button, Popconfirm, Typography } from "antd";
import Table, { ColumnType } from "antd/lib/table";
import { IS_TABLET, Notification } from "@devsplan/wealthclub-shared";
import { useMedia } from "react-use";
import ResponsiveTable from "../../components/ResponsiveTable";
import {
  fetchNotificationsAction,
  updateNotificationAction,
} from "./admin-notifications-actions";
import { useSubscription } from "@cobuildlab/react-simple-state";
import {
  OnFetchNotifications,
  OnFetchNotificationsError,
  OnUpdateNotification,
} from "./admin-notifications-events";
import { renderDate } from "../../components/Columns";
import { Span } from "../../components/Span";

const AdminNotificationView = () => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [loading, setLoading] = useState(false);
  const isTablet = useMedia(IS_TABLET);
  const columns: ColumnType<Notification>[] = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Fecha",
      dataIndex: "created_at",
      render: renderDate,
    },
    {
      title: "Title",
      dataIndex: "title",
      render: (value: string) => {
        return (
          <Span width={250} ellipsis lineHeight={1.5}>
            {value}
          </Span>
        );
      },
    },
    {
      title: "Descripción",
      dataIndex: "description",
      render: (value: string) => {
        return (
          <Span width={300} ellipsis lineHeight={1.5}>
            {value}
          </Span>
        );
      },
    },
    {
      title: "Action",
      render: (_, record) => {
        return (
          <Row>
            <Popconfirm
              title="Esta seguro de eliminar esta notificación?"
              okText="Si"
              cancelText="No"
              onConfirm={() => updateNotificationAction(record.id, 1)}
            >
              <Button type="link" style={{ padding: 0 }}>
                Marcar como leida
              </Button>
            </Popconfirm>
          </Row>
        );
      },
    },
  ];

  useEffect(() => {
    setLoading(true);
    fetchNotificationsAction();
  }, []);

  useSubscription(OnFetchNotifications, (data) => {
    if (data && data.length > 0) {
      setLoading(false);
      setNotifications(data.filter((n) => n.status === 0));
    } else {
      setLoading(false);
      setNotifications([]);
    }
  });

  useSubscription(OnFetchNotificationsError, () => {
    setLoading(false);
  });

  useSubscription(OnUpdateNotification, () => {
    setLoading(true);
    fetchNotificationsAction();
  });

  return (
    <Card
      title={
        <Row justify="space-between">
          <Typography.Title level={4} style={{ margin: 0 }}>
            Notificaciones Admin. sin leer
          </Typography.Title>
        </Row>
      }
    >
      {isTablet ? (
        <ResponsiveTable
          loading={loading}
          dataSource={notifications}
          columns={columns}
        />
      ) : (
        <Table
          bordered
          size="small"
          loading={loading}
          dataSource={notifications}
          columns={columns}
          scroll={{ x: 1000 }}
        />
      )}
    </Card>
  );
};

export default AdminNotificationView;
