import React, { FC, useState, useEffect } from "react";
import { Layout, Menu, Row, Col, Drawer, Badge } from "antd";
import {
  BLUE_COLOR,
  CONTACTS_ROUTE,
  DASHBOARD_ROUTE,
  DEPOSITS_ROUTE,
  GOLD_ROUTE,
  INVESTMENTS_ROUTE,
  NOTIFICATIONS_ROUTE,
  PAYMENTS_ROUTE,
  PAYPAL_ROUTE,
  TICKETS_ROUTE,
  USERS_ROUTE,
  TUTORIALS_ROUTE,
  DOWNLOADS_ROUTE,
  VERSIONS_ROUTE,
  RANKING_ROUTE,
  LOGS_ROUTE,
  ADMIN_NOTIFICATIONS_ROUTE,
  BONUS_ROUTE,
  REPRESENTANTS_ROUTE,
  LEADERS_ROUTE,
  REPORTS_ROUTE,
  CRYPTO_GOLD_ROUTE,
  CARD_REQUESTS_ROUTE,
} from "../../shared/constants";
import { useHistory } from "react-router-dom";
import "./Layout.css";
import ButtonIcon from "../ButtonIcon";
import {
  BellOutlined,
  BranchesOutlined,
  CodeOutlined,
  DollarOutlined,
  HomeOutlined,
  LogoutOutlined,
  MailOutlined,
  MenuFoldOutlined,
  MenuOutlined,
  MenuUnfoldOutlined,
  PayCircleOutlined,
  TeamOutlined,
  YoutubeOutlined,
  CloudDownloadOutlined,
  InfoCircleOutlined,
  BellFilled,
  BarChartOutlined,
  TableOutlined,
  UserSwitchOutlined,
  FolderOutlined,
  BarsOutlined,
  CreditCardOutlined,
} from "@ant-design/icons";
import { useAuth } from "../../providers/AuthProvider";
import { LogoHorizontal, PayPalIcon, UserName } from "..";
import logoHorizontal from "../../assets/images/logo-h.png";
import { useMediaQuery } from "react-responsive";
import { checkUserPermission } from "../../shared/utils";
import logoWc from "../../assets/images/logo_wc.png";
import styled from "styled-components";
import { fetchNotificationsAction } from "../../modules/admin-notifications/admin-notifications-actions";
import {
  OnFetchNotifications,
  OnUpdateNotification,
} from "../../modules/admin-notifications/admin-notifications-events";
import { useSubscription } from "@cobuildlab/react-simple-state";
import { ReactComponent as BitcoinSvg } from "../../assets/svg/bitcoin.svg";
import Icon from "@ant-design/icons";

const { Header, Content, Footer, Sider } = Layout;

const BitcoinIcon = () => <Icon component={BitcoinSvg} />;

const Logo = styled.img`
  width: 40px;
  height: 40px;
  max-width: 100%;
`;

const StyledSider = styled(Sider)`
  min-height: 100vh;
`;

const StyledMenu = styled(Menu)`
  .ant-menu-item-selected span {
    color: ${BLUE_COLOR} !important;
  }

  span {
    font-size: 16px !important;
  }

  .anticon {
    font-size: 20px !important;
  }
`;

const LayoutComponent: FC = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const history = useHistory();
  const { user, logout } = useAuth();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const [showDrawer, setShowDrawer] = useState(false);
  const [notifications, setNotificationsAmount] = useState(0);

  const menuMobile = [
    {
      url: DASHBOARD_ROUTE,
      name: "Inicio",
      icon: <HomeOutlined />,
    },
    {
      url: USERS_ROUTE,
      name: "Lista de usuarios",
      icon: <TableOutlined />,
    },
    {
      url: INVESTMENTS_ROUTE,
      name: "Inversiones",
      icon: <DollarOutlined />,
    },
    {
      url: DEPOSITS_ROUTE,
      name: "Depositos",
      icon: <DollarOutlined />,
    },
    {
      url: PAYMENTS_ROUTE,
      name: "Pagos",
      icon: <DollarOutlined />,
    },
    {
      url: GOLD_ROUTE,
      name: "Gold Dolares",
      icon: <BranchesOutlined />,
    },
    {
      url: CRYPTO_GOLD_ROUTE,
      name: "Gold Crypto",
      icon: <BitcoinIcon />,
    },
    {
      url: TUTORIALS_ROUTE,
      name: "Tutoriales",
      icon: <YoutubeOutlined />,
    },
    {
      url: DOWNLOADS_ROUTE,
      name: "Descargas",
      icon: <CloudDownloadOutlined />,
    },
    {
      url: CARD_REQUESTS_ROUTE,
      name: "Tarjetas",
      icon: <CreditCardOutlined />,
    },
    {
      url: TICKETS_ROUTE,
      name: "Tickets",
      icon: <InfoCircleOutlined />,
    },
    {
      url: NOTIFICATIONS_ROUTE,
      name: "Notificaciones",
      icon: <BellOutlined />,
    },
    {
      url: VERSIONS_ROUTE,
      name: "Versiones",
      icon: <CodeOutlined />,
    },
    {
      url: CONTACTS_ROUTE,
      name: "Contacto",
      icon: <MailOutlined />,
    },
    {
      url: LOGS_ROUTE,
      name: "Logs",
      icon: <BarsOutlined />,
    },
  ];

  const menu = [
    {
      url: DASHBOARD_ROUTE,
      name: "Inicio",
      icon: <HomeOutlined />,
    },
    {
      name: "Usuarios",
      icon: <TeamOutlined />,
      subMenu: [
        {
          url: USERS_ROUTE,
          name: "Lista de usuarios",
          icon: <TableOutlined />,
        },
        {
          url: REPORTS_ROUTE,
          name: "Reportes",
          icon: <BarChartOutlined />,
        },
        {
          url: REPRESENTANTS_ROUTE,
          name: "Representantes",
          icon: <UserSwitchOutlined />,
        },
        {
          url: LEADERS_ROUTE,
          name: "Lideres",
          icon: <UserSwitchOutlined />,
        },
        {
          url: RANKING_ROUTE,
          name: "Ranking",
          icon: <UserSwitchOutlined />,
        },
      ],
    },
    {
      name: "Finanzas",
      icon: <PayCircleOutlined />,
      subMenu: [
        {
          url: INVESTMENTS_ROUTE,
          name: "Inversiones",
          icon: <DollarOutlined />,
        },
        {
          url: DEPOSITS_ROUTE,
          name: "Depositos",
          icon: <DollarOutlined />,
        },
        {
          url: PAYMENTS_ROUTE,
          name: "Pagos",
          icon: <DollarOutlined />,
        },
        {
          url: BONUS_ROUTE,
          name: "Bonos",
          icon: <DollarOutlined />,
        },
        {
          url: PAYPAL_ROUTE,
          name: "PayPal",
          icon: <PayPalIcon />,
        },
      ],
    },
    {
      name: "Gold",
      icon: <BranchesOutlined />,
      subMenu: [
        {
          url: GOLD_ROUTE,
          name: "Gold Dolares",
          icon: <BranchesOutlined />,
        },
        {
          url: CRYPTO_GOLD_ROUTE,
          name: "Gold Crypto",
          icon: <BitcoinIcon />,
        },
      ],
    },
    {
      name: "Contenido",
      icon: <FolderOutlined />,
      subMenu: [
        {
          url: TUTORIALS_ROUTE,
          name: "Tutoriales",
          icon: <YoutubeOutlined />,
        },
        {
          url: DOWNLOADS_ROUTE,
          name: "Descargas",
          icon: <CloudDownloadOutlined />,
        },
      ],
    },
    {
      url: CARD_REQUESTS_ROUTE,
      name: "Tarjetas",
      icon: <CreditCardOutlined />,
    },
    {
      url: TICKETS_ROUTE,
      name: "Tickets",
      icon: <InfoCircleOutlined />,
    },
    {
      url: NOTIFICATIONS_ROUTE,
      name: "Notificaciones",
      icon: <BellOutlined />,
    },
    {
      url: VERSIONS_ROUTE,
      name: "Versiones",
      icon: <CodeOutlined />,
    },
    {
      url: CONTACTS_ROUTE,
      name: "Contacto",
      icon: <MailOutlined />,
    },
    {
      url: LOGS_ROUTE,
      name: "Logs",
      icon: <BarsOutlined />,
    },
  ];

  const goToAdminNotifications = () => {
    history.push(ADMIN_NOTIFICATIONS_ROUTE);
  };

  const activeItem: any = menu.find(
    (item) => item.url === window.location.pathname
  );

  useEffect(() => {
    fetchNotificationsAction();
  }, []);

  useSubscription(OnFetchNotifications, (data) => {
    if (data && data.length > 0) {
      const unreadNotifications = data.filter((item) => item.status === 0);
      setNotificationsAmount(unreadNotifications.length);
    } else {
      setNotificationsAmount(0);
    }
  });

  useSubscription(OnUpdateNotification, () => {
    fetchNotificationsAction();
  });

  return (
    <Layout className="layout">
      {!isTabletOrMobile && (
        <StyledSider trigger={null} collapsible collapsed={collapsed}>
          <Row
            justify="center"
            align="middle"
            style={{ marginTop: 16, marginBottom: 16 }}
          >
            <Logo src={logoWc} />
          </Row>
          {user && (
            <StyledMenu
              theme="dark"
              defaultSelectedKeys={[
                menu.indexOf(activeItem || undefined).toString(),
              ]}
              mode="inline"
            >
              {menu.map((item, key: number) => {
                if (item.subMenu) {
                  return (
                    <Menu.SubMenu key={key} icon={item.icon} title={item.name}>
                      {item.subMenu.map((subItem, subItemKey) => {
                        const havePermission = checkUserPermission(
                          subItem.url,
                          user
                        );

                        if (havePermission) {
                          return (
                            <Menu.Item
                              key={`sub-${key}-${subItemKey}`}
                              icon={subItem.icon}
                              onClick={() => {
                                history.push(subItem.url);
                              }}
                            >
                              {subItem.name}
                            </Menu.Item>
                          );
                        }

                        return null;
                      })}
                    </Menu.SubMenu>
                  );
                }

                const havePermission = checkUserPermission(item.url, user);

                if (havePermission) {
                  return (
                    <Menu.Item
                      key={key}
                      icon={item.icon}
                      onClick={() => {
                        history.push(item.url);
                      }}
                    >
                      {item.name}
                    </Menu.Item>
                  );
                }

                return null;
              })}
            </StyledMenu>
          )}
        </StyledSider>
      )}
      <Layout>
        <Header>
          <Row justify="space-between" align="middle">
            {isTabletOrMobile ? (
              <Col xs={4} style={{ padding: "0px 8px" }}>
                <MenuOutlined
                  style={{ fontSize: 24 }}
                  onClick={() => setShowDrawer(true)}
                />
              </Col>
            ) : (
              <>
                <Col xs={4}>
                  {React.createElement(
                    collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                    {
                      className: "trigger",
                      onClick: () => setCollapsed(!collapsed),
                      style: {
                        fontSize: 24,
                      },
                    }
                  )}
                </Col>
                <Col xs={{ span: 10 }} />
              </>
            )}
            <Col xs={20} sm={20} md={20} lg={8} xl={8} xxl={8}>
              <Row align="middle" justify="end">
                <Badge count={notifications} style={{ marginRight: 10 }}>
                  <span
                    style={{ marginRight: 10 }}
                    onClick={() => goToAdminNotifications()}
                  >
                    <ButtonIcon
                      fontSize="24px"
                      onClick={() => goToAdminNotifications()}
                    >
                      <BellFilled />
                    </ButtonIcon>
                  </span>
                </Badge>
                <UserName>
                  {isTabletOrMobile ? user?.name : user?.email}
                </UserName>
                <LogoutOutlined
                  onClick={() => logout()}
                  style={{
                    fontSize: 24,
                    color: "white",
                  }}
                />
              </Row>
            </Col>
          </Row>
        </Header>
        <div>
          <Content style={{ padding: 8 }}>
            <div className="site-layout-content">{children}</div>
          </Content>
          <Footer style={{ textAlign: "center", background: "transparent" }}>
            <p>v{process.env.REACT_APP_VERSION}</p>
            <br />
            WealthClub.Pro Wealthclub.VIP | 2020 © Todos los derechos reservados
          </Footer>
        </div>
      </Layout>
      <Drawer
        title="Wealth Club LTD"
        placement="left"
        closable={false}
        onClose={() => setShowDrawer(false)}
        visible={showDrawer}
      >
        <LogoHorizontal style={{ marginBottom: 16 }} src={logoHorizontal} />
        {user && (
          <Menu
            theme="dark"
            mode="vertical"
            defaultSelectedKeys={[
              menuMobile.indexOf(activeItem || undefined).toString(),
            ]}
          >
            {menuMobile.map((item, key: number) => {
              const havePermission = checkUserPermission(item.url, user);

              return havePermission ? (
                <Menu.Item
                  key={key}
                  onClick={() => {
                    if (item.url) {
                      history.push(item.url);
                    }
                  }}
                >
                  {item.name}
                </Menu.Item>
              ) : null;
            })}
          </Menu>
        )}
      </Drawer>
    </Layout>
  );
};

export default LayoutComponent;
