import { api, apiUrl } from "./../../api/index";

const url = `${apiUrl}/deposits`;

export const fetchDeposits = () => {
  return api(url, {
    method: "GET",
  });
};

export const updateDeposit = (id: number, data: object) => {
  return api(`${url}/${id}`, {
    method: "PUT",
    body: JSON.stringify(data),
  });
};

export const deleteDeposit = (id: number) => {
  return api(`${url}/${id}`, {
    method: "DELETE",
  });
};
