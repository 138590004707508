import { api, apiUrl } from "./../../api/index";

const url = `${apiUrl}/payments`;
const balancesUrl = `${apiUrl}/balances`;
const urlNextPayments = `${url}/next`;
const urlDonePayments = `${url}/done`;

export const fetchPayments = (data: { page: number; pageSize?: number }) => {
  return api(`${url}?page=${data.page}&per_page=${data.pageSize || 10}`, {
    method: "GET",
  });
};

export const fetchNextPayments = () => {
  return api(urlNextPayments, {
    method: "GET",
  });
};

export const fetchDonePayments = () => {
  return api(urlDonePayments, {
    method: "GET",
  });
};

export const updatePayment = (id: number, data: object) => {
  return api(`${url}/${id}`, {
    method: "PUT",
    body: JSON.stringify(data),
  });
};

export const deletePayment = (id: number) => {
  return api(`${balancesUrl}/${id}`, {
    method: "DELETE",
  });
};
