import { fetchTicketsAction } from "./tickets-actions";
import { Ticket } from "@devsplan/wealthclub-shared";
import { useEffect, useState } from "react";
import { useSubscription } from "@cobuildlab/react-simple-state";
import { OnFetchTickets, OnFetchTicketsError } from "./tickets-events";

export const useTickets = (): [Ticket[], boolean] => {
  const [tickets, setTickets] = useState<Ticket[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetchTicketsAction();
  }, []);

  useSubscription(OnFetchTickets, (data) => {
    if (data) {
      setLoading(false);
      setTickets(data);
    }
  });

  useSubscription(OnFetchTicketsError, () => {
    setLoading(false);
  });

  return [tickets, loading];
};
