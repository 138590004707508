import { handleError } from "./../../shared/utils";
import {
  OnFetchPaypalPayments,
  OnFetchPaypalPaymentsError,
} from "./paypal-events";
import { fetchPaypalPayments } from "./paypal-services";

export const fetchPaypalPaymentsAction = async () => {
  let response;

  try {
    response = await fetchPaypalPayments();
  } catch (error) {
    handleError(error);
    OnFetchPaypalPaymentsError.dispatch(error);
  }

  if (response) {
    const json = await response.json();

    OnFetchPaypalPayments.dispatch(json);
  }
};
