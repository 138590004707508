import React, { FC } from "react";
import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  InfoCircleTwoTone,
  WarningTwoTone,
} from "@ant-design/icons";
import { Input, Form, Select, FormInstance, Row } from "antd";
import { createNotificationAction } from "./notifications-actions";
import { NotificationData } from "./notifications-types";
import { Span } from "../../components/Span";

export const NOTIFICATIONS_TYPES = [
  {
    icon: (
      <CheckCircleTwoTone
        twoToneColor="#5CC532"
        style={{ fontSize: 24, marginRight: 5 }}
      />
    ),
    name: "success",
    label: "Success",
  },
  {
    icon: (
      <CloseCircleTwoTone
        twoToneColor="#ED4A4C"
        style={{ fontSize: 24, marginRight: 5 }}
      />
    ),
    name: "error",
    label: "Error",
  },
  {
    icon: (
      <WarningTwoTone
        style={{ fontSize: 24, marginRight: 5 }}
        twoToneColor="#F6AE42"
      />
    ),
    name: "warning",
    label: "Warning",
  },
  {
    icon: <InfoCircleTwoTone style={{ fontSize: 24, marginRight: 5 }} />,
    name: "info",
    label: "Info",
  },
];

const { TextArea } = Input;
const { Option } = Select;

interface Props {
  form: FormInstance;
}

const SendNotifications: FC<Props> = ({ form }) => {
  const handleOnFinish = (values: NotificationData) => {
    if (values.user_id) {
      createNotificationAction({ ...values, important: 0 });
    } else {
      createNotificationAction({ ...values, user_id: 0, important: 1 });
    }
  };

  return (
    <Form onFinish={handleOnFinish} form={form} layout="vertical">
      <Form.Item
        label="ID de Usuario (opcional)"
        name="user_id"
        rules={[
          {
            pattern: /^\d+$/,
            message: "Invalid number",
          },
        ]}
      >
        <Input placeholder="ID de Usuario" />
      </Form.Item>
      <Form.Item label="Titulo" name="title" rules={[{ required: true }]}>
        <Input placeholder="Titulo" maxLength={255} />
      </Form.Item>
      <Form.Item
        label="Descripción"
        name="description"
        rules={[{ required: true }]}
      >
        <TextArea
          style={{ width: "auto" }}
          placeholder="Descripción"
          showCount
          maxLength={600}
          rows={4}
        />
      </Form.Item>
      <Form.Item
        label="Tipo de Notificación"
        name="type"
        rules={[{ required: true }]}
      >
        <Select placeholder="Tipo de Notificación" style={{ width: 200 }}>
          {NOTIFICATIONS_TYPES.map((type) => (
            <Option value={type.name}>
              <Row align="middle">
                {type.icon}
                <Span marginLeft="8px">{type.label}</Span>
              </Row>
            </Option>
          ))}
        </Select>
      </Form.Item>
    </Form>
  );
};

export default SendNotifications;
