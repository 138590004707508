import { api, apiUrl } from "./../../api/index";

const url = `${apiUrl}/users`;

export const fetchUsers = ({
  page,
  pageSize = 10,
  search = "",
}: {
  page: number;
  pageSize?: number;
  search?: string;
}) => {
  return api(
    `${url}?page=${page}&per_page=${pageSize || 10}${
      search !== "" ? `&search=${search}` : ""
    }`,
    {
      method: "GET",
    }
  );
};

export const fetchUsersBasic = () => {
  return api(`${apiUrl}/basic/users`, {
    method: "GET",
  });
};

export const fetchUserDetails = (id: number) => {
  return api(`${url}/${id}`, {
    method: "GET",
  });
};

export const updateUser = (id: number, data: object) => {
  return api(`${url}/admin/${id}`, {
    method: "PUT",
    body: JSON.stringify(data),
  });
};

export const fetchUserInfoAccountant = (id: number) => {
  return api(`${url}/accountant/${id}`, {
    method: "GET",
  });
};
