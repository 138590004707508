import React, { useCallback, useEffect, useState } from "react";
import {
  Row,
  Card,
  Typography,
  Button,
  Table,
  Col,
  Popconfirm,
  Tabs,
  Radio,
  Statistic,
  //notification,
} from "antd";
import {
  deleteInvestmentAction,
  deletePeriodInvestmentAction,
  fetchInvestmentsAction,
  fetchLegacyInvestmentsAction,
  fetchPeriodInvestmentsAction,
  updateInvestmentAction,
  updateLegacyInvestmentAction,
  updatePeriodInvestmentAction,
} from "./investments-actions";
import { useSubscription } from "@cobuildlab/react-simple-state";
import {
  OnDeleteInvestment,
  OnDeletePeriodInvestment,
  OnFetchInvestments,
  OnFetchLegacyInvestments,
  OnFetchPeriodInvestments,
  OnUpdateInvestment,
  OnUpdateLegacyInvestment,
  OnUpdatePeriodInvestment,
} from "./investments-events";
import moment from "moment";
import { setFormatCurrency } from "../../shared/utils";
import {
  renderApproved,
  renderDate,
  renderDateFromNow,
  renderPaymentType,
  renderUserLink,
  renderVoucher,
} from "../../components/Columns";
import TableSearch from "../../components/TableSearch";
import { ADMIN_FILTER_CASH, IS_TABLET } from "../../shared/constants";
import { ColumnType } from "antd/lib/table";
import { useMedia } from "react-use";
import ResponsiveTable from "../../components/ResponsiveTable";
import { Investment } from "@devsplan/wealthclub-shared";
import { LegacyTransaction } from "../../shared/types";
import { InvestmentKpis } from "./investments-types";
import { useAuth } from "../../providers/AuthProvider";

const InvestmentsView = () => {
  const [investments, setInvestments] = useState<Investment[]>([]);
  const [periodInvestments, setPeriodInvestments] = useState<Investment[]>([]);
  const [periodInvestmentsFiltered, setPeriodInvestmentsFiltered] = useState<
    Investment[]
  >([]);
  const [legacyInvestments, setLegacyInvestments] = useState<
    LegacyTransaction[]
  >([]);
  const [investmentsFiltered, setInvestmentsFiltered] = useState<Investment[]>(
    []
  );
  const [legacyInvestmentsFiltered, setLegacyInvestmentsFiltered] = useState<
    LegacyTransaction[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingLegacy, setLoadingLegacy] = useState(false);
  const [kpis, setKpis] = useState<InvestmentKpis>();
  const [kpisLegacy, setKpisLegacy] = useState<InvestmentKpis>();
  const [kpisPeriod, setKpisPeriod] = useState<InvestmentKpis>();
  const isTablet = useMedia(IS_TABLET);
  const { user } = useAuth();

  const columns: ColumnType<Investment>[] = [
    {
      title: "#",
      dataIndex: "index",
      fixed: "left",
      width: 60,
    },
    // {
    //   title: "ID",
    //   dataIndex: "id",
    //   sorter: (a, b) => a.id - b.id,
    //   fixed: "left",
    // },
    {
      title: "Fecha",
      dataIndex: "created_at",
      render: renderDate,
      sorter: (a, b) => {
        const dateA = moment(a.created_at).seconds();
        const dateB = moment(b.created_at).seconds();

        return dateA - dateB;
      },
    },
    {
      title: "Creado hace",
      dataIndex: "created_at",
      render: renderDateFromNow,
    },
    {
      title: "Código",
      dataIndex: "code",
    },
    // {
    //   title: "Ciclo",
    //   dataIndex: "cycle_id",
    //   sorter: (a, b) => a.cycle_id - b.cycle_id,
    //   render: renderEmpty,
    // },
    {
      title: "Usuario",
      dataIndex: ["cycle", "user_id"],
      sorter: (a, b) => (a.cycle?.user_id ?? 0) - (b.cycle?.user_id ?? 0),
      render: renderUserLink,
    },
    {
      title: "Inversión",
      dataIndex: "amount",
      sorter: (a, b) => a.amount - b.amount,
      render: (value: number) => setFormatCurrency(value),
    },
    {
      title: "Método de pago",
      dataIndex: "payment_type",
      render: renderPaymentType,
    },
    {
      title: "Comprobante",
      width: 180,
      render: renderVoucher,
    },
    //{
    //  title: "Fecha de cobro",
    //  dataIndex: ["data", "finished_date"],
    //  render: renderDate,
    //  sorter: (a, b) => {
    //    const dateA = moment(a.data?.finished_date).seconds();
    //    const dateB = moment(b.data?.finished_date).seconds();
    //    return dateA - dateB;
    //  },
    //},
    //{
    //  title: "Terminado",
    //  sorter: (a, b) => {
    //    const now = moment();
    //    const dateA = moment(a.data?.finished_date);
    //    const dateB = moment(b.data?.finished_date);
    //    return now.diff(dateA, "days") - now.diff(dateB, "days");
    //  },
    //  render: (_, investment) => {
    //    const now = moment();
    //    const finishedDate = moment(investment.data?.finished_date);
    //    const diff = now.diff(finishedDate, "days");
    //    return diff > 0 ? "Si" : "No";
    //  },
    //},
    {
      title: "Estado",
      dataIndex: "status",
      sorter: (a, b) => a.status - b.status,
      render: renderApproved,
    },
    {
      title: "Action",
      width: 210,
      render: (_, record: Investment) => (
        <Row justify="space-between" align="middle">
          <Popconfirm
            title={`¿Esta seguro de ${
              record.status === 1 ? "Rechazar" : "Aprobar"
            } esta inversión?`}
            onConfirm={() =>
              record.status === 1
                ? handleOnUpdateInvestment({
                    investment: record,
                    status: false,
                  })
                : handleOnUpdateInvestment({
                    investment: record,
                    status: true,
                  })
            }
            okText="Si"
            cancelText="No"
          >
            <Button type="link">
              {record.status === 1 ? "Rechazar" : "Aprobar"}
            </Button>
          </Popconfirm>
          <Popconfirm
            title="¿Esta seguro de cancelar esta inversión?"
            onConfirm={() => handleOnDelete(record.id)}
            okText="Si"
            cancelText="No"
          >
            <Button type="link">Cancelar</Button>
          </Popconfirm>
        </Row>
      ),
    },
  ];

  const legacyColumns: ColumnType<LegacyTransaction>[] = [
    {
      title: "#",
      dataIndex: "index",
      fixed: "left",
      width: 60,
    },
    // {
    //   title: "ID",
    //   dataIndex: "id",
    //   sorter: (a, b) => a.id - b.id,
    //   fixed: "left",
    // },
    {
      title: "Fecha",
      dataIndex: "created_at",
      render: renderDate,
      sorter: (a, b) => {
        const dateA = moment(a.created_at).seconds();
        const dateB = moment(b.created_at).seconds();

        return dateA - dateB;
      },
    },
    {
      title: "Creado hace",
      dataIndex: "created_at",
      render: renderDateFromNow,
    },
    {
      title: "Código",
      dataIndex: "transaction",
    },
    //{
    //  title: "Ciclo",
    //  dataIndex: "cycle_id",
    //  sorter: (a, b) => a.cycle_id - b.cycle_id,
    //  render: renderEmpty,
    //},
    {
      title: "Usuario",
      dataIndex: "user_id",
      sorter: (a, b) => (a.user_id ?? 0) - (b.user_id ?? 0),
      render: renderUserLink,
    },
    {
      title: "Inversión",
      dataIndex: "amount",
      sorter: (a, b) => a.amount - b.amount,
      render: (value: number) => setFormatCurrency(value),
    },
    //{
    //  title: "Fecha de cobro",
    //  dataIndex: ["data", "finished_date"],
    //  render: renderDate,
    //  sorter: (a, b) => {
    //    const dateA = moment(a.data?.finished_date).seconds();
    //    const dateB = moment(b.data?.finished_date).seconds();
    //    return dateA - dateB;
    //  },
    //},
    //{
    //  title: "Terminado",
    //  sorter: (a, b) => {
    //    const now = moment();
    //    const dateA = moment(a.data?.finished_date);
    //    const dateB = moment(b.data?.finished_date);
    //    return now.diff(dateA, "days") - now.diff(dateB, "days");
    //  },
    //  render: (_, investment) => {
    //    const now = moment();
    //    const finishedDate = moment(investment.data?.finished_date);
    //    const diff = now.diff(finishedDate, "days");
    //    return diff > 0 ? "Si" : "No";
    //  },
    //},
    {
      title: "Estado",
      dataIndex: "status",
      sorter: (a, b) => a.status - b.status,
      render: renderApproved,
    },
    {
      title: "Action",
      width: 220,
      render: (_, record: LegacyTransaction) => (
        <Row justify="space-between" align="middle">
          <Popconfirm
            title={`¿Esta seguro de ${
              record.status === 1 ? "Rechazar" : "Aprobar"
            } esta inversión?`}
            onConfirm={() =>
              record.status === 1
                ? handleOnUpdateLegacyInvestment({
                    investment: record,
                    status: false,
                  })
                : handleOnUpdateLegacyInvestment({
                    investment: record,
                    status: true,
                  })
            }
            okText="Si"
            cancelText="No"
          >
            <Button type="link">
              {record.status === 1 ? "Rechazar" : "Aprobar"}
            </Button>
          </Popconfirm>
          <Popconfirm
            title="¿Esta seguro de cancelar esta inversión?"
            onConfirm={() => handleOnDelete(record.id)}
            okText="Si"
            cancelText="No"
          >
            <Button type="link">Cancelar</Button>
          </Popconfirm>
        </Row>
      ),
    },
  ];

  const periodColumns: ColumnType<Investment>[] = [
    {
      title: "#",
      dataIndex: "index",
      fixed: "left",
      width: 60,
    },
    // {
    //   title: "ID",
    //   dataIndex: "id",
    //   sorter: (a, b) => a.id - b.id,
    //   fixed: "left",
    // },
    {
      title: "Fecha",
      dataIndex: "created_at",
      render: renderDate,
      sorter: (a, b) => {
        const dateA = moment(a.created_at).seconds();
        const dateB = moment(b.created_at).seconds();

        return dateA - dateB;
      },
    },
    {
      title: "Creado hace",
      dataIndex: "created_at",
      render: renderDateFromNow,
    },
    {
      title: "Código",
      dataIndex: "code",
    },
    // {
    //   title: "Ciclo",
    //   dataIndex: "cycle_id",
    //   sorter: (a, b) => a.cycle_id - b.cycle_id,
    //   render: renderEmpty,
    // },
    {
      title: "Usuario",
      dataIndex: ["cycle", "user_id"],
      sorter: (a, b) => (a.cycle?.user_id ?? 0) - (b.cycle?.user_id ?? 0),
      render: renderUserLink,
    },
    {
      title: "Inversión",
      dataIndex: "amount",
      sorter: (a, b) => a.amount - b.amount,
      render: (value: number) => setFormatCurrency(value),
    },
    {
      title: "Método de pago",
      dataIndex: "payment_type",
      render: renderPaymentType,
    },
    {
      title: "Comprobante",
      width: 180,
      render: renderVoucher,
    },
    //{
    //  title: "Fecha de cobro",
    //  dataIndex: ["data", "finished_date"],
    //  render: renderDate,
    //  sorter: (a, b) => {
    //    const dateA = moment(a.data?.finished_date).seconds();
    //    const dateB = moment(b.data?.finished_date).seconds();
    //    return dateA - dateB;
    //  },
    //},
    //{
    //  title: "Terminado",
    //  sorter: (a, b) => {
    //    const now = moment();
    //    const dateA = moment(a.data?.finished_date);
    //    const dateB = moment(b.data?.finished_date);
    //    return now.diff(dateA, "days") - now.diff(dateB, "days");
    //  },
    //  render: (_, investment) => {
    //    const now = moment();
    //    const finishedDate = moment(investment.data?.finished_date);
    //    const diff = now.diff(finishedDate, "days");
    //    return diff > 0 ? "Si" : "No";
    //  },
    //},
    {
      title: "Estado",
      dataIndex: "status",
      sorter: (a, b) => a.status - b.status,
      render: renderApproved,
    },
    {
      title: "Action",
      width: 210,
      render: (_, record: Investment) => (
        <Row justify="space-between" align="middle">
          <Popconfirm
            title={`¿Esta seguro de ${
              record.status === 1 ? "Rechazar" : "Aprobar"
            } esta inversión?`}
            onConfirm={() =>
              record.status === 1
                ? handleOnUpdatePeriodInvestment({
                    investment: record,
                    status: false,
                  })
                : handleOnUpdatePeriodInvestment({
                    investment: record,
                    status: true,
                  })
            }
            okText="Si"
            cancelText="No"
          >
            <Button type="link">
              {record.status === 1 ? "Rechazar" : "Aprobar"}
            </Button>
          </Popconfirm>
          <Popconfirm
            title="¿Esta seguro de cancelar esta inversión?"
            onConfirm={() => handleOnDeletePeriod(record.id)}
            okText="Si"
            cancelText="No"
          >
            <Button type="link">Cancelar</Button>
          </Popconfirm>
        </Row>
      ),
    },
  ];

  const handleOnUpdateInvestment = ({
    investment,
    status,
  }: {
    investment: Investment;
    status: boolean;
  }) => {
    updateInvestmentAction(investment.id, {
      status,
    });
  };

  const handleOnUpdatePeriodInvestment = ({
    investment,
    status,
  }: {
    investment: Investment;
    status: boolean;
  }) => {
    updatePeriodInvestmentAction(investment.id, {
      status,
    });
  };

  const handleOnUpdateLegacyInvestment = ({
    investment,
    status,
  }: {
    investment: LegacyTransaction;
    status: boolean;
  }) => {
    updateLegacyInvestmentAction(investment.id, {
      status,
    });
  };

  const handleOnDelete = (id: number) => {
    setLoading(true);
    deleteInvestmentAction(id);
  };

  const handleOnDeletePeriod = (id: number) => {
    setLoading(true);
    deletePeriodInvestmentAction(id);
  };

  const handleOnChangeRadio = useCallback(
    (value: string) => {
      if (value === "all") {
        setInvestmentsFiltered(investments);
      }

      if (value === "pending") {
        setInvestmentsFiltered(
          investments.filter((investment) => investment.status === 0)
        );
      }

      if (value === "approved") {
        setInvestmentsFiltered(
          investments.filter((investment) => investment.status === 1)
        );
      }
    },
    [investments]
  );

  const handleOnChangeRadioPeriod = useCallback(
    (value: string) => {
      if (value === "all") {
        setPeriodInvestmentsFiltered(periodInvestments);
      }

      if (value === "pending") {
        setPeriodInvestmentsFiltered(
          periodInvestments.filter((investment) => investment.status === 0)
        );
      }

      if (value === "approved") {
        setPeriodInvestmentsFiltered(
          periodInvestments.filter((investment) => investment.status === 1)
        );
      }
    },
    [periodInvestments]
  );

  const handleOnChangeRadioLegacy = useCallback(
    (value: string) => {
      if (value === "all") {
        setLegacyInvestmentsFiltered(legacyInvestments);
      }

      if (value === "pending") {
        setLegacyInvestmentsFiltered(
          legacyInvestments.filter((investment) => investment.status === 0)
        );
      }

      if (value === "approved") {
        setLegacyInvestmentsFiltered(
          legacyInvestments.filter((investment) => investment.status === 1)
        );
      }
    },
    [legacyInvestments]
  );

  useEffect(() => {
    setLoading(true);
    setLoadingLegacy(true);
    fetchInvestmentsAction();
    fetchLegacyInvestmentsAction();
    fetchPeriodInvestmentsAction();
  }, []);

  useSubscription(
    OnFetchInvestments,
    (investments) => {
      if (investments) {
        setLoading(false);

        const filteredInvestments =
          ADMIN_FILTER_CASH.indexOf(user.id) !== -1
            ? investments.filter((i) => i.payment_type === 1)
            : investments;

        const sortedInvestments = filteredInvestments
          .slice()
          .sort((a, b) => {
            const dateA = moment(a.created_at);
            const dateB = moment(b.created_at);

            return dateB.diff(dateA, "seconds");
          })
          .map((i, index) => {
            return {
              ...i,
              index: investments.length + 1 - (index + 1),
            };
          });

        setInvestments(sortedInvestments);
        setInvestmentsFiltered([
          ...sortedInvestments.filter((investment) => investment.status === 0),
        ]);

        let totalInvested = 0;
        let totalPending = 0;
        let totalApproved = 0;

        sortedInvestments.forEach((investment) => {
          if (investment.status === 0) {
            totalPending += investment.amount;
          }

          if (investment.status === 1) {
            totalApproved += investment.amount;
          }

          totalInvested += investment.amount;
        });

        setKpis({
          total_invested: totalInvested,
          total_pending: totalPending,
          total_approved: totalApproved,
        });
      }
    },
    []
  );

  useSubscription(
    OnFetchPeriodInvestments,
    (investments) => {
      if (investments) {
        setLoading(false);

        const filteredInvestments =
          ADMIN_FILTER_CASH.indexOf(user.id) !== -1
            ? investments.filter((i) => i.payment_type === 1)
            : investments;

        const sortedInvestments = filteredInvestments
          .slice()
          .sort((a, b) => {
            const dateA = moment(a.created_at);
            const dateB = moment(b.created_at);

            return dateB.diff(dateA, "seconds");
          })
          .map((i, index) => {
            return {
              ...i,
              index: investments.length + 1 - (index + 1),
            };
          });

        setPeriodInvestments(sortedInvestments);
        setPeriodInvestmentsFiltered([
          ...sortedInvestments.filter((investment) => investment.status === 0),
        ]);

        let totalInvested = 0;
        let totalPending = 0;
        let totalApproved = 0;

        sortedInvestments.forEach((investment) => {
          if (investment.status === 0) {
            totalPending += investment.amount;
          }

          if (investment.status === 1) {
            totalApproved += investment.amount;
          }

          totalInvested += investment.amount;
        });

        setKpisPeriod({
          total_invested: totalInvested,
          total_pending: totalPending,
          total_approved: totalApproved,
        });
      }
    },
    []
  );

  useSubscription(
    OnFetchLegacyInvestments,
    (investments) => {
      if (investments) {
        setLoadingLegacy(false);

        const filteredInvestments =
          ADMIN_FILTER_CASH.indexOf(user.id) !== -1
            ? investments.filter(
                (i) => i.payment_type.toLowerCase() === "efectivo"
              )
            : investments;

        const sortedInvestments = filteredInvestments
          .slice()
          .sort((a, b) => {
            const dateA = moment(a.created_at);
            const dateB = moment(b.created_at);

            return dateB.diff(dateA, "seconds");
          })
          .map((i, index) => {
            return {
              ...i,
              index: investments.length + 1 - (index + 1),
            };
          });

        setLegacyInvestments(sortedInvestments);
        setLegacyInvestmentsFiltered([
          ...sortedInvestments.filter((investment) => investment.status === 0),
        ]);

        let totalInvested = 0;
        let totalPending = 0;
        let totalApproved = 0;

        sortedInvestments.forEach((investment) => {
          if (investment.status === 0) {
            totalPending += investment.amount;
          }

          if (investment.status === 1) {
            totalApproved += investment.amount;
          }

          totalInvested += investment.amount;
        });

        setKpisLegacy({
          total_invested: totalInvested,
          total_pending: totalPending,
          total_approved: totalApproved,
        });
      }
    },
    []
  );

  useSubscription(
    OnUpdateInvestment,
    () => {
      setLoading(true);
      fetchInvestmentsAction();
    },
    []
  );

  useSubscription(
    OnUpdatePeriodInvestment,
    () => {
      setLoading(true);
      fetchPeriodInvestmentsAction();
    },
    []
  );

  useSubscription(
    OnUpdateLegacyInvestment,
    () => {
      setLoadingLegacy(true);
      fetchLegacyInvestmentsAction();
    },
    []
  );

  useSubscription(OnDeleteInvestment, () => {
    fetchInvestmentsAction();
  });

  useSubscription(OnDeletePeriodInvestment, () => {
    fetchPeriodInvestmentsAction();
  });

  return (
    <div>
      <Card
        title={
          <Row justify="space-between" align="middle">
            <Typography.Title level={4} style={{ margin: 0 }}>
              Inversiones
            </Typography.Title>
          </Row>
        }
      >
        <Tabs defaultActiveKey={"0"}>
          <Tabs.TabPane key="0" tab="Legacy 4.0">
            <Row style={{ marginBottom: 16 }} gutter={[16, 16]}>
              <Col xs={24}>
                <Row gutter={[16, 16]}>
                  <Col xs={6}>
                    <Card>
                      <Statistic
                        title="Total"
                        value={kpisPeriod?.total_invested}
                        prefix="$"
                      />
                    </Card>
                  </Col>
                  <Col xs={6}>
                    <Card>
                      <Statistic
                        title="Total Pendientes"
                        value={kpisPeriod?.total_pending}
                        prefix="$"
                      />
                    </Card>
                  </Col>
                  <Col xs={6}>
                    <Card>
                      <Statistic
                        title="Total Aprobadas"
                        value={kpisPeriod?.total_approved}
                        prefix="$"
                      />
                    </Card>
                  </Col>
                </Row>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <TableSearch
                  keys={["id", "code", "amount"]}
                  dataOriginal={periodInvestments}
                  data={periodInvestmentsFiltered}
                  placeholder="Buscar por ID, Código o Inversión"
                  onChange={(data) => {
                    setPeriodInvestmentsFiltered(data);
                  }}
                />
              </Col>
              <Col xs={12}>
                <Radio.Group
                  defaultValue="pending"
                  onChange={(event) =>
                    handleOnChangeRadioPeriod(event.target.value)
                  }
                >
                  <Radio.Button value="all">Todas</Radio.Button>
                  <Radio.Button value="pending">Pendientes</Radio.Button>
                  <Radio.Button value="approved">Aprobadas</Radio.Button>
                </Radio.Group>
              </Col>
            </Row>
            {isTablet ? (
              <ResponsiveTable
                dataSource={periodInvestmentsFiltered}
                loading={loading}
                columns={periodColumns}
              />
            ) : (
              <Table
                bordered
                size="small"
                columns={periodColumns}
                loading={loading}
                dataSource={periodInvestmentsFiltered}
                scroll={{ x: 1000 }}
              />
            )}
          </Tabs.TabPane>
          <Tabs.TabPane key="1" tab="Legacy 2.0">
            <Row style={{ marginBottom: 16 }} gutter={[16, 16]}>
              <Col xs={24}>
                <Row gutter={[16, 16]}>
                  <Col xs={6}>
                    <Card>
                      <Statistic
                        title="Total"
                        value={kpis?.total_invested}
                        prefix="$"
                      />
                    </Card>
                  </Col>
                  <Col xs={6}>
                    <Card>
                      <Statistic
                        title="Total Pendientes"
                        value={kpis?.total_pending}
                        prefix="$"
                      />
                    </Card>
                  </Col>
                  <Col xs={6}>
                    <Card>
                      <Statistic
                        title="Total Aprobadas"
                        value={kpis?.total_approved}
                        prefix="$"
                      />
                    </Card>
                  </Col>
                </Row>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <TableSearch
                  keys={["id", "code", "amount"]}
                  dataOriginal={investments}
                  data={investmentsFiltered}
                  placeholder="Buscar por ID, Código o Inversión"
                  onChange={(data) => {
                    setInvestmentsFiltered(data);
                  }}
                />
              </Col>
              <Col xs={12}>
                <Radio.Group
                  defaultValue="pending"
                  onChange={(event) => handleOnChangeRadio(event.target.value)}
                >
                  <Radio.Button value="all">Todas</Radio.Button>
                  <Radio.Button value="pending">Pendientes</Radio.Button>
                  <Radio.Button value="approved">Aprobadas</Radio.Button>
                </Radio.Group>
              </Col>
            </Row>
            {isTablet ? (
              <ResponsiveTable
                dataSource={investmentsFiltered}
                loading={loading}
                columns={columns}
              />
            ) : (
              <Table
                bordered
                size="small"
                columns={columns}
                loading={loading}
                dataSource={investmentsFiltered}
                scroll={{ x: 1000 }}
              />
            )}
          </Tabs.TabPane>
          <Tabs.TabPane key="2" tab="Legacy 1.0">
            <Row style={{ marginBottom: 16 }} gutter={[16, 16]}>
              <Col xs={24}>
                <Row gutter={[16, 16]}>
                  <Col xs={6}>
                    <Card>
                      <Statistic
                        title="Total"
                        value={kpisLegacy?.total_invested}
                        prefix="$"
                      />
                    </Card>
                  </Col>
                  <Col xs={6}>
                    <Card>
                      <Statistic
                        title="Total Pendientes"
                        value={kpisLegacy?.total_pending}
                        prefix="$"
                      />
                    </Card>
                  </Col>
                  <Col xs={6}>
                    <Card>
                      <Statistic
                        title="Total Aprobadas"
                        value={kpisLegacy?.total_approved}
                        prefix="$"
                      />
                    </Card>
                  </Col>
                </Row>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <TableSearch
                  keys={["id", "transaction", "user_id", "amount"]}
                  dataOriginal={legacyInvestments}
                  data={legacyInvestmentsFiltered}
                  placeholder="Buscar por ID, Código, Usuario o Inversión"
                  onChange={(data) => {
                    setLegacyInvestmentsFiltered(data);
                  }}
                />
              </Col>
              <Col xs={12}>
                <Radio.Group
                  defaultValue="pending"
                  onChange={(event) =>
                    handleOnChangeRadioLegacy(event.target.value)
                  }
                >
                  <Radio.Button value="all">Todas</Radio.Button>
                  <Radio.Button value="pending">Pendientes</Radio.Button>
                  <Radio.Button value="approved">Aprobadas</Radio.Button>
                </Radio.Group>
              </Col>
            </Row>
            {isTablet ? (
              <ResponsiveTable
                dataSource={legacyInvestmentsFiltered}
                loading={loading}
                columns={columns}
              />
            ) : (
              <Table
                bordered
                size="small"
                columns={legacyColumns}
                loading={loadingLegacy}
                dataSource={legacyInvestmentsFiltered}
                scroll={{ x: 1000 }}
              />
            )}
          </Tabs.TabPane>
        </Tabs>
      </Card>
    </div>
  );
};

export default InvestmentsView;
