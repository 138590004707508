import {
  createCryptoGoldPurchase,
  createCryptoGoldPurchasePrivate,
  createGoldPurchase,
  createGoldPurchasePrivate,
  fetchCryptoGoldPurchases,
  fetchGoldPurchases,
} from "./gold-services";
import {
  OnFetchGoldPurchases,
  OnFetchGoldPurchasesError,
  OnCreateGoldPurchase,
  OnCreateGoldPurchaseError,
  OnCreateGoldPurchasePrivate,
  OnCreateGoldPurchasePrivateError,
  OnCreateCryptoGoldPurchase,
  OnCreateCryptoGoldPurchaseError,
  OnCreateCryptoGoldPurchasePrivate,
  OnCreateCryptoGoldPurchasePrivateError,
  OnFetchCryptoGoldPurchases,
  OnFetchCryptoGoldPurchasesError,
} from "./gold-events";
import { handleResponseError } from "./../../shared/utils";

export const fetchGoldPurchasesAction = async (data: {
  page: number;
  pageSize?: number;
  search?: string;
}) => {
  const response = await fetchGoldPurchases(data);

  if (response.ok) {
    const json = await response.json();

    OnFetchGoldPurchases.dispatch(json);
  } else {
    handleResponseError(response);
    OnFetchGoldPurchasesError.dispatch(null);
  }
};

export const createGoldPurchaseAction = async (
  user_id: number,
  amount: number
) => {
  const response = await createGoldPurchase(user_id, amount);

  if (response.ok) {
    const json = await response.json();

    OnCreateGoldPurchase.dispatch(json);
  } else {
    handleResponseError(response);
    OnCreateGoldPurchaseError.dispatch(null);
  }
};

export const createGoldPurchasePrivateAction = async (
  user_id: number,
  amount: number
) => {
  const response = await createGoldPurchasePrivate(user_id, amount);

  if (response.ok) {
    const json = await response.json();

    OnCreateGoldPurchasePrivate.dispatch(json);
  } else {
    handleResponseError(response);
    OnCreateGoldPurchasePrivateError.dispatch(null);
  }
};

export const fetchCryptoGoldPurchasesAction = async (data: {
  page: number;
  pageSize?: number;
  search?: string;
}) => {
  const response = await fetchCryptoGoldPurchases(data);

  if (response.ok) {
    const json = await response.json();

    OnFetchCryptoGoldPurchases.dispatch(json);
  } else {
    handleResponseError(response);
    OnFetchCryptoGoldPurchasesError.dispatch(null);
  }
};

export const createCryptoGoldPurchaseAction = async (
  user_id: number,
  amount: number
) => {
  const response = await createCryptoGoldPurchase(user_id, amount);

  if (response.ok) {
    const json = await response.json();

    OnCreateCryptoGoldPurchase.dispatch(json);
  } else {
    handleResponseError(response);
    OnCreateCryptoGoldPurchaseError.dispatch(null);
  }
};

export const createCryptoGoldPurchasePrivateAction = async (
  user_id: number,
  amount: number
) => {
  const response = await createCryptoGoldPurchasePrivate(user_id, amount);

  if (response.ok) {
    const json = await response.json();

    OnCreateCryptoGoldPurchasePrivate.dispatch(json);
  } else {
    handleResponseError(response);
    OnCreateCryptoGoldPurchasePrivateError.dispatch(null);
  }
};
