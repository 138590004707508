import { Card, Row, Typography } from "antd";
import Table, { ColumnType } from "antd/lib/table";
import React, { useEffect, useState } from "react";
import { IS_TABLET } from "@devsplan/wealthclub-shared";
import { useMedia } from "react-use";
import ResponsiveTable from "../../components/ResponsiveTable";
import { fetchLogsAction } from "./logs-actions";
import { useSubscription } from "@cobuildlab/react-simple-state";
import { OnFetchLogs, OnFetchLogsError } from "./logs-events";
import { LogsData } from "./logs-types";
import { renderDate } from "../../components/Columns";
import moment from "moment";

const LogsView = () => {
  const [logs, setLogs] = useState<LogsData[]>([]);
  const [loading, setLoading] = useState(false);
  const isTablet = useMedia(IS_TABLET);
  const columns: ColumnType<LogsData>[] = [
    {
      title: "#",
      dataIndex: "index",
      fixed: "left",
      width: 60,
    },
    {
      title: "Usuario",
      dataIndex: "user_id",
    },
    {
      title: "Fecha",
      dataIndex: "created_at",
      render: renderDate,
    },
    {
      title: "Valor",
      dataIndex: "value",
      width: 250,
    },
    {
      title: "Valor anterior",
      dataIndex: "value_before",
      width: 250,
    },
    {
      title: "Tipo",
      dataIndex: "type",
    },
    {
      title: "Acción",
      dataIndex: "action",
    },
  ];

  useEffect(() => {
    setLoading(true);
    fetchLogsAction();
  }, []);

  useSubscription(OnFetchLogs, (data) => {
    if (data) {
      setLoading(false);
      setLogs(
        data.slice().sort((x, y) => {
          const dateA = moment(x.created_at);
          const dateB = moment(y.created_at);

          return dateB.diff(dateA, "seconds");
        }).map((l, index) => {
          return {
            ...l,
            index: (data.length + 1) - (index + 1),
          }
        })
      );
    }
  });

  useSubscription(OnFetchLogsError, () => {
    setLoading(false);
  });

  return (
    <Card
      title={
        <Row justify="space-between">
          <Typography.Title level={4} style={{ margin: 0 }}>
            Logs
          </Typography.Title>
        </Row>
      }
    >
      {isTablet ? (
        <ResponsiveTable
          loading={loading}
          dataSource={logs}
          columns={columns}
        />
      ) : (
        <Table
          bordered
          size="small"
          loading={loading}
          dataSource={logs}
          columns={columns}
          scroll={{ x: 1000 }}
        />
      )}
    </Card>
  );
};

export default LogsView;
