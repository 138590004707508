import { ReactComponent as ColombiaFlag } from "../assets/svg/colombia.svg";
import { ReactComponent as CostaRicaFlag } from "../assets/svg/costa-rica.svg";
import { ReactComponent as UruguayFlag } from "../assets/svg/uruguay.svg";

export const API_URL =
  process.env.REACT_APP_ENV === "development"
    ? process.env.REACT_APP_API_URL_DEV
    : process.env.REACT_APP_API_URL_PROD;

export const LOGIN_ROUTE = "/";

export const DASHBOARD_ROUTE = "/dashboard";

export const USERS_ROUTE = "/users";

export const ENVIAR_NOTIFICACIONES = "/enviar-notificaciones";

export const INVESTMENTS_ROUTE = "/investments";

export const USER_DETAILS_ID_ROUTE = "/users/:id";

export const PAYMENTS_ROUTE = "/payments";

export const DEPOSITS_ROUTE = "/deposits";

export const RANKING_ROUTE = "/ranking";

export const GOLD_ROUTE = "/gold";

export const CRYPTO_GOLD_ROUTE = "/crypto_gold";

export const TICKETS_ROUTE = "/tickets";

export const NOTIFICATIONS_ROUTE = "/notifications";

export const ADMIN_NOTIFICATIONS_ROUTE = "/admin-notifications";

export const PAYPAL_ROUTE = "/paypal";

export const TUTORIALS_ROUTE = "/tutorials";

export const LOGS_ROUTE = "/logs";

export const DOWNLOADS_ROUTE = "/downloads";

export const VERSIONS_ROUTE = "/versions";

export const CONTACTS_ROUTE = "/contacts";

export const BONUS_ROUTE = "/bonus";

export const REPRESENTANTS_ROUTE = "/representants";

export const LEADERS_ROUTE = "/leaders";

export const REPORTS_ROUTE = "/reports";

export const CARD_REQUESTS_ROUTE = "/card_requests";

export const TOKEN_KEY = "@wealthclub/token";

export const REFRESH_TOKEN_KEY = "@wealthclub/refresh_token";

export const EMAIL_KEY = "@wealthclub/email";

export const APP_DOMAIN = "https://www.wealthclub.pro";

export const PRIMARY_COLOR = "#F0CB65";

export const BLUE_COLOR = "#02225c";

export const RED_COLOR = "#F54509";

export const GREEN_COLOR = "#06A967";

export const AMOUNTS = [
  500, 1000, 2000, 3000, 4000, 5000, 10000, 20000, 30000, 40000, 50000, 100000,
];

export const PAYMENT_TYPES = ["PayPal", "Efectivo", "Criptomonedas"];

export const PERMISSIONS: { [key: string]: string } = {
  [USERS_ROUTE]: "users",
  [DASHBOARD_ROUTE]: "dashboard",
  [INVESTMENTS_ROUTE]: "investments",
  [DEPOSITS_ROUTE]: "deposits",
  [PAYMENTS_ROUTE]: "payments",
  [REPRESENTANTS_ROUTE]: "representants",
  [LEADERS_ROUTE]: "leaders",
  [BONUS_ROUTE]: "bonus",
  [RANKING_ROUTE]: "ranking",
  [GOLD_ROUTE]: "gold",
  [TICKETS_ROUTE]: "tickets",
  [NOTIFICATIONS_ROUTE]: "notifications",
  [PAYPAL_ROUTE]: "paypal",
  [TUTORIALS_ROUTE]: "tutorials",
  [DOWNLOADS_ROUTE]: "downloads",
  [VERSIONS_ROUTE]: "versions",
  [CONTACTS_ROUTE]: "contacts",
  [LOGS_ROUTE]: "logs",
};

export const PERMISSIONS_URL: { [key: string]: string } = {
  users: USERS_ROUTE,
  dashboard: DASHBOARD_ROUTE,
  investments: INVESTMENTS_ROUTE,
  deposits: DEPOSITS_ROUTE,
  payments: PAYMENTS_ROUTE,
  representants: REPRESENTANTS_ROUTE,
  leaders: LEADERS_ROUTE,
  bonus: BONUS_ROUTE,
  ranking: RANKING_ROUTE,
  gold: GOLD_ROUTE,
  tickets: TICKETS_ROUTE,
  notifications: NOTIFICATIONS_ROUTE,
  paypal: PAYPAL_ROUTE,
  tutorials: TUTORIALS_ROUTE,
  downloads: DOWNLOADS_ROUTE,
  versions: VERSIONS_ROUTE,
  contact: CONTACTS_ROUTE,
  logs: LOGS_ROUTE,
};

export const IS_TABLET = "(max-width: 768px)";

export const GOLD_BUTTONS = [
  25, 50, 100, 250, 500, 750, 1000, 2500, 5000, 7500, 10000, 25000, 50000,
  75000, 100000,
];

export const USER_LEVELS = [
  "Básico",
  "Líder I",
  "Líder II",
  "Líder III",
  "VIP I",
  "VIP II",
  "VIP III",
  "Millonario I",
  "Millonario II",
  "Millonario II",
  "Élite I",
  "Élite II",
  "Élite III",
];

export const COUNTRIES_FLAGS: {
  [key: string]: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
} = {
  colombia: ColombiaFlag,
  uruguay: UruguayFlag,
  costarica: CostaRicaFlag,
};

export const COUNTRIES = ["Colombia", "Costa Rica", "Uruguay"];

export const ADMIN_FILTER_CASH = [3182, 288];
