import React, { useEffect, useState } from "react";
import { useSubscription } from "@cobuildlab/react-simple-state";
import { Card, Row, Table, Typography } from "antd";
import { ColumnType } from "antd/lib/table";
import { useMedia } from "react-use";
import ResponsiveTable from "../../components/ResponsiveTable";
import { COUNTRIES_FLAGS, IS_TABLET } from "../../shared/constants";
import { Representant } from "../../shared/types";
import { fetchRepresentantsAction } from "./representants-actions";
import {
  OnFetchRepresentants,
  OnFetchRepresentantsError,
} from "./representants-events";
import { renderCurrency, renderUserLink } from "../../components/Columns";
import { User } from "@devsplan/wealthclub-shared";

const RepresentantsView = () => {
  const [representants, setRepresentants] = useState<Representant[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const isTablet = useMedia(IS_TABLET);
  const columns: ColumnType<Representant>[] = [
    {
      title: "#",
      dataIndex: "index",
      fixed: "left",
      width: 40,
    },
    {
      title: "Usuario",
      dataIndex: "user_id",
      fixed: "left",
      width: 70,
      render: renderUserLink,
    },
    {
      title: "Nombre",
      dataIndex: "user",
      width: 70,
      render: (value: User) => {
        return (
          <p>
            {value.name} {value.last_name}
          </p>
        );
      },
    },
    {
      title: "País",
      dataIndex: "country",
      width: 70,
      render: (value: string) => {
        const Flag = COUNTRIES_FLAGS[value.toLowerCase()];

        if (Flag) {
          return (
            <Row justify="start" align="middle">
              <Flag width="32px" height="32px" />
              <span style={{ marginLeft: 16 }}>{value}</span>
            </Row>
          );
        }

        return null;
      },
    },
    {
      title: "Lideres",
      dataIndex: "leaders",
      width: 70,
    },
    {
      title: "Referidos directos",
      dataIndex: "total_referrals_direct",
      width: 70,
    },
    {
      title: "Equipo",
      dataIndex: "total_sub_referrals",
      width: 70,
    },
    {
      title: "Volumen Legacy",
      dataIndex: "total_volume_legacy",
      width: 70,
      render: renderCurrency,
    },
    {
      title: "Volumen Gold",
      dataIndex: "total_volume_gold",
      width: 70,
      render: renderCurrency,
    },
  ];

  useEffect(() => {
    setLoading(true);
    fetchRepresentantsAction();
  }, []);

  useSubscription(OnFetchRepresentants, (data) => {
    if (data) {
      setLoading(false);
      setRepresentants(
        data.map((r, index) => {
          return {
            ...r,
            index: (data.length + 1) - (index + 1),
          };
        })
      );
    }
  });

  useSubscription(OnFetchRepresentantsError, () => {
    setLoading(false);
  });

  return (
    <Card
      title={
        <Row justify="space-between" align="middle">
          <Typography.Title level={4} style={{ margin: 0 }}>
            Representantes
          </Typography.Title>
        </Row>
      }
    >
      {isTablet ? (
        <ResponsiveTable
          dataSource={representants}
          columns={columns}
          loading={loading}
        />
      ) : (
        <div>
          <Table
            bordered
            size="small"
            columns={columns}
            dataSource={representants}
            loading={loading}
            scroll={{ x: 1000 }}
          />
        </div>
      )}
    </Card>
  );
};

export default RepresentantsView;
