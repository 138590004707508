import { Button, Input, Modal } from "antd";
import styled from "styled-components";
import { BLUE_COLOR, PRIMARY_COLOR } from "../shared/constants";
import { ReactComponent as PayPalSvg } from "../assets/svg/paypal.svg";
import Icon from "@ant-design/icons";

export const PayPalIcon = (props: any) => (
  <Icon component={PayPalSvg} {...props} />
);

export const FullHeight = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
`;

export const Card = styled.div`
  border: 2px solid ${PRIMARY_COLOR};
  border-radius: 4px;
  padding: 24px;
  background-color: rgba(0, 0, 0, 0.2);
`;

export const CustomModal = styled(Modal)`
  .ant-modal-content {
    background-image: url("/assets/images/background.png");
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 4px;
    border: 1px solid ${PRIMARY_COLOR};
  }

  .ant-modal-header {
    background-color: transparent !important;
    border-bottom-color: white;
  }

  .ant-modal-footer {
    border-top-color: white;
  }
`;

export const CustomButton = styled(Button)`
  border-radius: 20px;
  border: 1px solid ${PRIMARY_COLOR};
  color: ${BLUE_COLOR};
`;

export const FormContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  Form {
    width: 100%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    overflow: hidden;
    object-fit: cover;
  }
`;

export const CustomInput = styled(Input)`
  background-color: transparent;
  border: 1px solid ${PRIMARY_COLOR};
  border-radius: 20px;
  color: white;
`;

export const CustomInputPassword = styled(Input.Password)`
  background-color: transparent;
  border: 1px solid ${PRIMARY_COLOR};
  border-radius: 20px;

  input {
    background-color: transparent;
    color: white;
  }
`;

export const UserName = styled.span`
  color: white;
  margin-right: 16px;
`;

export const LogoHorizontal = styled.img`
  max-width: 100%;
  object-fit: cover;
`;

export const TextEllipsis = styled.p<{ lines: number }>`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: ${(props) => props.lines}; /* number of lines to show */
  -webkit-box-orient: vertical;
  margin: 0px;
`;