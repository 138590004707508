import React, { useCallback, useEffect, useState } from "react";
import { useSubscription } from "@cobuildlab/react-simple-state";
import { Card, Col, Input, Row, Select, Table, Typography } from "antd";
import { fetchReportAction } from "./reports-actions";
import { OnFetchReport, OnFetchReportError } from "./reports-events";
import { renderCurrency, renderUserLink } from "../../components/Columns";
import moment from "moment";
import { SearchOutlined } from "@ant-design/icons";
import { CustomButton } from "../../components";
import { Pagination } from "../../shared/types";
import TablePagination from "../../components/TablePagination";
// import { GREEN_COLOR, RED_COLOR } from "../../shared/constants";
import { User } from "@devsplan/wealthclub-shared";
import { fetchReport } from "./reports-services";
import { generateFileCsv } from "../../shared/utils";

const REPORTS_TYPES = [
  {
    name: "Reporte de Volumenes",
    key: "users_volumes",
  },
  {
    name: "Reporte de Inversores",
    key: "users_investors",
  },
  {
    name: "Reporte de Usuarios",
    key: "users_finance",
  },
];

const ReportsView = () => {
  const [data, setData] = useState<any>([]);
  const [dataFiltered, setDataFiltered] = useState<any>([]);
  const [reportType, setReportType] = useState("users_finance");
  const [order, setOrder] = useState("id");
  const [orderType, setOrderType] = useState("desc");
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState<Pagination>();
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const reportsColumns: {
    [key: string]: {
      title: string;
      dataIndex?: string;
      render?: any;
    }[];
  } = {
    users_volumes: [
      {
        title: "ID",
        dataIndex: "user_id",
      },
      {
        title: "V.P.T. Actual",
        dataIndex: "volume",
        render: renderCurrency,
      },
      {
        title: "V.P.T. Anterior",
        dataIndex: "volume_old",
        render: renderCurrency,
      },
      {
        title: "V.E.T. Actual",
        dataIndex: "volume_team",
        render: renderCurrency,
      },
      {
        title: "V.E.T. Anterior",
        dataIndex: "volume_team_old",
        render: renderCurrency,
      },
    ],
    users_investors: [
      {
        title: "ID",
        dataIndex: "user_id",
      },
      {
        title: "Total Invertido",
        dataIndex: "total_amount",
        render: renderCurrency,
      },
    ],
    users_finance: [
      {
        title: "ID",
        dataIndex: "id",
        render: renderUserLink,
      },
      {
        title: "Nombre completo",
        dataIndex: "full_name",
        render: (_: any, record: User) => `${record.name} ${record.last_name}`,
      },
      {
        title: "Invertido",
        dataIndex: "total_invested",
        render: renderCurrency,
      },
      {
        title: "Pagado",
        dataIndex: "total_paid",
        render: renderCurrency,
      },
      // {
      //   title: "Invertido - Pagado",
      //   render: (_: any, record: any) => {
      //     const total = record.total_invested - record.total_withdraw;

      //     return (
      //       <p style={{ color: total < 0 ? RED_COLOR : GREEN_COLOR }}>
      //         {setFormatCurrency(total)}
      //       </p>
      //     );
      //   },
      // },
      // {
      //   title: "Dinero Safe+Legacy",
      //   render: (_: any, record: any) => {
      //     const total = record.total_safe + record.total_legacy;

      //     return <p>{setFormatCurrency(total)}</p>;
      //   },
      // },
      {
        title: "Caja Fuerte",
        dataIndex: "total_safe",
        render: renderCurrency,
      },
      {
        title: "Legacy",
        dataIndex: "total_legacy",
        render: renderCurrency,
      },
      // {
      //   title: "GOLD",
      //   dataIndex: "gold",
      // },
      // {
      //   title: "GOLD Crypto",
      //   dataIndex: "crypto_gold",
      // },
      {
        title: "Referidos",
        dataIndex: "referrals",
      },
    ],
  };
  const reportsOrderColumns: {
    [key: string]: {
      title: string;
      dataIndex: string;
    }[];
  } = {
    users_finance: [
      {
        title: "ID",
        dataIndex: "id",
      },
    ],
  };
  const onDownloadCSV: {
    [key: string]: Function;
  } = {
    users_finance: () => {
      fetchReport({
        report: reportType,
        page: 4,
        pageSize: 1000,
        search: "",
        order: "id",
        orderType: "desc",
      }).then(async (res) => {
        const { data } = await res.json();

        const header = reportsColumns[reportType].map((c) => c.title);
        const rows = data
          .filter((item: any) => item.total_invested > 0)
          .map((item: any) =>
            reportsColumns[reportType].map(
              (c) => c.dataIndex && item[c.dataIndex]
            )
          );

        generateFileCsv([header, ...rows]);

        console.log(header, rows);
      });
    },
  };
  const columns = reportsColumns[reportType];
  const orderColumns = reportsOrderColumns[reportType];

  const handleOnSearch = useCallback(() => {
    if (search !== "") {
      setLoading(true);
      fetchReportAction({
        report: reportType,
        page: 1,
        search,
        order,
        orderType,
      });
    } else {
      setDataFiltered([...data]);
    }
  }, [search, data, reportType, order, orderType]);

  const handleOnChangeOrder = useCallback(() => {
    setLoading(true);
    fetchReportAction({
      report: reportType,
      page,
      search,
      order,
      orderType,
    });
  }, [search, page, reportType, order, orderType]);

  useEffect(() => {
    if (reportType !== "") {
      setLoading(true);
      fetchReportAction({
        report: reportType,
        page: 1,
        search: "",
        order,
        orderType,
      });
    }
  }, [reportType, order, orderType]);

  useEffect(() => {
    if (search === "") {
      setLoading(true);
      fetchReportAction({
        report: reportType,
        page: 1,
        search: "",
        order,
        orderType,
      });
    }
  }, [search, reportType, order, orderType]);

  useSubscription(OnFetchReport, (data: any) => {
    if (data) {
      setLoading(false);
      // @ts-ignore
      const dataSorted = data.data.slice().sort((x, y) => {
        const dateA = moment(x.created_at);
        const dateB = moment(y.created_at);

        return dateB.diff(dateA, "seconds");
      });

      setPagination(data);

      setData([...dataSorted]);
      setDataFiltered([...dataSorted]);
    }
  });

  useSubscription(OnFetchReportError, (data) => {
    if (data) {
      setLoading(false);
    }
  });

  return (
    <Card
      title={
        <Row justify="space-between" align="middle">
          <Typography.Title level={4} style={{ margin: 0 }}>
            Reportes
          </Typography.Title>
          <Row justify="center" align="middle">
            <Typography.Text style={{ marginRight: 16 }}>
              Tipo de reporte
            </Typography.Text>
            <Select
              value={reportType}
              style={{ width: 300 }}
              onChange={(value) => setReportType(value)}
            >
              {REPORTS_TYPES.map((report) => (
                <Select.Option value={report.key} key={report.key}>
                  {report.name}
                </Select.Option>
              ))}
            </Select>
          </Row>
        </Row>
      }
    >
      <Row style={{ marginBottom: 24, width: "100%" }} gutter={[16, 0]}>
        <Col xs={10}>
          <Input.Group compact>
            <Input
              prefix={<SearchOutlined />}
              value={search}
              onChange={(event) => setSearch(event.target.value)}
              placeholder="Buscar..."
              onPressEnter={handleOnSearch}
              allowClear
              style={{ width: "70%" }}
            />
            <CustomButton
              type="primary"
              block
              onClick={handleOnSearch}
              style={{ width: "30%" }}
            >
              Buscar
            </CustomButton>
          </Input.Group>
        </Col>
        <Col xs={6}>
          <Select
            style={{ width: "100%" }}
            placeholder="Ordenar por"
            onChange={(value: string) => {
              const values = value.toString().split("_");
              const orderType = values[0] === "0" ? "desc" : "asc";
              const columnName = columns[parseInt(values[1])]?.dataIndex;

              if (columnName) {
                setOrder(columnName);
                setOrderType(orderType);
              }

              handleOnChangeOrder();
            }}
          >
            {["Mayor a menor", "Menor a mayor"].map((order, orderIndex) => (
              <Select.OptGroup label={order}>
                {orderColumns.map((column, columnIndex) => {
                  if (column.dataIndex) {
                    return (
                      <Select.Option
                        key={`${orderIndex}_${columnIndex}`}
                        value={`${orderIndex}_${columnIndex}`}
                      >
                        {column.title}
                      </Select.Option>
                    );
                  }

                  return null;
                })}
              </Select.OptGroup>
            ))}
          </Select>
        </Col>
        <Col xs={3}>
          <CustomButton
            type="primary"
            block
            onClick={() => onDownloadCSV[reportType]()}
          >
            Descargar CSV
          </CustomButton>
        </Col>
      </Row>
      <Table
        bordered
        size="small"
        columns={columns}
        dataSource={dataFiltered}
        loading={loading}
        scroll={{ x: 1000 }}
        pagination={false}
      />
      {pagination && (
        <TablePagination
          pagination={pagination}
          onChangePage={(page, pageSize) => {
            setLoading(true);
            setPage(page);
            fetchReportAction({
              report: reportType,
              page,
              pageSize,
              search,
              order,
              orderType,
            });
          }}
        />
      )}
    </Card>
  );
};

export default ReportsView;
