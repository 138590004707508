import { UserInfoAccountant } from './../../shared/types';
import { createEvent } from "@cobuildlab/react-simple-state";
import { User } from "@devsplan/wealthclub-shared";
import { UserPagination } from "../../shared/types";

export const OnFetchUsers = createEvent<UserPagination>();
export const OnFetchUsersError = createEvent();

export const OnFetchUsersBasic = createEvent<User[]>();
export const OnFetchUsersBasicError = createEvent();

export const OnFetchUserInfoAccountant = createEvent<UserInfoAccountant[]>();
export const OnFetchUserInfoAccountantError = createEvent();

export const OnFetchUserDetails = createEvent<User>();
export const OnFetchUserDetailsError = createEvent();

export const OnUpdateUser = createEvent();
export const OnUpdateUserError = createEvent();
