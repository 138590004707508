import { MessageData } from "./messages-types";
import { api, apiUrl } from "./../../api/index";

const messagesUrl = `${apiUrl}/messages`;

export const createMessage = (data: MessageData) => {
  return api(messagesUrl, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const updateMessage = (id: number) => {
  return api(`${messagesUrl}/${id}`, {
    method: "PUT",
  });
};
