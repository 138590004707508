import React, { useEffect, useState } from "react";
import { useSubscription } from "@cobuildlab/react-simple-state";
import { IS_TABLET } from "@devsplan/wealthclub-shared";
import { Card, Row, Typography, Table } from "antd";
import { ColumnType } from "antd/lib/table";
import { useMedia } from "react-use";
import {
  renderCurrency,
  renderDate,
  renderDateFromNow,
  renderUserLink,
} from "../../components/Columns";
import ResponsiveTable from "../../components/ResponsiveTable";
import { Bonus } from "../../shared/types";
import { fetchBonusAction } from "./bonus-actions";
import { OnFetchBonus, OnFetchBonusError } from "./bonus-events";
import moment from "moment";

const BonusView = () => {
  const [bonus, setBonus] = useState<Bonus[]>([]);
  const [loading, setLoading] = useState(false);
  const isTablet = useMedia(IS_TABLET);
  const columns: ColumnType<Bonus>[] = [
    {
      title: "#",
      dataIndex: "index",
    },
    // {
    //   title: "ID",
    //   dataIndex: "id",
    // },
    {
      title: "Usuario",
      dataIndex: "user_id",
      render: renderUserLink,
    },
    {
      title: "Fecha",
      dataIndex: "created_at",
      render: renderDate,
    },
    {
      title: "Creado hace",
      dataIndex: "created_at",
      render: renderDateFromNow,
    },
    {
      title: "Monto",
      dataIndex: "amount",
      render: renderCurrency,
    },
  ];

  useEffect(() => {
    setLoading(true);
    fetchBonusAction();
  }, []);

  useSubscription(OnFetchBonus, (data) => {
    console.log(data);
    if (data) {
      setBonus(
        data
          .slice()
          .sort((x, y) => {
            const dateA = moment(x.created_at);
            const dateB = moment(y.created_at);

            return dateB.diff(dateA, "seconds");
          })
          .map((b, index) => {
            return {
              ...b,
              index: (data.length + 1) - (index + 1),
            };
          })
      );
      setLoading(false);
    }
  });

  useSubscription(OnFetchBonusError, () => {
    setLoading(false);
  });

  return (
    <Card
      title={
        <Row justify="space-between">
          <Typography.Title level={4} style={{ margin: 0 }}>
            Bonos Mensuales
          </Typography.Title>
        </Row>
      }
    >
      {isTablet ? (
        <ResponsiveTable
          loading={loading}
          dataSource={bonus}
          columns={columns}
        />
      ) : (
        <Table
          bordered
          size="small"
          loading={loading}
          dataSource={bonus}
          columns={columns}
          scroll={{ x: 1000 }}
        />
      )}
    </Card>
  );
};

export default BonusView;
