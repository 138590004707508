import { api, apiUrl } from "../../api/index";

const url = `${apiUrl}/investments`;
const legacyUrl = `${apiUrl}/legacy/investments`;
const periodUrl = `${apiUrl}/period_investments`;

export const fetchInvestments = () => {
  return api(url, {
    method: "GET",
  });
};

export const fetchLegacyInvestments = () => {
  return api(legacyUrl, {
    method: "GET",
  });
};

export const updateInvestment = (id: number, data: object) => {
  return api(`${url}/${id}`, {
    method: "PUT",
    body: JSON.stringify(data),
  });
};

export const updateLegacyInvestment = (id: number, data: object) => {
  return api(`${legacyUrl}/${id}`, {
    method: "PUT",
    body: JSON.stringify(data),
  });
};

export const deleteInvestment = (id: number) => {
  return api(`${url}/${id}`, {
    method: "DELETE",
  });
};

export const createInvestment = (data: object) => {
  return api(url, {
    method: "POST",
    body: JSON.stringify(data),
  });
};

// Period investments

export const fetchPeriodInvestments = () => {
  return api(periodUrl, {
    method: "GET",
  });
};

export const updatePeriodInvestment = (id: number, data: object) => {
  return api(`${periodUrl}/${id}`, {
    method: "PUT",
    body: JSON.stringify(data),
  });
};

export const deletePeriodInvestment = (id: number) => {
  return api(`${periodUrl}/${id}`, {
    method: "DELETE",
  });
};
