import React, { FC, Suspense } from "react";
import { Switch } from "react-router-dom";
import LoginView from "./modules/login/LoginView";
import Route from "./providers/Route";
import Loading from "./components/Loading";
import {
  CONTACTS_ROUTE,
  DASHBOARD_ROUTE,
  DEPOSITS_ROUTE,
  GOLD_ROUTE,
  INVESTMENTS_ROUTE,
  LOGIN_ROUTE,
  NOTIFICATIONS_ROUTE,
  PAYMENTS_ROUTE,
  PAYPAL_ROUTE,
  TICKETS_ROUTE,
  USERS_ROUTE,
  TUTORIALS_ROUTE,
  DOWNLOADS_ROUTE,
  USER_DETAILS_ID_ROUTE,
  VERSIONS_ROUTE,
  RANKING_ROUTE,
  LOGS_ROUTE,
  ADMIN_NOTIFICATIONS_ROUTE,
  BONUS_ROUTE,
  REPRESENTANTS_ROUTE,
  LEADERS_ROUTE,
  REPORTS_ROUTE,
  CRYPTO_GOLD_ROUTE,
  CARD_REQUESTS_ROUTE,
} from "./shared/constants";
import DashboardView from "./modules/dashboard/DashboardView";
import LayoutComponent from "./components/Layout/Layout";
import UsersView from "./modules/users/UsersView";
import InvestmentsView from "./modules/investments/InvestmentsView";
import UserDetailsView from "./modules/users/UserDetailsView";
import PaymentsView from "./modules/payments/PaymentsView";
import DepositsView from "./modules/deposits/DepositsView";
import RankingView from "./modules/ranking/RankingView";
import GoldView from "./modules/gold/GoldView";
import TicketsView from "./modules/tickets/TicketsView";
import NotificationsView from "./modules/notifications/NotificationsView";
import PayPalPaymentsView from "./modules/paypal/PayPalPaymentsView";
import TutorialsView from "./modules/tutorials/TutorialsView";
import DownloadsView from "./modules/downloads/DownloadsView";
import VersionsView from "./modules/versions/VersionsView";
import ContactsView from "./modules/contacts/ContactsView";
import LogsView from "./modules/logs/LogsView";
import AdminNotificationsView from "./modules/admin-notifications/AdminNotificationsView";
import BonusView from "./modules/bonus/BonusView";
import RepresentantsView from "./modules/representants/RepresentantsView";
import LeadersView from "./modules/leaders/LeadersView";
import ReportsView from "./modules/reports/ReportsView";
import GoldCryptoView from "./modules/gold/GoldCryptoView";
import CardRequestsView from "./modules/card-requests/CardRequestsView";

const App: FC = () => {
  return (
    <Suspense fallback={<Loading size={30} />}>
      <Switch>
        <Route
          exact
          path={LOGIN_ROUTE}
          component={LoginView}
          needAuth={false}
        />
        <LayoutComponent>
          <Route
            exact
            path={DASHBOARD_ROUTE}
            component={DashboardView}
            needAuth={true}
          />
          <Route
            exact
            path={USERS_ROUTE}
            component={UsersView}
            needAuth={true}
          />
          <Route
            exact
            path={INVESTMENTS_ROUTE}
            component={InvestmentsView}
            needAuth={true}
          />
          <Route
            exact
            path={USER_DETAILS_ID_ROUTE}
            component={UserDetailsView}
            needAuth
          />
          <Route
            exact
            path={PAYMENTS_ROUTE}
            component={PaymentsView}
            needAuth
          />
          <Route exact path={BONUS_ROUTE} component={BonusView} needAuth />
          <Route
            exact
            path={DEPOSITS_ROUTE}
            component={DepositsView}
            needAuth
          />
          <Route exact path={RANKING_ROUTE} component={RankingView} needAuth />
          <Route exact path={TICKETS_ROUTE} component={TicketsView} needAuth />
          <Route
            exact
            path={NOTIFICATIONS_ROUTE}
            component={NotificationsView}
            needAuth
          />
          <Route
            exact
            path={ADMIN_NOTIFICATIONS_ROUTE}
            component={AdminNotificationsView}
            needAuth
          />
          <Route exact path={GOLD_ROUTE} component={GoldView} needAuth />
          <Route
            exact
            path={CRYPTO_GOLD_ROUTE}
            component={GoldCryptoView}
            needAuth
          />
          <Route
            exact
            path={PAYPAL_ROUTE}
            component={PayPalPaymentsView}
            needAuth
          />
          <Route
            exact
            path={TUTORIALS_ROUTE}
            component={TutorialsView}
            needAuth
          />
          <Route
            exact
            path={DOWNLOADS_ROUTE}
            component={DownloadsView}
            needAuth
          />
          <Route
            exact
            path={VERSIONS_ROUTE}
            component={VersionsView}
            needAuth
          />
          <Route
            exact
            path={CONTACTS_ROUTE}
            component={ContactsView}
            needAuth
          />
          <Route exact path={LOGS_ROUTE} component={LogsView} needAuth />
          <Route
            exact
            path={REPRESENTANTS_ROUTE}
            component={RepresentantsView}
            needAuth
          />
          <Route exact path={LEADERS_ROUTE} component={LeadersView} needAuth />
          <Route exact path={REPORTS_ROUTE} component={ReportsView} needAuth />
          <Route
            exact
            path={CARD_REQUESTS_ROUTE}
            component={CardRequestsView}
            needAuth
          />
        </LayoutComponent>
      </Switch>
    </Suspense>
  );
};

export default App;
