import { Card, Col, Row, Statistic } from "antd";
import React from "react";
import styled from "styled-components";
import LoadingComponent from "../../components/Loading";
import Margin from "../../components/Margin";
import { useKpis } from "./dashboard-hooks";

const CustomCard = styled(Card)`
  min-height: 150px;
`;

const DashboardView = () => {
  const [kpis, loadingKpis] = useKpis();
  return (
    <div>
      <Card>
        {loadingKpis ? (
          <Margin bottom="32px">
            <LoadingComponent />
          </Margin>
        ) : (
          <Row gutter={[24, 24]} align="middle" style={{ marginBottom: 16 }}>
            <Col xs={24} md={6}>
              <CustomCard>
                <Statistic title="Total Usuarios" value={kpis?.users} />
              </CustomCard>
            </Col>
            <Col xs={24} md={6}>
              <CustomCard>
                <Statistic
                  title="Inversionistas"
                  value={kpis?.users_investments}
                />
              </CustomCard>
            </Col>
            <Col xs={24} md={6}>
              <CustomCard>
                <Statistic
                  title="Total Dinero Invertido (inversiones activas)"
                  value={kpis?.total_money_invested}
                  prefix="$"
                />
              </CustomCard>
            </Col>
            <Col xs={24} md={6}>
              <CustomCard>
                <Statistic
                  title="Total Dinero a Pagar"
                  value={kpis?.total_money_to_paid}
                  prefix="$"
                />
              </CustomCard>
            </Col>
            <Col xs={24} md={6}>
              <CustomCard>
                <Statistic
                  title="Total Dinero Pagado"
                  value={Math.abs(kpis?.total_money_paid || 0)}
                  prefix="$"
                />
              </CustomCard>
            </Col>
            <Col xs={24} md={6}>
              <CustomCard>
                <Statistic
                  title="Reserva Gold Total"
                  value={Math.abs(kpis?.gold_subsidies_total || 0)}
                  prefix="$"
                />
              </CustomCard>
            </Col>
            <Col xs={24} md={6}>
              <CustomCard>
                <Statistic
                  title="Reserva Gold 15%"
                  value={Math.abs(kpis?.gold_subsidies || 0)}
                  prefix="$"
                />
              </CustomCard>
            </Col>
            <Col xs={24} md={6}>
              <CustomCard>
                <Statistic
                  title="Reserva Gold OVERFLOW"
                  value={Math.abs(kpis?.gold_subsidies_overflow || 0)}
                  prefix="$"
                />
              </CustomCard>
            </Col>
          </Row>
        )}
      </Card>
    </div>
  );
};

export default DashboardView;
