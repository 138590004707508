import React, { useCallback, useEffect, useState } from "react";
import { useSubscription } from "@cobuildlab/react-simple-state";
/* @ts-ignore */
import { IS_TABLET, Version } from "@devsplan/wealthclub-shared";
import {
  Card,
  Row,
  Typography,
  Table,
  Modal,
  Form,
  Input,
  Button,
  Select,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import { useMedia } from "react-use";
import { renderDate } from "../../components/Columns";
import { createVersionAction, fetchVersionsAction } from "./versions-actions";
import { OnCreateVersion, OnFetchVersions } from "./versions-events";
import ResponsiveTable from "../../components/ResponsiveTable";
import { TextEllipsis } from "../../components";

const VersionsView = () => {
  const [versions, setVersions] = useState<Version[]>([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const isTablet = useMedia(IS_TABLET);
  const [form] = Form.useForm();

  const columns: ColumnsType<Version> = [
    {
      title: "#",
      dataIndex: "id",
    },
    {
      title: "Fecha",
      dataIndex: "created_at",
      render: renderDate,
    },
    {
      title: "Versión",
      dataIndex: "name",
    },
    {
      title: "Descripción",
      dataIndex: "description",
      width: 300,
      render: (value: string) => <TextEllipsis lines={2}>{value}</TextEllipsis>,
    },
    {
      title: "Tipo",
      dataIndex: "type",
    },
  ];

  const handleOnSubmit = useCallback(() => {
    form.validateFields().then((values) => {
      createVersionAction({
        name: values.name,
        description: values.description,
        type: values.type,
      });
    });
  }, [form]);

  useEffect(() => {
    setLoading(true);
    fetchVersionsAction();
  }, []);

  useSubscription(OnFetchVersions, (data) => {
    if (data) {
      setLoading(false);
      setVersions(data);
    }
  });

  useSubscription(OnCreateVersion, () => {
    setShowModal(false);
    fetchVersionsAction();
  });

  return (
    <div>
      <Card
        title={
          <Row justify="space-between" align="middle">
            <Typography.Title level={4} style={{ margin: 0 }}>
              Versiones
            </Typography.Title>
            <Button
              type="primary"
              size="large"
              onClick={() => setShowModal(true)}
            >
              Crear versión
            </Button>
          </Row>
        }
      >
        {isTablet ? (
          <ResponsiveTable
            dataSource={versions}
            columns={columns}
            loading={loading}
          />
        ) : (
          <Table
            size="small"
            bordered
            loading={loading}
            columns={columns}
            dataSource={versions}
          />
        )}
      </Card>
      <Modal
        visible={showModal}
        onOk={handleOnSubmit}
        onCancel={() => setShowModal(false)}
        okText="Guardar"
        cancelText="Cancelar"
        title="Crear versión"
        centered
      >
        <Form layout="vertical" form={form}>
          <Form.Item name="name" label="Nombre de la versión">
            <Input />
          </Form.Item>
          <Form.Item name="description" label="Descripción">
            <Input.TextArea rows={4} />
          </Form.Item>
          <Form.Item name="type" label="Tipo">
            <Select>
              <Select.Option value="web_app">Web App</Select.Option>
              <Select.Option value="web_backoffice">
                Web Backoffice
              </Select.Option>
              <Select.Option value="app_mobile">App Mobile</Select.Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default VersionsView;
