import { useSubscription } from "@cobuildlab/react-simple-state";
import { Balance } from "@devsplan/wealthclub-shared";
import {
  Button,
  Card,
  Col,
  Popconfirm,
  Row,
  Table,
  Tabs,
  Typography,
} from "antd";
import { ColumnType } from "antd/lib/table";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useMedia } from "react-use";
import {
  renderApproved,
  renderCurrency,
  renderDate,
  renderDateFromNow,
  renderPaymentType,
  renderUserLink,
  renderVoucher,
} from "../../components/Columns";
import ResponsiveTable from "../../components/ResponsiveTable";
import TableSearch from "../../components/TableSearch";
import { useAuth } from "../../providers/AuthProvider";
import { ADMIN_FILTER_CASH, IS_TABLET } from "../../shared/constants";
import {
  deleteDepositAction,
  fetchDepositsAction,
  updateDepositAction,
} from "./deposits-actions";
import {
  OnDeleteDeposit,
  OnFetchDeposits,
  OnUpdateDeposit,
} from "./deposits-events";

const DepositsView = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [deposits, setDeposits] = useState<Balance[]>([]);
  const [depositsFiltered, setDepositsFiltered] = useState<Balance[]>([
    ...deposits,
  ]);
  const isTablet = useMedia(IS_TABLET);
  const { user } = useAuth();

  const columns: ColumnType<Balance>[] = [
    {
      title: "#",
      dataIndex: "index",
      fixed: "left",
      width: 60,
    },
    // {
    //   title: "ID",
    //   dataIndex: "id",
    //   sorter: (a, b) => a.id - b.id,
    //   fixed: "left",
    // },
    {
      title: "Fecha",
      dataIndex: "created_at",
      render: renderDate,
      sorter: (a, b) => {
        const dateA = moment(a.created_at).seconds();
        const dateB = moment(b.created_at).seconds();

        return dateA - dateB;
      },
    },
    {
      title: "Creado hace",
      dataIndex: "created_at",
      render: renderDateFromNow,
    },
    {
      title: "Código",
      dataIndex: "code",
    },
    {
      title: "Usuario",
      dataIndex: "user_id",
      sorter: (a, b) => a.user_id - b.user_id,
      render: renderUserLink,
    },
    {
      title: "Monto",
      dataIndex: "balance",
      render: renderCurrency,
      sorter: (a, b) => a.balance - b.balance,
    },
    {
      title: "Método de pago",
      dataIndex: "payment_type",
      render: renderPaymentType,
    },
    {
      title: "Comprobante",
      render: renderVoucher,
    },
    {
      title: "Estado",
      dataIndex: "status",
      render: renderApproved,
    },
  ];
  const columnsPending: ColumnType<Balance>[] = [
    ...columns,
    {
      title: "Action",
      width: 200,
      render: (_, record: Balance) => (
        <Row justify="space-around">
          <Popconfirm
            title="¿Esta seguro de aprobar este deposito?"
            onConfirm={() => handleOnUpdateDeposit(record.id, true)}
            okText="Si"
            cancelText="No"
          >
            <Button type="link">Aprobar</Button>
          </Popconfirm>
          <Popconfirm
            title="¿Esta seguro de cancelar este deposito?"
            onConfirm={() => handleOnDeleteDeposit(record.id)}
            okText="Si"
            cancelText="No"
          >
            <Button type="link">Cancelar</Button>
          </Popconfirm>
        </Row>
      ),
    },
  ];
  const columnsDone: ColumnType<Balance>[] = [
    ...columns,
    {
      title: "Action",
      width: 200,
      render: (_, record: Balance) => (
        <Row justify="space-around">
          <Popconfirm
            title="¿Esta seguro de rechazar este deposito?"
            onConfirm={() => handleOnUpdateDeposit(record.id, false)}
            okText="Si"
            cancelText="No"
          >
            <Button type="link">Rechazar</Button>
          </Popconfirm>
          <Popconfirm
            title="¿Esta seguro de cancelar este deposito?"
            onConfirm={() => handleOnDeleteDeposit(record.id)}
            okText="Si"
            cancelText="No"
          >
            <Button type="link">Cancelar</Button>
          </Popconfirm>
        </Row>
      ),
    },
  ];

  const handleOnUpdateDeposit = (id: number, status: boolean) => {
    setLoading(true);
    updateDepositAction(id, status);
  };

  const handleOnDeleteDeposit = (id: number) => {
    setLoading(true);
    deleteDepositAction(id);
  };

  useEffect(() => {
    setLoading(true);
    fetchDepositsAction();
  }, []);

  useSubscription(OnFetchDeposits, (deposits) => {
    if (deposits) {
      setLoading(false);

      const filteredDeposits =
        ADMIN_FILTER_CASH.indexOf(user.id) !== -1
          ? deposits.filter((i) => i.payment_type === 1)
          : deposits;

      const sortedDeposits = filteredDeposits
        .slice()
        .sort((a, b) => {
          const dateA = moment(a.created_at);
          const dateB = moment(b.created_at);

          return dateB.diff(dateA, "seconds");
        })
        .map((d, index) => {
          return {
            ...d,
            index: deposits.length + 1 - (index + 1),
          };
        });

      setDeposits(sortedDeposits);
      setDepositsFiltered([...sortedDeposits]);
    }
  });

  useSubscription(OnUpdateDeposit, () => {
    fetchDepositsAction();
  });

  useSubscription(OnDeleteDeposit, () => {
    fetchDepositsAction();
  });

  const depositsPendings = depositsFiltered?.filter(
    (deposit) => !deposit.status
  );
  const depositsDone = depositsFiltered?.filter((deposit) => deposit.status);

  return (
    <Card
      title={
        <Row justify="space-between" align="middle">
          <Typography.Title level={4} style={{ margin: 0 }}>
            Depositos
          </Typography.Title>
        </Row>
      }
    >
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="Todos" key="0">
          <Row style={{ marginBottom: 16 }}>
            <Col xs={24} sm={24} md={12}>
              <TableSearch
                keys={["code", "user_id", "balance"]}
                dataOriginal={deposits}
                data={deposits}
                placeholder="Buscar por Código, Usuario o Monto"
                onChange={(data) => {
                  setDepositsFiltered(data);
                }}
              />
            </Col>
          </Row>
          {isTablet ? (
            <ResponsiveTable
              loading={loading}
              dataSource={deposits}
              columns={columnsPending}
            />
          ) : (
            <Table
              bordered
              size="small"
              loading={loading}
              dataSource={deposits}
              columns={columnsPending}
              scroll={{ x: 1000 }}
            />
          )}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Pendientes" key="1">
          <Row style={{ marginBottom: 16 }}>
            <Col xs={24} sm={24} md={12}>
              <TableSearch
                keys={["code", "user_id", "balance"]}
                dataOriginal={deposits}
                data={depositsPendings}
                placeholder="Buscar por Código, Usuario o Monto"
                onChange={(data) => {
                  setDepositsFiltered(data);
                }}
              />
            </Col>
          </Row>
          {isTablet ? (
            <ResponsiveTable
              loading={loading}
              dataSource={depositsPendings}
              columns={columnsPending}
            />
          ) : (
            <Table
              bordered
              size="small"
              loading={loading}
              dataSource={depositsPendings}
              columns={columnsPending}
              scroll={{ x: 1000 }}
            />
          )}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Aprobados" key="2">
          <Row style={{ marginBottom: 16 }}>
            <Col xs={24} sm={24} md={12}>
              <TableSearch
                keys={["code", "user_id", "balance"]}
                dataOriginal={deposits}
                data={depositsDone}
                placeholder="Buscar por código, usuario o monto"
                onChange={(data) => {
                  setDepositsFiltered(data);
                }}
              />
            </Col>
          </Row>
          {isTablet ? (
            <ResponsiveTable
              loading={loading}
              dataSource={depositsDone}
              columns={columnsDone}
            />
          ) : (
            <Table
              bordered
              size="small"
              loading={loading}
              dataSource={depositsDone}
              columns={columnsDone}
              scroll={{ x: 1000 }}
            />
          )}
        </Tabs.TabPane>
      </Tabs>
    </Card>
  );
};

export default DepositsView;
