import { GOLD_BUTTONS } from "./../../shared/constants";
import { api, apiUrl } from "./../../api/index";

const url = `${apiUrl}/gold`;
const cryptoUrl = `${apiUrl}/crypto_gold`;

export const fetchGoldPurchases = ({
  page,
  pageSize = 10,
  search = "",
}: {
  page: number;
  pageSize?: number;
  search?: string;
}) => {
  return api(
    `${url}/purchases?page=${page}&per_page=${pageSize || 10}${
      search !== "" ? `&search=${search}` : ""
    }`,
    {
      method: "GET",
    }
  );
};

export const fetchCryptoGoldPurchases = ({
  page,
  pageSize = 10,
  search = "",
}: {
  page: number;
  pageSize?: number;
  search?: string;
}) => {
  return api(
    `${cryptoUrl}/purchases?page=${page}&per_page=${pageSize || 10}${
      search !== "" ? `&search=${search}` : ""
    }`,
    {
      method: "GET",
    }
  );
};

export const createGoldPurchase = (user_id: number, amount: number) => {
  return api(`${url}/purchases`, {
    method: "POST",
    body: JSON.stringify({
      user_id,
      amount,
      index: GOLD_BUTTONS.indexOf(amount) + 1,
    }),
  });
};

export const createCryptoGoldPurchase = (user_id: number, amount: number) => {
  return api(`${cryptoUrl}/purchases`, {
    method: "POST",
    body: JSON.stringify({
      user_id,
      amount,
      index: GOLD_BUTTONS.indexOf(amount) + 1,
    }),
  });
};

export const createGoldPurchasePrivate = (user_id: number, amount: number) => {
  return api(`${url}/purchases_private`, {
    method: "POST",
    body: JSON.stringify({
      user_id,
      amount,
      index: GOLD_BUTTONS.indexOf(amount) + 1,
      crypto: "bitcoin",
    }),
  });
};

export const createCryptoGoldPurchasePrivate = (
  user_id: number,
  amount: number
) => {
  return api(`${cryptoUrl}/purchases_private`, {
    method: "POST",
    body: JSON.stringify({
      user_id,
      amount,
      index: GOLD_BUTTONS.indexOf(amount) + 1,
      crypto: "bitcoin",
    }),
  });
};
