import React, { useCallback, useEffect, useState } from "react";
import { useSubscription } from "@cobuildlab/react-simple-state";
import { User } from "@devsplan/wealthclub-shared";
import {
  Button,
  Card,
  Form,
  Modal,
  Row,
  Table,
  Col,
  Input,
  Typography,
  Select,
  notification,
  Popconfirm,
  Tooltip,
} from "antd";
import { ColumnType } from "antd/lib/table";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useMedia } from "react-use";
import { CustomButton } from "../../components";
import { renderStatus, renderUserName } from "../../components/Columns";
import ResponsiveTable from "../../components/ResponsiveTable";
import TablePagination from "../../components/TablePagination";
import { IS_TABLET, USERS_ROUTE } from "../../shared/constants";
import { Pagination } from "../../shared/types";
import { setFormatCurrency } from "../../shared/utils";
import {
  fetchUsersAction,
  fetchUsersBasicAction,
  updateUserAction,
} from "./users-actions";
import {
  OnFetchUsers,
  OnFetchUsersBasic,
  OnUpdateUser,
  OnUpdateUserError,
} from "./users-events";
import { Span } from "../../components/Span";

const UsersView = () => {
  // const [users, setUsers] = useState<User[]>([]);
  const [usersFiltered, setUsersFiltered] = useState<User[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [loadingForm, setLoadingForm] = useState<boolean>(false);
  const [user, setUser] = useState<User | undefined>();
  const [usersBasic, setUsersBasic] = useState<User[]>([]);
  const [pagination, setPagination] = useState<Pagination>();
  const [userId, setUserId] = useState(0);
  const [search, setSearch] = useState("");
  const [form] = Form.useForm();
  const history = useHistory();
  const isTablet = useMedia(IS_TABLET);
  const columns: ColumnType<User>[] = [
    {
      title: "ID",
      dataIndex: "id",
      sorter: (a, b) => a.id - b.id,
      fixed: "left",
      width: 70,
    },
    {
      title: "Fecha de ingreso",
      dataIndex: "created_at",
      sorter: (a, b) => {
        const dateA = moment(a.created_at).seconds();
        const dateB = moment(b.created_at).seconds();

        return dateA - dateB;
      },
      render: (value: string) => moment(value).format("DD/MM/YY"),
      width: 200,
    },
    {
      title: "Nombre",
      sorter: (a, b) => a.name.length - b.name.length,
      width: 220,
      render: renderUserName,
    },
    {
      title: "Correo",
      dataIndex: "email",
      sorter: (a, b) => a.email.length - b.email.length,
      width: 250,
      render: (value) => (
        <Tooltip title={value}>
          <Span ellipsis width={200} lineHeight={1.5}>
            {value}
          </Span>
        </Tooltip>
      ),
    },
    {
      title: "Referidos",
      dataIndex: "referrals",
      sorter: (a, b) => a.referrals.length - b.referrals.length,
      width: 100,
    },
    {
      title: "Inv. áctual",
      dataIndex: "current_investment",
      sorter: (a, b) =>
        (a.current_investment ?? 0) - (b.current_investment ?? 0),
      render: (value: any) => setFormatCurrency(value),
      width: 100,
    },
    {
      title: "Total invertido",
      dataIndex: "total_invested",
      sorter: (a, b) => a.total_invested - b.total_invested,
      render: (value: any) => setFormatCurrency(value),
      width: 100,
    },
    {
      title: "Caja fuerte",
      dataIndex: "balance",
      sorter: (a, b) => a.balance - b.balance,
      render: (value: any, record: User) => {
        return (
          <span
            style={{
              color: record.settings
                ? value > record.settings.balance_min_withdrawal
                  ? "green"
                  : "red"
                : value > 500
                ? "green"
                : "red",
            }}
          >
            {setFormatCurrency(value)}
          </span>
        );
      },
      width: 100,
    },
    {
      title: "Caja fuerte bloqueada",
      sorter: (a, b) =>
        (a.settings?.balance_locked || 0) - (b.settings?.balance_locked || 0),
      render: (_, record: User) => {
        return record.settings?.balance_locked ? "Si" : "No";
      },
      width: 100,
    },
    {
      title: "Estado",
      dataIndex: "status",
      sorter: (a, b) => a.status - b.status,
      render: renderStatus,
      width: 100,
      responsive: ["xxl"],
    },
    {
      title: "Action",
      width: 300,
      fixed: "right",
      render: (_, record: User) => (
        <Row justify="space-between" align="middle">
          <Button type="link" onClick={() => handleOnClickEdit(record)}>
            Editar
          </Button>
          <Button
            type="link"
            onClick={() => history.push(`${USERS_ROUTE}/${record.id}`)}
          >
            Ver detalles
          </Button>
          <Popconfirm
            title="¿Esta seguro de desactivar este usuario?"
            onConfirm={() => handleOnUpdateStatus(record.id, !record.status)}
            okText="Si"
            cancelText="No"
          >
            <Button type="link">
              {record.status === 1 ? "Desactivar" : "Activar"}
            </Button>
          </Popconfirm>
        </Row>
      ),
    },
  ];

  const handleOnClickEdit = (user: User) => {
    setUser(user);
    setShowModal(true);
    form.setFieldsValue({
      name: user.name,
      last_name: user.last_name,
      parent_id: user.parent_id,
      email: user.email,
    });
  };

  const handleOnSearch = useCallback(() => {
    setLoading(true);
    fetchUsersAction({ page: 1, search });
  }, [search]);

  const handleOnUpdateUser = useCallback(() => {
    form.validateFields().then((values) => {
      if (user) {
        setLoadingForm(false);
        updateUserAction(user.id, values);
      }
    });
  }, [user, form]);

  const handleOnUpdateStatus = (id: number, status: boolean) => {
    setLoading(true);
    updateUserAction(id, { status: Number(status) });
  };

  useEffect(() => {
    setLoading(true);
    fetchUsersAction({ page: 1 });
    fetchUsersBasicAction();
  }, []);

  useEffect(() => {
    if (search === "") {
      fetchUsersAction({ page: 1 });
    }
  }, [search]);

  useSubscription(OnFetchUsers, (data) => {
    if (data) {
      setLoading(false);
      setPagination(data);
      // setUsers(data.data);
      setUsersFiltered([...data.data.slice().sort((a, b) => b.id - a.id)]);
    }
  });

  useSubscription(OnUpdateUser, () => {
    setLoadingForm(false);
    setUser(undefined);
    setShowModal(false);
    fetchUsersAction({ page: 1 });
    notification.success({
      message: "Actualizado",
      description: "Usuario actualizado con exito",
    });
  });

  useSubscription(OnUpdateUserError, () => {
    setLoadingForm(false);
    setShowModal(false);
    setUser(undefined);
  });

  useSubscription(OnFetchUsersBasic, (data) => {
    if (data) {
      setUsersBasic(data);
    }
  });

  return (
    <Card
      title={
        <Row justify="space-between" align="middle">
          <Typography.Title level={4} style={{ margin: 0 }}>
            Usuarios
          </Typography.Title>
        </Row>
      }
    >
      <Row style={{ marginBottom: 16 }} gutter={[16, 16]}>
        <Col xs={24} sm={24} md={12}>
          <Input.Search
            placeholder="Buscar por ID, Nombre o Correo"
            size="middle"
            style={{ width: "100%" }}
            onPressEnter={handleOnSearch}
            onSearch={handleOnSearch}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            allowClear
          />
        </Col>
        <Col xs={8}>
          <Row gutter={[16, 16]}>
            <Col>
              <Input
                type="number"
                placeholder="Ingresa un ID"
                style={{ width: 130 }}
                onChange={(event) => {
                  setUserId(parseInt(event.target.value));
                }}
              />
            </Col>
            <Col>
              <Button
                type="primary"
                onClick={() => {
                  history.push(`${USERS_ROUTE}/${userId}`);
                }}
              >
                Ver usuario
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      {isTablet ? (
        <ResponsiveTable
          dataSource={usersFiltered}
          columns={columns}
          loading={loading}
        />
      ) : (
        <div>
          <Table
            bordered
            size="small"
            columns={columns}
            dataSource={usersFiltered}
            loading={loading}
            scroll={{ x: 1000 }}
            pagination={false}
          />
          {pagination && (
            <TablePagination
              pagination={pagination}
              onChangePage={(page, pageSize) => {
                fetchUsersAction({ page, pageSize });
              }}
            />
          )}
        </div>
      )}
      <Modal
        visible={showModal}
        title={`Editar usuario #${user?.id}`}
        onCancel={() => setShowModal(false)}
        footer={null}
        width={800}
      >
        <Form layout="vertical" form={form}>
          <Row gutter={[16, 16]}>
            <Col xs={12}>
              <Form.Item name="name" label="Nombre">
                <Input />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item name="last_name" label="Apellido">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={12}>
              <Form.Item name="parent_id" label="Referido de">
                <Select
                  allowClear
                  showSearch
                  optionFilterProp="value"
                  filterOption={(input, option) => {
                    console.log(input, option);

                    if (option) {
                      return option.value === parseInt(input);
                    }

                    return false
                  }}
                >
                  {usersBasic.map((user) => (
                    <Select.Option key={user.id} value={user.id}>
                      {user.id} - {user.email}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item name="email" label="Correo">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <CustomButton
              type="primary"
              htmlType="submit"
              loading={loadingForm}
              onClick={handleOnUpdateUser}
            >
              Guardar
            </CustomButton>
          </Form.Item>
        </Form>
      </Modal>
    </Card>
  );
};

export default UsersView;
