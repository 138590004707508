import { Card, Row, Button, Popconfirm, Typography } from "antd";
import Table, { ColumnType } from "antd/lib/table";
import React, { useEffect, useState } from "react";
import { IS_TABLET, Notification } from "@devsplan/wealthclub-shared";
import { useMedia } from "react-use";
import ResponsiveTable from "../../components/ResponsiveTable";
import {
  deleteNotificationAction,
  fetchMassiveNotificationsAction,
} from "./notifications-actions";
import { useSubscription } from "@cobuildlab/react-simple-state";
import {
  OnDeleteNotification,
  OnFetchMassiveNotifications,
  OnFetchMassiveNotificationsError,
} from "./notifications-events";
import { renderDate } from "../../components/Columns";
import { Span } from "../../components/Span";
import { NOTIFICATIONS_TYPES } from "./SendNotifications";
import ButtonNotifications from "./ButtonNotifications";
import moment from "moment";

const NotificationsView = () => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [loading, setLoading] = useState(false);
  const isTablet = useMedia(IS_TABLET);
  const columns: ColumnType<Notification>[] = [
    {
      title: "#",
      dataIndex: "index",
    },
    {
      title: "Fecha",
      dataIndex: "created_at",
      render: renderDate,
    },
    {
      title: "Title",
      dataIndex: "title",
      render: (value: string) => {
        return (
          <Span width={180} ellipsis>
            {value}
          </Span>
        );
      },
    },
    {
      title: "Descripción",
      dataIndex: "description",
      render: (value: string) => {
        return (
          <Span width={180} ellipsis>
            {value}
          </Span>
        );
      },
    },
    {
      title: "Tipo",
      dataIndex: "type",
      render: (value) => {
        const type = NOTIFICATIONS_TYPES.find((_type) => _type.name === value);

        if (!type) return null;

        return (
          <Row align="middle">
            {type.icon}
            <Span marginLeft="8px">{type.label}</Span>
          </Row>
        );
      },
    },
    {
      title: "Visto por",
      dataIndex: "count",
    },
    {
      title: "Action",
      render: (_, record) => {
        return (
          <Row>
            <Popconfirm
              title="Esta seguro de eliminar esta notificación?"
              okText="Si"
              cancelText="No"
              onConfirm={() => deleteNotificationAction(record.id)}
            >
              <Button type="link" style={{ padding: 0 }}>
                Eliminar
              </Button>
            </Popconfirm>
          </Row>
        );
      },
    },
  ];

  useEffect(() => {
    setLoading(true);
    fetchMassiveNotificationsAction();
  }, []);

  useSubscription(OnFetchMassiveNotifications, (data) => {
    if (data) {
      setLoading(false);

      setNotifications(
        data
          .slice()
          .sort((a, b) => {
            // @ts-ignore
            const dateA = moment(a.updated_at);
            // @ts-ignore
            const dateB = moment(b.updated_at);

            return dateB.diff(dateA);
          })
          .map((n, index) => {
            return {
              ...n,
              index: data.length + 1 - (index + 1),
            };
          })
      );
    }
  });

  useSubscription(OnFetchMassiveNotificationsError, () => {
    setLoading(false);
  });

  useSubscription(OnDeleteNotification, () => {
    setLoading(true);
    fetchMassiveNotificationsAction();
  });

  return (
    <Card
      title={
        <Row justify="space-between">
          <Typography.Title level={4} style={{ margin: 0 }}>
            Notificaciones
          </Typography.Title>
          <ButtonNotifications />
        </Row>
      }
    >
      {isTablet ? (
        <ResponsiveTable
          loading={loading}
          dataSource={notifications}
          columns={columns}
        />
      ) : (
        <Table
          bordered
          size="small"
          loading={loading}
          dataSource={notifications}
          columns={columns}
          scroll={{ x: 1000 }}
        />
      )}
    </Card>
  );
};

export default NotificationsView;
