import React, { useEffect, useState } from "react";
import { useSubscription } from "@cobuildlab/react-simple-state";
import { Contact, IS_TABLET } from "@devsplan/wealthclub-shared";
import { Card, Row, Typography, Table, Descriptions } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useMedia } from "react-use";
import { renderDate } from "../../components/Columns";
import ResponsiveTable from "../../components/ResponsiveTable";
import { fetchContactsAction } from "./contacts-actions";
import { OnFetchContacts } from "./contacts-events";

const ContactsView = () => {
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [loading, setLoading] = useState(false);
  const isTablet = useMedia(IS_TABLET);

  const columns: ColumnsType<Contact> = [
    {
      title: "#",
      dataIndex: "id",
    },
    {
      title: "Fecha",
      dataIndex: "created_at",
      render: renderDate,
    },
    {
      title: "Nombre",
      dataIndex: "full_name",
    },
    {
      title: "Correo",
      dataIndex: "email",
    },
    {
      title: "Asunto",
      dataIndex: "subject",
    },
  ];

  useEffect(() => {
    setLoading(true);
    fetchContactsAction();
  }, []);

  useSubscription(OnFetchContacts, (data) => {
    if (data) {
      setLoading(false);
      setContacts(data);
    }
  });

  return (
    <div>
      <Card
        title={
          <Row justify="space-between" align="middle">
            <Typography.Title level={4} style={{ margin: 0 }}>
              Contacto
            </Typography.Title>
          </Row>
        }
      >
        {isTablet ? (
          <ResponsiveTable
            dataSource={contacts}
            columns={columns}
            loading={loading}
          />
        ) : (
          <Table
            size="small"
            bordered
            loading={loading}
            columns={columns}
            dataSource={contacts.map((item, itemIndex) => ({
              ...item,
              key: itemIndex,
            }))}
            expandable={{
              expandIcon: undefined,
              expandRowByClick: true,
              expandedRowRender: (record) => (
                <Descriptions
                  bordered
                  size="small"
                  layout="vertical"
                  style={{ width: "100%" }}
                  column={1}
                >
                  <Descriptions.Item label="Mensaje">
                    {record.message}
                  </Descriptions.Item>
                </Descriptions>
              ),
            }}
          />
        )}
      </Card>
    </div>
  );
};

export default ContactsView;
