import {
  OnCreateVersion,
  OnCreateVersionError,
  OnFetchVersions,
  OnFetchVersionsError,
} from "./versions-events";
import { createAction } from "@cobuildlab/react-simple-state";
import { createVersion, fetchVersions } from "./versions-services";

export const fetchVersionsAction = createAction(
  OnFetchVersions,
  OnFetchVersionsError,
  fetchVersions
);

export const createVersionAction = createAction(
  OnCreateVersion,
  OnCreateVersionError,
  createVersion
);
