import {
  fetchUsers,
  fetchUserDetails,
  updateUser,
  fetchUserInfoAccountant,
  fetchUsersBasic,
} from "./users-services";
import {
  OnFetchUsers,
  OnFetchUsersError,
  OnFetchUserDetails,
  OnFetchUserDetailsError,
  OnUpdateUser,
  OnUpdateUserError,
  OnFetchUserInfoAccountant,
  OnFetchUserInfoAccountantError,
  OnFetchUsersBasic,
  OnFetchUsersBasicError,
} from "./users-events";
import { handleError, handleResponseError } from "./../../shared/utils";

export const fetchUsersAction = async (data: {
  page: number;
  pageSize?: number;
  search?: string;
}) => {
  let response;

  try {
    response = await fetchUsers(data);
  } catch (error) {
    handleError(error);
    OnFetchUsersError.dispatch(error);
  }

  if (response) {
    const json = await response.json();

    OnFetchUsers.dispatch(json);
  }
};

export const fetchUserDetailsAction = async (id: number) => {
  let response;

  try {
    response = await fetchUserDetails(id);
  } catch (error) {
    handleError(error);
    OnFetchUserDetailsError.dispatch(error);
  }

  if (response) {
    const json = await response.json();

    OnFetchUserDetails.dispatch(json);
  }
};

export const fetchUsersBasicAction = async () => {
  let response;

  try {
    response = await fetchUsersBasic();
  } catch (error) {
    handleError(error);
    OnFetchUsersBasicError.dispatch(error);
  }

  if (response) {
    const json = await response.json();

    OnFetchUsersBasic.dispatch(json);
  }
};

export const updateUserAction = async (id: number, data: object) => {
  const response = await updateUser(id, data);

  if (response.ok) {
    const json = await response.json();

    OnUpdateUser.dispatch(json);
  } else {
    handleResponseError(response);
    OnUpdateUserError.dispatch(null);
  }
};

export const fetchUserInfoAccountantAction = async (id: number) => {
  let response;

  try {
    response = await fetchUserInfoAccountant(id);
  } catch (error) {
    handleError(error);
    OnFetchUserInfoAccountantError.dispatch(error);
  }

  if (response) {
    const json = await response.json();

    OnFetchUserInfoAccountant.dispatch(json);
  }
};
