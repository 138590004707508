import {
  OnFetchCardRequests,
  OnFetchCardRequestsError,
} from "./card-requests-events";
import { createAction } from "@cobuildlab/react-simple-state";
import { fetchCardRequests } from "./card-requests-services";

export const fetchCardRequestsAction = createAction(
  OnFetchCardRequests,
  OnFetchCardRequestsError,
  fetchCardRequests
);
