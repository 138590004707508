import { fetchLogs } from "./logs-services";
import {
  OnFetchLogs,
  OnFetchLogsError,
} from "./logs-events";
import { handleError } from "./../../shared/utils";

export const fetchLogsAction = async () => {
  let response;

  try {
    response = await fetchLogs();
  } catch (error) {
    handleError(error);
    OnFetchLogsError.dispatch(error);
  }
  if (response?.ok) {
    const json = await response.json();
    OnFetchLogs.dispatch(json.data);
  } else {
    handleError({ message: response?.statusText });
    OnFetchLogsError.dispatch(response?.statusText);
  }
};