import { GoldPagination } from "./../../shared/types";
import { createEvent } from "@cobuildlab/react-simple-state";

export const OnFetchGoldPurchases = createEvent<GoldPagination>();
export const OnFetchGoldPurchasesError = createEvent();

export const OnCreateGoldPurchase = createEvent();
export const OnCreateGoldPurchaseError = createEvent();

export const OnCreateGoldPurchasePrivate = createEvent();
export const OnCreateGoldPurchasePrivateError = createEvent();

export const OnFetchCryptoGoldPurchases = createEvent<GoldPagination>();
export const OnFetchCryptoGoldPurchasesError = createEvent();

export const OnCreateCryptoGoldPurchase = createEvent();
export const OnCreateCryptoGoldPurchaseError = createEvent();

export const OnCreateCryptoGoldPurchasePrivate = createEvent();
export const OnCreateCryptoGoldPurchasePrivateError = createEvent();
