import { createEvent } from "@cobuildlab/react-simple-state";
import { Notification } from "@devsplan/wealthclub-shared";

export const OnCreateNotification = createEvent();
export const OnCreateNotificationError = createEvent();

export const OnFetchMassiveNotifications = createEvent<Notification[]>();
export const OnFetchMassiveNotificationsError = createEvent();

export const OnDeleteNotification = createEvent();
export const OnDeleteNotificationError = createEvent();
