import React, { FC, useEffect, useState } from "react";
import { Collapse, Row, Divider, Empty, Pagination } from "antd";
import { ColumnType } from "antd/lib/table";
import LoadingComponent from "./Loading";

interface Props {
  dataSource: { [key: string]: any }[];
  columns: ColumnType<any>[];
  loading?: boolean;
}

const ResponsiveTable: FC<Props> = ({
  dataSource,
  columns,
  loading = false,
}) => {
  const [data, setData] = useState<{ [key: string]: any }[]>([]);
  const [page, setPage] = useState(1);
  const perPage = 10;

  const handleOnChangePage = (page: number) => {
    setPage(page);
  };

  const onShowSizeChange = (current: number, pageSize: number) => {
    //console.log(current, pageSize);
  };

  useEffect(() => {
    if (dataSource) {
      setData([...dataSource.slice(0, perPage)]);
    }
  }, [dataSource, perPage]);

  useEffect(() => {
    if (page) {
      setData([...dataSource.slice((page - 1) * perPage, page * perPage)]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  if (loading) {
    return <LoadingComponent />;
  }

  if (dataSource?.length === 0) {
    return <Empty />;
  }

  return (
    <div>
      <Collapse style={{ marginBottom: 32 }}>
        {data?.map((item, itemKey) => (
          <Collapse.Panel header={`#${item.id}`} key={itemKey}>
            {columns?.map((column, columnKey) => {
              let value: any = "";

              if (column?.dataIndex && typeof column.dataIndex === "string") {
                value = item[column?.dataIndex];
              }

              if (Array.isArray(column.dataIndex)) {
                value = item;

                column.dataIndex.forEach((index) => {
                  value = value ? value[index] : "N/A";
                });
              }

              return (
                <Row key={columnKey} justify="space-between">
                  <span>
                    <b>{column.title}:</b>
                  </span>
                  {column.render ? (
                    <span>{column?.render(value, item, itemKey)}</span>
                  ) : (
                    <span>{value}</span>
                  )}
                  <Divider />
                </Row>
              );
            })}
          </Collapse.Panel>
        ))}
      </Collapse>
      {dataSource.length > perPage && (
        <Pagination
          showSizeChanger={true}
          defaultCurrent={1}
          total={dataSource.length}
          pageSize={perPage}
          onChange={handleOnChangePage}
          onShowSizeChange={onShowSizeChange}
        />
      )}
    </div>
  );
};

export default ResponsiveTable;
