import React, { FC } from "react";
import styled from "styled-components";

interface Props {
  onClick?(): void;
  fontSize: string;
}

const Container = styled.span<Props>`
  cursor: pointer;
  font-size: ${(props) => props.fontSize};
`;

const ButtonIcon: FC<Props> = ({ onClick, children, fontSize }) => {
  return <Container fontSize={fontSize}>{children}</Container>;
};

export default ButtonIcon;
