import { Form, Row, Typography } from "antd";
import React, { useState } from "react";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import {
  Card,
  FullHeight,
  CustomButton,
  CustomInput,
  CustomInputPassword,
} from "../../components";
import Logo from "../../components/Logo";
import Margin from "../../components/Margin";
import { loginAction } from "./login-actions";
import { useSubscription } from "@cobuildlab/react-simple-state";
import { OnLogIn, OnLogInError } from "./login-events";
import { meAction } from "../../api/me/me-actions";
import Background from "../../components/Background";

const LoginView = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleOnSubmit = (values: any) => {
    const data = {
      email: values.email,
      password: values.password,
    };

    setLoading(true);
    loginAction(data);
  };

  useSubscription(OnLogIn, (response) => {
    setLoading(false);
    meAction();
  });

  useSubscription(OnLogInError, (response) => {
    setLoading(false);
  });

  return (
    <Background>
      <FullHeight>
        <Row
          justify="center"
          align="middle"
          style={{ flexDirection: "column" }}
        >
          <Margin bottom="32px">
            <Logo />
          </Margin>
          <Card>
            <Typography.Title
              level={2}
              style={{ textAlign: "center", color: "white" }}
            >
              Ingreso a Tu Oficina
            </Typography.Title>
            <Form onFinish={handleOnSubmit}>
              <Form.Item name="email">
                <CustomInput placeholder="Correo Electronico" size="large" />
              </Form.Item>
              <Form.Item name="password">
                <CustomInputPassword
                  placeholder="Contraseña"
                  size="large"
                  iconRender={(visible) =>
                    visible ? (
                      <EyeTwoTone />
                    ) : (
                      <EyeInvisibleOutlined style={{ color: "white" }} />
                    )
                  }
                />
              </Form.Item>
              <Form.Item>
                <CustomButton
                  block
                  size="middle"
                  type="primary"
                  loading={loading}
                  htmlType="submit"
                >
                  Iniciar Sesión
                </CustomButton>
              </Form.Item>
            </Form>
          </Card>
        </Row>
      </FullHeight>
    </Background>
  );
};

export default LoginView;
