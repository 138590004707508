import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  ClockCircleTwoTone,
  CloseCircleTwoTone,
  CheckCircleTwoTone,
  MessageOutlined,
  DeleteOutlined,
  EditOutlined,
  SendOutlined,
  FileOutlined,
} from "@ant-design/icons";
import { Ticket } from "@devsplan/wealthclub-shared";
import {
  Badge,
  Button,
  Card,
  Col,
  Drawer,
  notification,
  Popconfirm,
  Popover,
  Row,
  Select,
  Tabs,
  Tooltip,
  Typography,
  Tag,
  Descriptions,
} from "antd";
import Table, { ColumnType } from "antd/lib/table";
import { CustomButton, CustomInput } from "../../components";
import { renderDate, renderUserLink } from "../../components/Columns";
import { useTickets } from "./tickets-hooks";
import {
  RED_COLOR,
  GREEN_COLOR,
  IS_TABLET,
  PRIMARY_COLOR,
  BLUE_COLOR,
} from "../../shared/constants";
import { useMedia } from "react-use";
import ResponsiveTable from "../../components/ResponsiveTable";
import {
  deleteTicketAction,
  fetchTicketsAction,
  updateTicketAction,
} from "./tickets-actions";
import { useSubscription } from "@cobuildlab/react-simple-state";
import { OnDeleteTicket, OnUpdateTicket } from "./tickets-events";
import styled from "styled-components";
import {
  createMessageAction,
  updateMessageAction,
} from "../messages/messages-actions";
import { OnCreateMessage } from "../messages/messages-events";

interface PredefinedMessage {
  message: string;
  icon?: any;
}

const TicketChatContent = styled.div`
  height: calc(90vh - 64px);
  background-color: #303030;
  padding: 16px;
  overflow-y: scroll;
`;

const TicketMessage = styled.div<{ isAdmin?: boolean }>`
  padding: 8px 16px;
  border-radius: 5px;
  background-color: ${(props) => (!props.isAdmin ? PRIMARY_COLOR : "#202020")};
  color: ${(props) => (!props.isAdmin ? BLUE_COLOR : "#fff")};
  max-width: 50%;
  margin-bottom: 8px;
`;

const TICKETS_STATUSES = [
  {
    name: "Pendiente",
    value: "pending",
  },
  {
    name: "Atención técnica",
    value: "in_technique",
  },
  {
    name: "Resuelto",
    value: "resolved",
  },
  {
    name: "Cerrado",
    value: "closed",
  },
];

const PREDEFINED_MESSAGES: PredefinedMessage[] = [
  {
    message:
      "Gracias por haber reportado tu problema pronto te daremos una respuesta",
    icon: null,
  },
  {
    message: "Hola, podrias describirnos mejor tu problema por este medio",
    icon: null,
  },
  {
    message: "Todavia estamos resolviendo su problema, disculpe las molestias",
    icon: null,
  },
  { message: "Hola, su problema ya fue resuelto", icon: null },
  { message: "Hola, en que puedo ayudarle?", icon: null },
  { message: "Tiene algun otro problema?", icon: null },
];

const TicketsView = () => {
  const [tickets, loading] = useTickets();
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [status, setStatus] = useState<string | undefined>();
  const [showDrawer, setShowDrawer] = useState(false);
  const [ticket, setTicket] = useState<Ticket | undefined>();
  const [message, setMessage] = useState<string>("");
  const isTablet = useMedia(IS_TABLET);
  const ref: any = useRef(null);

  const handleOnUpdateTicket = useCallback(
    (id: number) => {
      if (status) {
        setLoadingUpdate(true);
        updateTicketAction(id, status);
      }
    },
    [status]
  );

  const handleOnSubmit = useCallback(() => {
    if (ticket && message !== "") {
      setLoadingCreate(true);
      createMessageAction({
        message,
        ticket_id: ticket.id,
        user_id: ticket.user_id,
        from_admin: 1,
      });
    }
  }, [ticket, message]);

  const columns: ColumnType<Ticket>[] = [
    {
      title: "#",
      dataIndex: "id",
    },
    {
      title: "Fecha",
      dataIndex: "created_at",
      render: renderDate,
    },
    {
      title: "Usuario",
      dataIndex: "user_id",
      render: renderUserLink,
    },
    {
      title: "Problema",
      dataIndex: "name",
    },
    {
      title: "Estado",
      dataIndex: "status",
      width: 150,
      render: (value: string) => {
        let statusText = "Pendiente";
        let icon = <ClockCircleTwoTone style={{ fontSize: 24 }} />;

        if (value === "closed") {
          icon = (
            <CloseCircleTwoTone
              twoToneColor={RED_COLOR}
              style={{ fontSize: 24 }}
            />
          );
          statusText = "Cerrado";
        }

        if (value === "resolved") {
          icon = (
            <CheckCircleTwoTone
              twoToneColor={GREEN_COLOR}
              style={{ fontSize: 24 }}
            />
          );
          statusText = "Resuelto";
        }

        return (
          <Row justify="start" align="middle">
            {icon}
            <span style={{ marginLeft: 16 }}>{statusText}</span>
          </Row>
        );
      },
    },
    {
      title: "Acciones",
      width: 260,
      render: (_, record) => {
        const unReadMessages = record.messages?.filter(
          (message) => !message.read && !message.from_admin
        );

        return (
          <Row align="middle">
            <Popover
              title="Actualizar estado"
              trigger={["click"]}
              content={
                <Row style={{ flexDirection: "column" }}>
                  <Select
                    style={{ width: 200 }}
                    value={status}
                    onChange={(value) => setStatus(value)}
                    placeholder="Elige un estado"
                  >
                    {TICKETS_STATUSES.map((ticket, ticketKey) => (
                      <Select.Option value={ticket.value} key={ticketKey}>
                        {ticket.name}
                      </Select.Option>
                    ))}
                  </Select>
                  <CustomButton
                    type="primary"
                    style={{ marginTop: 16, width: 100 }}
                    onClick={() => handleOnUpdateTicket(record.id)}
                    loading={loadingUpdate}
                  >
                    Enviar
                  </CustomButton>
                </Row>
              }
            >
              <Button type="link">
                <EditOutlined style={{ fontSize: 24 }} />
              </Button>
            </Popover>
            {record.file && (
              <a href={record.file?.url} rel="noreferrer" target="_blank">
                <Tooltip title="Ver archivo">
                  <Button type="link">
                    <FileOutlined style={{ fontSize: 24 }} />
                  </Button>
                </Tooltip>
              </a>
            )}
            <Popconfirm
              title="Esta seguro de eliminar este ticket?"
              okText="Si, eliminar"
              cancelText="No"
              onConfirm={() => deleteTicketAction(record.id)}
            >
              <Button type="link">
                <DeleteOutlined style={{ fontSize: 24 }} />
              </Button>
            </Popconfirm>
            <Tooltip title="Ver mensajes">
              <Button type="link">
                <Badge count={unReadMessages?.length}>
                  <MessageOutlined
                    style={{ fontSize: 24, color: PRIMARY_COLOR }}
                    onClick={() => {
                      setTicket(record);
                      setShowDrawer(true);
                    }}
                  />
                </Badge>
              </Button>
            </Tooltip>
          </Row>
        );
      },
    },
  ];

  useEffect(() => {
    if (ticket) {
      ticket.messages?.forEach((message) => {
        if (!message.read && !message.from_admin) {
          updateMessageAction(message.id);
        }
      });
    }

    if (ref && ref.current) {
      ref?.current?.scrollTo(0, ref?.current?.clientHeight);
    }
  }, [ticket]);

  useEffect(() => {
    if (ticket && tickets.length > 0) {
      const findTicket = tickets.find((_ticket) => _ticket.id === ticket.id);

      if (findTicket) {
        setTicket(findTicket);
      }
    }
  }, [tickets, ticket]);

  useSubscription(OnDeleteTicket, () => {
    fetchTicketsAction();
    notification.success({
      message: "Exito",
      description: "Ticket eliminado con exito!",
    });
  });

  useSubscription(OnUpdateTicket, () => {
    setStatus("");
    setLoadingUpdate(false);
    fetchTicketsAction();
    notification.success({
      message: "Exito",
      description: "Ticket actualizado con exito!",
    });
  });

  useSubscription(OnCreateMessage, () => {
    fetchTicketsAction();
    setMessage("");
    fetchTicketsAction();
    setTimeout(() => {
      setLoadingCreate(false);
    }, 1000);
  });

  const tabs = [
    {
      name: "Pendientes",
      data: tickets.filter((ticket) => ticket.status === "pending"),
    },
    {
      name: "Atención técnica",
      // @ts-ignore
      data: tickets.filter((ticket) => ticket.status === "in_technique"),
    },
    {
      name: "Resueltos",
      data: tickets.filter((ticket) => ticket.status === "resolved"),
    },
    {
      name: "Cerrados",
      data: tickets.filter((ticket) => ticket.status === "closed"),
    },
  ];

  return (
    <Card
      title={
        <Row justify="space-between" align="middle">
          <Typography.Title level={4} style={{ margin: 0 }}>
            Tickets de Soporte
          </Typography.Title>
        </Row>
      }
    >
      <Tabs>
        {tabs.map((tab, tabKey) => (
          <Tabs.TabPane key={tabKey} tab={tab.name}>
            {isTablet ? (
              <ResponsiveTable
                dataSource={tab.data}
                columns={columns}
                loading={loading}
              />
            ) : (
              <Table
                size="small"
                bordered
                loading={loading}
                columns={columns}
                dataSource={tab.data.map((item, itemIndex) => ({
                  ...item,
                  key: itemIndex,
                }))}
                expandable={{
                  expandIcon: undefined,
                  expandRowByClick: true,
                  expandedRowRender: (record) => (
                    <Descriptions
                      bordered
                      size="small"
                      layout="vertical"
                      style={{ width: "100%" }}
                      column={2}
                    >
                      <Descriptions.Item label="Descripción">
                        {record.description}
                      </Descriptions.Item>
                      <Descriptions.Item label="Ultima actualización">
                        {renderDate(record.updated_at)}
                      </Descriptions.Item>
                    </Descriptions>
                  ),
                }}
              />
            )}
          </Tabs.TabPane>
        ))}
      </Tabs>
      {ticket && (
        <Drawer
          title="Mensajes"
          placement="right"
          visible={showDrawer}
          width={600}
          bodyStyle={{ padding: 0 }}
          onClose={() => setShowDrawer(false)}
        >
          <Row justify="space-between" style={{ flexDirection: "column" }}>
            <TicketChatContent ref={ref}>
              {ticket.messages?.map((message, messageKey) => (
                <Row
                  key={messageKey}
                  justify={!message.from_admin ? "start" : "end"}
                >
                  <TicketMessage isAdmin={!message.from_admin}>
                    {message.message}
                  </TicketMessage>
                </Row>
              ))}
            </TicketChatContent>
            <div style={{ padding: 16 }}>
              <Row align="middle" gutter={[16, 0]}>
                <Col xs={24} style={{ marginBottom: 5 }}>
                  {PREDEFINED_MESSAGES.map((predefinedMessage, index) => {
                    const message = predefinedMessage.message;
                    const isLongMessage = message.length > 20;

                    const tagMessage = (
                      <Tag
                        onClick={() => setMessage(message)}
                        icon={predefinedMessage.icon}
                        style={{
                          marginBottom: 5,
                          borderRadius: 10,
                          cursor: "pointer",
                        }}
                      >
                        {isLongMessage ? `${message.slice(0, 20)}...` : message}
                      </Tag>
                    );

                    return isLongMessage ? (
                      <Tooltip title={message} key={index}>
                        {tagMessage}
                      </Tooltip>
                    ) : (
                      tagMessage
                    );
                  })}
                </Col>
                <Col xs={21}>
                  <CustomInput
                    onChange={(e) => {
                      setMessage(e.target.value);
                    }}
                    value={message}
                    autoFocus
                    style={{ height: 36, width: "100%" }}
                    placeholder="Escribe tu mensaje"
                    onPressEnter={handleOnSubmit}
                    disabled={loading}
                    maxLength={255}
                  />
                </Col>
                <Col xs={3}>
                  <Row justify="center">
                    <Button
                      type="primary"
                      style={{ borderRadius: 120, width: "100%", height: 36 }}
                      onClick={handleOnSubmit}
                      disabled={loadingCreate}
                    >
                      <Row justify="center" align="middle">
                        <SendOutlined
                          style={{
                            color: BLUE_COLOR,
                            fontSize: 16,
                            marginLeft: 4,
                          }}
                        />
                      </Row>
                    </Button>
                  </Row>
                </Col>
              </Row>
            </div>
          </Row>
        </Drawer>
      )}
    </Card>
  );
};

export default TicketsView;
