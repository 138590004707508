import { message } from "antd";
import { fetchMe } from "./me";
import { OnFetchMeError, OnFetchMe } from "./me-events";

export const meAction = async () => {
  let response;

  try {
    response = await fetchMe();
  } catch (error) {
    OnFetchMeError.dispatch(error);
  }

  if (response) {
    if (response.ok) {
      const json = await response.json();

      OnFetchMe.dispatch(json.data);
    } else {
      message.error("Su sesión ha expirado", 10);
      OnFetchMeError.dispatch(response.statusText);
    }
  }
};
