import {
  OnFetchRepresentants,
  OnFetchRepresentantsError,
} from "./representants-events";
import { createAction } from "@cobuildlab/react-simple-state";
import { fetchRepresentants } from "./representants-services";

export const fetchRepresentantsAction = createAction(
  OnFetchRepresentants,
  OnFetchRepresentantsError,
  fetchRepresentants
);
