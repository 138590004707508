import {
  createInvestment,
  deleteInvestment,
  fetchInvestments,
  updateInvestment,
  fetchLegacyInvestments,
  updateLegacyInvestment,
  fetchPeriodInvestments,
  deletePeriodInvestment,
  updatePeriodInvestment,
} from "./investments-services";
import {
  OnFetchInvestments,
  OnFetchInvestmentsError,
  OnUpdateInvestment,
  OnUpdateInvestmentError,
  OnCreateInvestment,
  OnCreateInvestmentError,
  OnDeleteInvestment,
  OnDeleteInvestmentError,
  OnFetchLegacyInvestments,
  OnFetchLegacyInvestmentsError,
  OnUpdateLegacyInvestment,
  OnUpdateLegacyInvestmentError,
  OnFetchPeriodInvestmentsError,
  OnFetchPeriodInvestments,
  OnDeletePeriodInvestmentError,
  OnDeletePeriodInvestment,
  OnUpdatePeriodInvestmentError,
  OnUpdatePeriodInvestment,
} from "./investments-events";
import { handleError } from "../../shared/utils";

export const fetchInvestmentsAction = async () => {
  let response;

  try {
    response = await fetchInvestments();
  } catch (error) {
    handleError(error);
    OnFetchInvestmentsError.dispatch(error);
  }

  if (response) {
    const json = await response.json();

    OnFetchInvestments.dispatch(json);
  }
};

export const fetchLegacyInvestmentsAction = async () => {
  let response;

  try {
    response = await fetchLegacyInvestments();
  } catch (error) {
    handleError(error);
    OnFetchLegacyInvestmentsError.dispatch(error);
  }

  if (response) {
    const json = await response.json();

    OnFetchLegacyInvestments.dispatch(json);
  }
};

export const updateInvestmentAction = async (id: number, data: object) => {
  let response;

  try {
    response = await updateInvestment(id, data);
  } catch (error) {
    handleError(error);
    OnUpdateInvestmentError.dispatch(error);
  }

  if (response) {
    const json = await response.json();

    OnUpdateInvestment.dispatch(json);
  }
};

export const updateLegacyInvestmentAction = async (
  id: number,
  data: object
) => {
  let response;

  try {
    response = await updateLegacyInvestment(id, data);
  } catch (error) {
    handleError(error);
    OnUpdateLegacyInvestmentError.dispatch(error);
  }

  if (response) {
    const json = await response.json();

    OnUpdateLegacyInvestment.dispatch(json);
  }
};

export const deleteInvestmentAction = async (id: number) => {
  let response;

  try {
    response = await deleteInvestment(id);
  } catch (error) {
    handleError(error);
    OnDeleteInvestmentError.dispatch(error);
  }

  if (response) {
    const json = await response.json();

    OnDeleteInvestment.dispatch(json);
  }
};

export const createInvestmentAction = async (data: object) => {
  let response;

  try {
    response = await createInvestment(data);
  } catch (error) {
    handleError(error);
    OnCreateInvestmentError.dispatch(error);
  }

  if (response) {
    const json = await response.json();

    OnCreateInvestment.dispatch(json);
  }
};

// Period investments

export const fetchPeriodInvestmentsAction = async () => {
  let response;

  try {
    response = await fetchPeriodInvestments();
  } catch (error) {
    handleError(error);
    OnFetchPeriodInvestmentsError.dispatch(error);
  }

  if (response) {
    const json = await response.json();

    OnFetchPeriodInvestments.dispatch(json);
  }
};

export const updatePeriodInvestmentAction = async (id: number, data: object) => {
  let response;

  try {
    response = await updatePeriodInvestment(id, data);
  } catch (error) {
    handleError(error);
    OnUpdatePeriodInvestmentError.dispatch(error);
  }

  if (response) {
    const json = await response.json();

    OnUpdatePeriodInvestment.dispatch(json);
  }
};

export const deletePeriodInvestmentAction = async (id: number) => {
  let response;

  try {
    response = await deletePeriodInvestment(id);
  } catch (error) {
    handleError(error);
    OnDeletePeriodInvestmentError.dispatch(error);
  }

  if (response) {
    const json = await response.json();

    OnDeletePeriodInvestment.dispatch(json);
  }
};
