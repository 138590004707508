import {
  OnUpdateNotification,
  OnUpdateNotificationError,
  OnFetchNotifications,
  OnFetchNotificationsError,
  OnDeleteNotification,
  OnDeleteNotificationError
} from "./admin-notifications-events";
import { handleError } from "../../shared/utils";
import {
  updateNotification,
  fetchNotifications,
  deleteNotification
} from "./admin-notifications-services";

export const updateNotificationAction = async (id: number, status: number) => {
  let response;

  try {
    response = await updateNotification(id, status);
  } catch (error) {
    handleError(error);
    OnUpdateNotificationError.dispatch(error);
  }

  if (response?.ok) {
    const json = await response.json();
    OnUpdateNotification.dispatch(json);
  } else {
    handleError({ message: response?.statusText });
    OnUpdateNotificationError.dispatch(response?.statusText);
  }
};

export const fetchNotificationsAction = async () => {
  let response;
  try {
    response = await fetchNotifications();
  } catch (error) {
    handleError(error);
    OnFetchNotificationsError.dispatch(error);
  }
  if (response?.ok) {
    const json = await response.json();
    OnFetchNotifications.dispatch(json.data);
  } else {
    handleError({ message: response?.statusText });
    OnFetchNotificationsError.dispatch(response?.statusText);
  }
};

export const deleteNotificationAction = async (id: number) => {
  let response;
  try {
    response = await deleteNotification(id);
  } catch (error) {
    handleError(error);
    OnDeleteNotificationError.dispatch(error);
  }
  if (response?.ok) {
    const json = await response.json();
    OnDeleteNotification.dispatch(json.data);
  } else {
    handleError({ message: response?.statusText });
    OnDeleteNotificationError.dispatch(response?.statusText);
  }
};
