import { getTokens } from "../shared/utils";
import { API_URL } from "./../shared/constants";

export const apiUrl = `${API_URL}/api`;

export const api = async (url: string, options: object) => {
  return fetch(url, {
    ...options,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getTokens().token,
    },
  });
};
