import {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
  FC,
  useCallback,
} from "react";
import { getTokens, removeTokens, sortData } from "../shared/utils";
import { useHistory } from "react-router-dom";
import { useSubscription } from "@cobuildlab/react-simple-state";
import { meAction } from "../api/me/me-actions";
import { OnFetchMe, OnFetchMeError } from "../api/me/me-events";
import { DASHBOARD_ROUTE, LOGIN_ROUTE, ADMIN_NOTIFICATIONS_ROUTE } from "../shared/constants";
import { User, Notification } from "@devsplan/wealthclub-shared";
import { notification, Button, Row } from "antd";
import {
  updateNotificationAction,
} from "../modules/admin-notifications/admin-notifications-actions";
import {
  OnFetchNotifications,
} from "../modules/admin-notifications/admin-notifications-events";
import moment from 'moment';
import styled from 'styled-components';

const Columns = styled(Row)`
  flex-direction: column;
`;


const AuthContext: any = createContext(null);

export const Provider: FC = ({ children }) => {
  const [isNotificationDisplayed, setNotificationDisplay] = useState(false);
  const [state, setState] = useState<{
    tokenError?: boolean;
    user: User | null;
  }>({
    tokenError: false,
    user: null,
  });
  const { user } = state;
  const history = useHistory();
  const tokens = getTokens();
  const { token } = useMemo(() => {
    return tokens;
  }, [tokens]);

  const goToLogin = useCallback(() => {
    history.push("/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, history]);

  const goToAdminNotifications = useCallback(() => {
    notification.close("0");
    history.push(ADMIN_NOTIFICATIONS_ROUTE);
  }, [history])

  const getSession = () => {
    if (token) {
      meAction();
    }
  };
  const logout = useCallback(() => {
    removeTokens();
    setState({ user: null });
    goToLogin();
  }, [goToLogin]);

  const handleOnClickRead = (userNotification: Notification) => {
    notification.close(userNotification.id.toString());
    updateNotificationAction(userNotification.id, 1);
  };

  useSubscription(
    OnFetchMe,
    (user) => {
      setState({ user });

      if (LOGIN_ROUTE === window.location.pathname) {
        history.push(DASHBOARD_ROUTE);
      }
    },
    []
  );

  useSubscription(OnFetchNotifications, (notifications) => {
      if (notifications && !isNotificationDisplayed) {
        const notificationsData = notifications.filter((item => item.status === 0));
        setNotificationDisplay(true);
        if (notificationsData.length >= 4) {
          const data = {
            message: (
              <>
                <p
                  style={{
                    margin: 0,
                    fontSize: 16,
                  }}
                >
                  Tienes ({notificationsData.length}) notificaciones sin leer
              </p>
              </>
            ),
            key: "0",
            description: (
              <Columns justify="start" align="top">
                <span>Haz click en el boton para ver todas las notificaciones</span>
                <Button
                  type="link"
                  style={{ marginLeft: -14 }}
                  onClick={() => goToAdminNotifications()}
                >
                  Ver notificaciones
              </Button>
              </Columns>
            ),
            duration: 12,
          };

          const placement = "bottomRight";
          notification.success({ ...data, placement });
        } else {
          const sortNotificationsByDate = sortData(notificationsData.slice());
          sortNotificationsByDate.forEach(
            (userNotification: Notification) => {
              const data = {
                message: (
                  <>
                    <p
                      style={{
                        margin: 0,
                        fontSize: 16,
                      }}
                    >
                      {userNotification.title}
                    </p>
                    <p
                      style={{
                        margin: 0,
                        fontSize: 12,
                        color: "rgba(255, 255, 255, 0.5)",
                      }}
                    >
                      {moment(userNotification.created_at).fromNow()}
                    </p>
                  </>
                ),
                key: userNotification.id.toString(),
                description: (
                  <Columns justify="start" align="top">
                    <span>{userNotification.description}</span>
                    <Button
                      type="link"
                      style={{ marginLeft: -14 }}
                      onClick={() => handleOnClickRead(userNotification)}
                    >
                      Marcar como leido
                  </Button>
                  </Columns>
                ),
                duration: userNotification.important ? 12 : 6,
              };

              const placement = "bottomRight";

              if (userNotification.type === "success") {
                notification.success({ ...data, placement });
              }

              if (userNotification.type === "info") {
                notification.info({ ...data, placement });
              }

              if (userNotification.type === "warning") {
                notification.warning({ ...data, placement });
              }

              if (userNotification.type === "error") {
                notification.error({ ...data, placement });
              }
            }
          );
        }
      }
  }, [])

  useSubscription(
    OnFetchMeError,
    async () => {
      removeTokens();
      setState({ user: null });
      goToLogin();
    },
    []
  );

  useEffect(() => {
    getSession();
    // eslint-disable-next-line
  }, []);

  const value = useMemo(() => {
    return { user, logout };
    // eslint-disable-next-line
  }, [user]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = (): { user: User; logout: Function } => {
  const { user, logout } = useContext(AuthContext);

  return { user, logout };
};
