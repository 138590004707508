import { CopyOutlined } from "@ant-design/icons";
import { message, Tooltip } from "antd";
import React, { FC } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

interface Props {
  value: string;
}

const IconCopyText: FC<Props> = ({ value }) => {
  const handleOnCopy = () => {
    message.success("Texto copiado!");
  };

  return (
    <Tooltip title="Copiar">
      <CopyToClipboard text={value} onCopy={handleOnCopy}>
        <CopyOutlined
          style={{
            fontSize: 24,
            cursor: "pointer",
          }}
        />
      </CopyToClipboard>
    </Tooltip>
  );
};

export default IconCopyText;
