import styled from "styled-components";
import bg from "../assets/images/background.png";

const Background = styled.div`
  background-image: url(${bg});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 100vh;
`;

export default Background;
