import React, { useEffect, useState } from "react";
import { useSubscription } from "@cobuildlab/react-simple-state";
import { Card, Row, Table, Typography, Tooltip } from "antd";
import { ColumnType } from "antd/lib/table";
import { useMedia } from "react-use";
import ResponsiveTable from "../../components/ResponsiveTable";
import { IS_TABLET } from "../../shared/constants";
import { setFormatCurrency } from "../../shared/utils";
import { fetchRankingsAction } from "./ranking-actions";
import { OnFetchRankings, OnFetchRankingsError } from "./ranking-events";
import { Span } from "../../components/Span";
import { Ranking } from "../../shared/types";
import moment from "moment";

const RankingView = () => {
  const [users, setUsers] = useState<Ranking[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const isTablet = useMedia(IS_TABLET);
  const columns: ColumnType<Ranking>[] = [
    {
      title: "#",
      dataIndex: "index",
      width: 50,
    },
    {
      title: "Posición",
      dataIndex: "index",
      sorter: (a, b) => a.index - b.index,
      fixed: "left",
      width: 70,
    },
    {
      title: "Usuario",
      dataIndex: "user_id",
      sorter: (a, b) => a.id - b.id,
      fixed: "left",
      width: 70,
    },
    {
      title: "Nombre",
      dataIndex: "name",
      sorter: (a, b) => a.name.length - b.name.length,
      width: 220,
    },
    {
      title: "Correo",
      dataIndex: "email",
      sorter: (a, b) => a.email.length - b.email.length,
      width: 250,
      render: (value) => (
        <Tooltip title={value}>
          <Span ellipsis width={200} lineHeight={1.5}>
            {value}
          </Span>
        </Tooltip>
      ),
    },
    {
      title: "Referidos",
      dataIndex: "referrals",
      sorter: (a, b) => a.referrals - b.referrals,
      width: 100,
    },
    {
      title: "Total invertido",
      dataIndex: "total_invested",
      sorter: (a, b) => a.total_invested - b.total_invested,
      render: (value: any) => setFormatCurrency(value),
      width: 100,
    },
  ];

  useEffect(() => {
    setLoading(true);
    fetchRankingsAction();
  }, []);

  useSubscription(OnFetchRankings, (data) => {
    if (data) {
      setLoading(false);

      const currentWeek = moment().week();

      const usersOrdered = data
        .slice(0, 11)
        .filter((u) => {
          return moment(u.created_at).week() === currentWeek;
        })
        .sort((a, b) => b.referrals - a.referrals)
        .map((u, index) => ({
          ...u,
          index: (data.length + 1) - (index + 1),
        }));

      setUsers(usersOrdered);
    }
  });

  useSubscription(OnFetchRankingsError, () => {
    setLoading(false);
  });

  return (
    <Card
      title={
        <Row justify="space-between" align="middle">
          <Typography.Title level={4} style={{ margin: 0 }}>
            Ranking
          </Typography.Title>
        </Row>
      }
    >
      {isTablet ? (
        <ResponsiveTable
          dataSource={users}
          columns={columns}
          loading={loading}
        />
      ) : (
        <div>
          <Table
            bordered
            size="small"
            columns={columns}
            dataSource={users}
            loading={loading}
            scroll={{ x: 1000 }}
          />
        </div>
      )}
    </Card>
  );
};

export default RankingView;
