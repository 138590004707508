import React, { useCallback, useEffect, useState } from "react";
import { useSubscription } from "@cobuildlab/react-simple-state";
/* @ts-ignore */
import { Balance, PaymentHistory, User } from "@devsplan/wealthclub-shared";
import {
  Form,
  Table,
  Row,
  Button,
  Tabs,
  Popconfirm,
  Card,
  Col,
  Modal,
  Input,
  Typography,
  Tooltip,
  Timeline,
} from "antd";
import { ColumnType } from "antd/lib/table";
import moment from "moment";
import { useMedia } from "react-use";
import {
  renderApproved,
  renderCenter,
  renderCurrency,
  renderDate,
  renderPaymentType,
  renderPaymentTypeSettings,
  renderUserLink,
  renderUserName,
} from "../../components/Columns";
import ResponsiveTable from "../../components/ResponsiveTable";
import { Span } from "../../components/Span";
import TableSearch from "../../components/TableSearch";
import { IS_TABLET } from "../../shared/constants";
import { createNotificationAction } from "../notifications/notifications-actions";
import { OnCreateNotification } from "../notifications/notifications-events";
import {
  deletePaymentAction,
  fetchDonePaymentsAction,
  fetchNextPaymentsAction,
  fetchPaymentsAction,
  updatePaymentAction,
} from "./payments-actions";
import {
  OnDeletePayment,
  OnFetchDonePayments,
  // OnFetchNextPayments,
  OnFetchPayments,
  OnUpdatePayment,
} from "./payments-events";
import { setFormatCurrency } from "../../shared/utils";
import { Pagination } from "../../shared/types";
import TablePagination from "../../components/TablePagination";

const PaymentsView = () => {
  const [payments, setPayments] = useState<User[]>([]);
  const [paymentsFiltered, setPaymentsFiltered] = useState<User[]>([]);
  // const [nextPayments, setNextPayments] = useState<Cycle[]>([]);
  // const [nextPaymentsFiltered, setNextPaymentsFiltered] = useState<Cycle[]>([]);
  const [donePayments, setDonePayments] = useState<Balance[]>([]);
  const [donePaymentsFiltered, setDonePaymentsFiltered] = useState<Balance[]>(
    []
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [showModalhandleOnApproved, setShowModalhandleOnApproved] =
    useState(false);
  const [showModalhandleOnPaid, setShowModalhandleOnPaid] = useState(false);
  const [showModalCancel, setShowModalCancel] = useState(false);
  const [userId, setUserId] = useState(0);
  const [showModalHistory, setShowModalHistory] = useState(false);
  const [paymentHistory, setPaymentHistory] = useState<PaymentHistory[]>([]);
  const [balanceId, setBalanceId] = useState<number | undefined>();
  const [form] = Form.useForm();
  const isTablet = useMedia(IS_TABLET);
  const [showConfirm, setShowConfirm] = useState(false);
  const [payment, setPayment] = useState<User | undefined>();
  const [pagination, setPagination] = useState<Pagination>();

  const columnsPayments: ColumnType<User>[] = [
    {
      title: "#",
      dataIndex: "index",
      fixed: "left",
      width: 60,
    },
    {
      title: "Usuario",
      dataIndex: "user_id",
      sorter: (a, b) => a.id - b.id,
      render: renderUserLink,
      fixed: "left",
      width: 90,
    },
    {
      title: "Fecha",
      dataIndex: "balance_date",
      render: (value) => renderCenter(renderDate(value)),
      sorter: (a, b) => {
        const dateA = moment(a.balance_date).seconds();
        const dateB = moment(b.balance_date).seconds();

        return dateA - dateB;
      },
      width: 100,
    },
    {
      title: "Nombre",
      sorter: (a, b) => a.name.length - b.name.length,
      width: 200,
      render: renderUserName,
    },
    {
      title: "Correo",
      sorter: (a, b) => a.email.length - b.email.length,
      dataIndex: "email",
      width: 200,
      render: (value) => (
        <Tooltip title={value}>
          <Span ellipsis width={200} lineHeight={1.5}>
            {value}
          </Span>
        </Tooltip>
      ),
    },
    {
      title: "Método de pago",
      width: 140,
      dataIndex: ["settings", "account_type"],
      render: renderPaymentTypeSettings,
    },
    {
      title: "Monto",
      dataIndex: "balance",
      sorter: (a, b) => a.balance - b.balance,
      render: (value: any) => renderCenter(renderCurrency(Math.abs(value))),
      width: 100,
    },
    {
      title: "Fecha de pago",
      dataIndex: "balance_date",
      render: (value: string) => {
        const date = moment(value);
        const nextFriday = date.day(5);
        const diffHours = date.diff(nextFriday, "hours");
        let nextMondayDate = nextFriday;

        if (diffHours < 72) {
          nextMondayDate = date.day(8);
        }

        return renderCenter(nextMondayDate.format("DD/MM/YY"));
      },
      width: 120,
    },
    {
      title: "Action",
      width: 300,
      fixed: "right",
      render: (_, user: User) => (
        <Row justify="space-around" align="middle">
          <Button
            type="link"
            onClick={() => {
              setShowModalHistory(true);
              /* @ts-ignore */
              setPaymentHistory([...(user.balance_history || [])]);
              setBalanceId(user.balance_id);
            }}
          >
            Historial
          </Button>
          {user.balance_status === 0 && (
            <>
              <Button
                onClick={() => {
                  setShowModalhandleOnApproved(true);
                  setPayment(user);
                }}
                type="link"
              >
                Aprobar pago
              </Button>
            </>
          )}
          {user.balance_status === 1 && user.balance_paid === 0 && (
            <>
              <Button
                onClick={() => {
                  setShowModalhandleOnPaid(true);
                  setPayment(user);
                }}
                type="link"
              >
                Marcar como Pagado
              </Button>
            </>
          )}
          <Popconfirm
            title="¿Desea cancelar este pago?"
            onConfirm={() => {
              setUserId(user?.id || 0);
              handleOnDelete(user?.balance_id || 0);
            }}
            okText="Si"
            cancelText="No"
          >
            <Button type="link">Cancelar</Button>
          </Popconfirm>
        </Row>
      ),
    },
  ];

  // const columnsNextPayments: ColumnType<Cycle>[] = [
  //   {
  //     title: "Usuario",
  //     dataIndex: "user_id",
  //     sorter: (a, b) => a.user_id - b.user_id,
  //     render: renderUserLink,
  //     fixed: "left",
  //   },
  //   {
  //     title: "Ciclo #",
  //     sorter: (a, b) => a.number - b.number,
  //     dataIndex: "number",
  //   },
  //   {
  //     title: "Dinero a cobrar",
  //     dataIndex: ["data", "earn_goal"],
  //     sorter: (a, b) => a.data.earn_goal - b.data.earn_goal,
  //     render: renderCurrency,
  //   },
  //   {
  //     title: "Fecha de cobro a Caja Fuerte",
  //     dataIndex: ["investment_data", "finished_date"],
  //     sorter: (a, b) => {
  //       const dateA = moment(a.data.finished_date).seconds();
  //       const dateB = moment(b.data.finished_date).seconds();

  //       return dateA - dateB;
  //     },
  //     render: renderDate,
  //   },
  // ];

  const columnsDonePayments: ColumnType<Balance>[] = [
    {
      title: "#",
      dataIndex: "index",
      fixed: "left",
      width: 60,
    },
    {
      title: "Usuario",
      dataIndex: "user_id",
      sorter: (a, b) => a.user_id - b.user_id,
      fixed: "left",
      render: renderUserLink,
      width: 100,
    },
    {
      title: "Fecha",
      dataIndex: "created_at",
      render: renderDate,
      sorter: (a, b) => {
        const dateA = moment(a.created_at).seconds();
        const dateB = moment(b.created_at).seconds();

        return dateA - dateB;
      },
    },
    {
      title: "Nombre",
      dataIndex: ["user", "name"],
      width: 220,
      render: (value) => (
        <Tooltip title={value}>
          <Span ellipsis width={200} lineHeight={1.5}>
            {value}
          </Span>
        </Tooltip>
      ),
    },
    {
      title: "Correo",
      dataIndex: ["user", "email"],
      width: 220,
      render: (value) => (
        <Tooltip title={value}>
          <Span ellipsis width={200} lineHeight={1.5}>
            {value}
          </Span>
        </Tooltip>
      ),
    },
    {
      title: "Método de pago",
      dataIndex: "payment_type",
      render: renderPaymentType,
    },
    {
      title: "Monto",
      dataIndex: "balance",
      sorter: (a, b) => a.balance - b.balance,
      render: (value: any) => renderCurrency(Math.abs(value)),
    },
    {
      title: "Estado",
      dataIndex: "status",
      render: renderApproved,
    },
  ];

  const handleOnApproved = (user: User) => {
    setLoading(true);
    updatePaymentAction(user.id, {
      status: true,
      paid: false,
    });
  };

  const handleOnPaid = (user: User) => {
    setLoading(true);
    updatePaymentAction(user.id, {
      status: true,
      paid: true,
    });
  };

  const handleOnDelete = (id: number) => {
    setLoading(true);
    deletePaymentAction(id);
    setShowModalCancel(true);
  };

  const handleOnSendNotification = useCallback(() => {
    if (userId) {
      form.validateFields().then((values) => {
        createNotificationAction({
          user_id: userId,
          title: "Su retiro fue cancelado",
          description: values.description,
          type: "error",
        });
      });
    }
  }, [userId, form]);

  useEffect(() => {
    setLoading(true);
    fetchPaymentsAction({ page: 1 });
  }, []);

  useSubscription(
    OnFetchPayments,
    (data: any) => {
      if (data) {
        setPagination(data);

        const payments: User[] = data.data;
        setPayments(payments);
        setPaymentsFiltered([
          ...payments
            .slice()
            .sort((a, b) => {
              const dateA = moment(a.balance_date);
              const dateB = moment(b.balance_date);

              return dateA.diff(dateB);
            })
            .map((done, index) => {
              return {
                ...done,
                index: payments.length + 1 - (index + 1),
              };
            }),
        ]);
        fetchNextPaymentsAction();
        fetchDonePaymentsAction();
      }
    },
    []
  );

  // useSubscription(OnFetchNextPayments, (nextPayments) => {
  //   if (nextPayments) {
  //     setNextPayments(nextPayments);
  //     setNextPaymentsFiltered([
  //       ...nextPayments.slice().sort((a, b) => {
  //         const dateA = moment(a.data.finished_date);
  //         const dateB = moment(b.data.finished_date);

  //         return dateA.diff(dateB);
  //       }),
  //     ]);
  //     fetchDonePaymentsAction();
  //   }
  // });

  useSubscription(
    OnFetchDonePayments,
    (donePayments) => {
      if (donePayments) {
        setLoading(false);

        const donePaymentsSorted = donePayments
          .slice()
          .sort((a, b) => {
            // @ts-ignore
            const dateA = moment(a.updated_at);
            // @ts-ignore
            const dateB = moment(b.updated_at);

            return dateB.diff(dateA);
          })
          .map((done, index) => {
            return {
              ...done,
              index: donePayments.length + 1 - (index + 1),
            };
          });

        setDonePayments(donePaymentsSorted);
        setDonePaymentsFiltered([...donePaymentsSorted]);
      }
    },
    []
  );

  useSubscription(
    OnUpdatePayment,
    () => {
      fetchPaymentsAction({ page: 1 });
      setShowConfirm(false);
      setPayment(undefined);
    },
    []
  );

  useSubscription(OnDeletePayment, () => {
    fetchPaymentsAction({ page: 1 });
  });

  useSubscription(OnCreateNotification, () => {
    setShowModalCancel(false);
    setUserId(0);
  });

  return (
    <Card
      title={
        <Row justify="space-between" align="middle">
          <Typography.Title level={4} style={{ margin: 0 }}>
            Pagos
          </Typography.Title>
        </Row>
      }
    >
      <Tabs defaultActiveKey={"0"}>
        <Tabs.TabPane tab="Pendientes" key="0">
          <Row style={{ marginBottom: 16 }}>
            <Col xs={24} sm={24} md={12}>
              <TableSearch
                keys={["id", "name", "email", "balance"]}
                dataOriginal={payments}
                data={paymentsFiltered}
                placeholder="Buscar por ID, Nombre, Correo o Monto"
                onChange={(data) => {
                  setPaymentsFiltered(data);
                }}
              />
            </Col>
          </Row>
          {isTablet ? (
            <ResponsiveTable
              loading={loading}
              dataSource={paymentsFiltered}
              columns={columnsPayments}
            />
          ) : (
            <>
              <Table
                bordered
                size="small"
                loading={loading}
                dataSource={paymentsFiltered}
                columns={columnsPayments}
                scroll={{ x: 1000 }}
                pagination={false}
              />
              {pagination && (
                <TablePagination
                  pagination={pagination}
                  onChangePage={(page, pageSize) => {
                    setLoading(true);
                    fetchPaymentsAction({
                      page,
                      pageSize,
                    });
                  }}
                />
              )}
            </>
          )}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Pagado" key="2">
          <Row style={{ marginBottom: 16 }}>
            <Col xs={24} sm={24} md={12}>
              <TableSearch
                keys={["user_id", "balance"]}
                dataOriginal={donePayments}
                data={donePaymentsFiltered}
                placeholder="Buscar por Usuario o Monto"
                onChange={(data) => {
                  setDonePaymentsFiltered(data);
                }}
              />
            </Col>
          </Row>
          {isTablet ? (
            <ResponsiveTable
              loading={loading}
              dataSource={donePaymentsFiltered}
              columns={columnsDonePayments}
            />
          ) : (
            <Table
              bordered
              size="small"
              loading={loading}
              dataSource={donePaymentsFiltered}
              columns={columnsDonePayments}
              scroll={{ x: 1000 }}
            />
          )}
        </Tabs.TabPane>
      </Tabs>
      <Modal
        title="Pago cancelado"
        visible={showModalCancel}
        onCancel={() => setShowModalCancel(false)}
        okText="Enviar"
        cancelText="Cancelar"
        onOk={handleOnSendNotification}
        destroyOnClose
      >
        <Form layout="vertical" form={form}>
          <p>Por que se cancelo el pago?</p>
          <Form.Item
            label="Descripción"
            name="description"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input.TextArea
              style={{ width: "auto" }}
              placeholder="Ingresa una breve descripción para notificar al usuario"
              showCount
              maxLength={255}
              rows={4}
            />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title={`Historial del pago #${balanceId}`}
        visible={showModalHistory}
        onCancel={() => setShowModalHistory(false)}
        destroyOnClose
        okText="Ok"
        cancelText="Cancelar"
      >
        <Timeline mode="right">
          {paymentHistory.map((item) => {
            let label;
            const amount = setFormatCurrency(Math.abs(item.amount));
            const date = moment(item.created_at).format("DD/MM/YYYY");

            if (item.type === "retiro") {
              label = (
                <>
                  Retiro de <span style={{ color: "red" }}>{amount}</span>
                </>
              );
            }

            if (item.type === "ganado") {
              label = (
                <>
                  Ganado Legacy <span style={{ color: "green" }}>{amount}</span>
                </>
              );
            }

            if (item.type === "ganado gold") {
              label = (
                <>
                  Ganado Gold <span style={{ color: "green" }}>{amount}</span>
                </>
              );
            }

            if (item.type === "comisión referido") {
              label = (
                <>
                  Comisión referido{" "}
                  <span style={{ color: "green" }}>{amount}</span>
                </>
              );
            }

            return <Timeline.Item label={date}>{label}</Timeline.Item>;
          })}
        </Timeline>
      </Modal>

      {/* Table modals */}
      {payment && payment.balance_status === 0 && (
        <>
          <Modal
            title="Por favor, confirmar"
            visible={showModalhandleOnApproved}
            onCancel={() => setShowModalhandleOnApproved(false)}
            onOk={() => {
              setShowConfirm(true);
              setShowModalhandleOnApproved(false);
            }}
            okText="Si, aprobar"
            cancelText="Cancelar"
            okButtonProps={{
              size: "large",
            }}
            cancelButtonProps={{
              size: "large",
            }}
          >
            <Span size="16px">¿Desea aprobar este pago?</Span>
          </Modal>
          <Modal
            title="Por favor, vuelva a confirmar"
            visible={showConfirm}
            onCancel={() => setShowConfirm(false)}
            onOk={() => handleOnApproved(payment)}
            okText="Si"
            cancelText="No"
            okButtonProps={{
              size: "large",
            }}
            cancelButtonProps={{
              size: "large",
            }}
          >
            <Span size="16px">¿Esta seguro de realizar esta acción?</Span>
          </Modal>
        </>
      )}
      {payment && payment.balance_status === 1 && payment.balance_paid === 0 && (
        <>
          <Modal
            title="Por favor, confirmar"
            visible={showModalhandleOnPaid}
            onCancel={() => setShowModalhandleOnPaid(false)}
            onOk={() => {
              setShowConfirm(true);
              setShowModalhandleOnPaid(false);
            }}
            okText="Si, marcar como pagado"
            cancelText="Cancelar"
            okButtonProps={{
              size: "large",
            }}
            cancelButtonProps={{
              size: "large",
            }}
          >
            <Span size="16px">¿Desea marcar este pago como pagado?</Span>
          </Modal>
          <Modal
            title="Por favor, vuelva a confirmar"
            visible={showConfirm}
            onCancel={() => setShowConfirm(false)}
            onOk={() => handleOnPaid(payment)}
            okText="Si"
            cancelText="No"
            okButtonProps={{
              size: "large",
            }}
            cancelButtonProps={{
              size: "large",
            }}
          >
            <Span size="16px">¿Esta seguro de realizar esta acción?</Span>
          </Modal>
        </>
      )}
    </Card>
  );
};

export default PaymentsView;
