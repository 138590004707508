import React, { useCallback, useEffect, useState } from "react";
import { useSubscription } from "@cobuildlab/react-simple-state";
import { setFormatCurrency } from "@devsplan/wealthclub-shared";
import {
  Button,
  Card,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Table,
  Col,
  Radio,
} from "antd";
import { ColumnType } from "antd/lib/table";
import moment from "moment";
import { useMedia } from "react-use";
import {
  renderDate,
  renderDateTime,
  renderStatus,
} from "../../components/Columns";
import InputTagsComponent from "../../components/InputTags";
import ResponsiveTable from "../../components/ResponsiveTable";
import { GOLD_BUTTONS, IS_TABLET } from "../../shared/constants";
import { GoldPagination, GoldPurchase } from "../../shared/types";
import { goToViewGoldMatrix } from "../../shared/utils";
import {
  createCryptoGoldPurchaseAction,
  createCryptoGoldPurchasePrivateAction,
  fetchCryptoGoldPurchasesAction,
} from "./gold-actions";
import {
  OnCreateCryptoGoldPurchase,
  OnCreateCryptoGoldPurchasePrivate,
  OnFetchCryptoGoldPurchases,
} from "./gold-events";
import styled from "styled-components";
import TablePagination from "../../components/TablePagination";
import { fetchCryptoGoldSubsidiesAction } from "../dashboard/dashboard-actions";
import { OnFetchCryptoGoldSubsidies } from "../dashboard/dashboard-events";
import { GoldSubsidy } from "../../shared/types";

export const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  margin-bottom: 20px;

  & > .ant-switch-checked .ant-switch-inner {
    color: #02225c;
  }
`;

interface ColumnsGoldSubsidies extends ColumnType<GoldSubsidy> {}

const GoldView = () => {
  const [goldPurchases, setGoldPurchases] = useState<GoldPurchase[]>([]);
  const [goldPurchasesFiltered, setGoldPurchasesFiltered] = useState<
    GoldPurchase[]
  >([]);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isMultiplePurchases, setIsMultiplePurchases] = useState(false);
  const [multipleUserIds, setMultipleUserIds] = useState<string[]>([]);
  const [search, setSearch] = useState("");
  const [pagination, setPagination] = useState<GoldPagination>();
  const isTablet = useMedia(IS_TABLET);
  const [form] = Form.useForm();
  const [goldSubsidies, setGoldSubsidies] = useState<GoldSubsidy[]>([]);
  const [goldSubsidiesLoading, setGoldSubsidiesLoading] =
    useState<boolean>(false);
  const [isPrivatePurchase, setIsPrivatePurchase] = useState(false);

  const handleOnSubmit = useCallback(async () => {
    let values: any;

    try {
      values = await form.validateFields();
    } catch (error) {
      console.log(error);
    }

    if (values) {
      const amounts = values.amount || [];

      setLoadingCreate(true);

      if (isMultiplePurchases) {
        amounts.forEach((amount: number) => {
          multipleUserIds.forEach((userId: string) => {
            if (isPrivatePurchase) {
              createCryptoGoldPurchasePrivateAction(parseInt(userId), amount);
            } else {
              createCryptoGoldPurchaseAction(parseInt(userId), amount);
            }
          });
        });
      } else {
        amounts.forEach((amount: number) => {
          if (isPrivatePurchase) {
            createCryptoGoldPurchasePrivateAction(
              parseInt(values.user_id),
              amount
            );
          } else {
            createCryptoGoldPurchaseAction(parseInt(values.user_id), amount);
          }
        });
      }
    }
  }, [isPrivatePurchase, isMultiplePurchases, form, multipleUserIds]);

  const columns: ColumnType<GoldPurchase>[] = [
    {
      title: "#",
      dataIndex: "id",
      sorter: (a, b) => a.id - b.id,
      fixed: "left",
    },
    {
      title: "Fecha",
      dataIndex: "created_at",
      render: renderDateTime,
      sorter: (a, b) => {
        const dateA = moment(a.created_at).seconds();
        const dateB = moment(b.created_at).seconds();

        return dateA - dateB;
      },
    },
    {
      title: "Usuario",
      dataIndex: "user_id",
      sorter: (a, b) => a.user_id - b.user_id,
    },
    {
      title: "Monto",
      dataIndex: "amount",
      sorter: (a, b) => a.amount - b.amount,
    },
    {
      title: "Reserva",
      dataIndex: "total_subsidy",
      sorter: (a, b) => a.total_subsidy - b.total_subsidy,
    },
    {
      title: "Overflow",
      dataIndex: "total_overflow",
      sorter: (a, b) => a.total_overflow - b.total_overflow,
    },
    {
      title: "Comisiones",
      dataIndex: "commission",
      sorter: (a, b) => a.commission - b.commission,
    },
    {
      title: "Tamaño de Matriz",
      dataIndex: ["matrix", "length"],
    },
    {
      title: "Crypto",
      dataIndex: "crypto",
    },
    {
      title: "Estado",
      dataIndex: "status",
      sorter: (a, b) => a.status - b.status,
      render: renderStatus,
    },
    {
      title: "",
      render: (_, record) => (
        <Row justify="center" align="middle">
          <Button
            type="link"
            onClick={() => goToViewGoldMatrix(record.user_id)}
          >
            Ver matriz
          </Button>
        </Row>
      ),
    },
  ];

  const columnsSubsidies: ColumnsGoldSubsidies[] = [
    {
      title: "#",
      dataIndex: "id",
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Fecha",
      dataIndex: "created_at",
      sorter: (a, b) => {
        const dateA = moment(a.created_at);
        const dateB = moment(b.created_at);

        return dateA.diff(dateB);
      },
      render: renderDate,
    },
    {
      title: "ID Usuario",
      dataIndex: "user_id",
      sorter: (a, b) => a.user_id - b.user_id,
    },
    {
      title: "ID de compra",
      sorter: (a, b) => a.gold_purchase_id - b.gold_purchase_id,
      dataIndex: "crypto_gold_purchase_id",
    },
    {
      title: "Monto",
      dataIndex: "amount",
      sorter: (a, b) => a.amount - b.amount,
    },
    {
      title: "Estado",
      dataIndex: "status",
      sorter: (a, b) => a.status - b.status,
      render: renderStatus,
    },
  ];

  const handleOnSearch = useCallback(() => {
    setLoading(true);
    fetchCryptoGoldPurchasesAction({ page: 1, search });
  }, [search]);

  useEffect(() => {
    setLoading(true);
    fetchCryptoGoldPurchasesAction({ page: 1 });
    setGoldSubsidiesLoading(true);
    fetchCryptoGoldSubsidiesAction();
  }, []);

  useEffect(() => {
    if (search === "") {
      fetchCryptoGoldPurchasesAction({ page: 1 });
    }
  }, [search]);

  useSubscription(OnFetchCryptoGoldPurchases, (data) => {
    if (data) {
      setLoading(false);
      setPagination(data);
      setGoldPurchases(data.data);
      setGoldPurchasesFiltered(
        data.data
          .slice()
          .sort((a, b) => {
            const dateA = moment(a.created_at);
            const dateB = moment(b.created_at);

            return dateB.diff(dateA, "seconds");
          })
          .map((g, index) => {
            return {
              ...g,
              index: index + 1,
            };
          })
      );
    }
  });

  useSubscription(OnCreateCryptoGoldPurchase, () => {
    setLoading(true);
    setLoadingCreate(false);
    setShowModal(false);
    fetchCryptoGoldPurchasesAction({ page: 1 });
  });

  useSubscription(OnCreateCryptoGoldPurchasePrivate, () => {
    setLoading(true);
    setLoadingCreate(false);
    setShowModal(false);
    fetchCryptoGoldPurchasesAction({ page: 1 });
  });

  useSubscription(OnFetchCryptoGoldSubsidies, (goldSubsidies) => {
    if (goldSubsidies) {
      setGoldSubsidies(goldSubsidies);
      setGoldSubsidiesLoading(false);
    }
  });

  return (
    <div>
      <Card
        title={
          <Row justify="space-between" align="middle">
            <h1>Compras Gold</h1>
            <Button
              type="primary"
              size="large"
              onClick={() => setShowModal(true)}
            >
              Crear compra
            </Button>
          </Row>
        }
      >
        <Row style={{ marginBottom: 16 }} gutter={[16, 16]}>
          <Col xs={24} sm={24} md={12}>
            <Input.Search
              placeholder="Buscar por ID, Nombre o Correo"
              size="middle"
              style={{ width: "100%" }}
              onPressEnter={handleOnSearch}
              onSearch={handleOnSearch}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              allowClear
            />
          </Col>
        </Row>
        {isTablet ? (
          <ResponsiveTable
            loading={loading}
            dataSource={goldPurchases}
            columns={columns}
          />
        ) : (
          <>
            <Table
              bordered
              size="small"
              loading={loading}
              dataSource={goldPurchasesFiltered}
              columns={columns}
              pagination={false}
              scroll={{ x: 1000 }}
            />
            {pagination && (
              <TablePagination
                pagination={pagination}
                onChangePage={(page, pageSize) => {
                  fetchCryptoGoldPurchasesAction({ page, pageSize });
                }}
              />
            )}
          </>
        )}
        <Modal
          title="Crear compra"
          visible={showModal}
          onCancel={() => setShowModal(false)}
          okText="Enviar"
          cancelText="Cancelar"
          onOk={handleOnSubmit}
          okButtonProps={{
            disabled: isMultiplePurchases && !multipleUserIds.length,
          }}
          confirmLoading={loadingCreate}
        >
          <Form form={form} layout="vertical">
            <Form.Item label="Tipo de compra">
              <Radio.Group
                defaultValue="admin"
                onChange={(e) => {
                  setIsPrivatePurchase(e.target.value === "private");
                }}
              >
                <Radio.Button value="admin">Corporativa</Radio.Button>
                <Radio.Button value="private">Privada</Radio.Button>
              </Radio.Group>
            </Form.Item>
            <Form.Item label="Cantidad de usuarios">
              <Radio.Group
                defaultValue="simple"
                onChange={(e) => {
                  setIsMultiplePurchases(e.target.value === "multiple");
                }}
              >
                <Radio.Button value="simple">Uno</Radio.Button>
                <Radio.Button value="multiple">Múltiple</Radio.Button>
              </Radio.Group>
            </Form.Item>
            {isMultiplePurchases ? (
              <InputTagsComponent
                name="user_id"
                placeholder="Usuario"
                filteredTags={multipleUserIds}
                onNewTags={(newTags) => {
                  setMultipleUserIds(newTags);
                }}
              />
            ) : (
              <Form.Item
                name="user_id"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input placeholder="Usuario" />
              </Form.Item>
            )}
            <Form.Item
              name="amount"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select placeholder="Monto" allowClear showSearch mode="multiple">
                {GOLD_BUTTONS.map((button) => (
                  <Select.Option value={button} key={button}>
                    {setFormatCurrency(button)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </Modal>
      </Card>
      <Card
        title="Reserva Gold"
        bodyStyle={{ padding: 0 }}
        style={{ marginTop: 16 }}
      >
        {isTablet ? (
          <ResponsiveTable
            dataSource={goldSubsidies
              .slice()
              .filter((item: any) => item.overflow === 0)}
            columns={columnsSubsidies}
            loading={goldSubsidiesLoading}
          />
        ) : (
          <Table
            loading={goldSubsidiesLoading}
            dataSource={goldSubsidies
              .slice()
              .filter((item: any) => item.overflow === 0)}
            columns={columnsSubsidies}
          />
        )}
      </Card>
      <Card
        title="Overflow"
        bodyStyle={{ padding: 0 }}
        style={{ marginTop: 16 }}
      >
        {isTablet ? (
          <ResponsiveTable
            dataSource={goldSubsidies
              .slice()
              .filter((item: any) => item.overflow === 1)}
            columns={columnsSubsidies}
            loading={goldSubsidiesLoading}
          />
        ) : (
          <Table
            loading={goldSubsidiesLoading}
            dataSource={goldSubsidies
              .slice()
              .filter((item: any) => item.overflow === 1)}
            columns={columnsSubsidies}
          />
        )}
      </Card>
    </div>
  );
};

export default GoldView;
