import React, { FC, useRef, useState } from "react";
import { Form, Input, Tag, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

type OnNewTagsType = (newTags: Array<string>) => void;

interface InputTagProps {
  name: string;
  rules?: Array<any>;
  placeholder: string;
  filteredTags?: Array<string>;
  onNewTags: OnNewTagsType;
}

const InputTagsComponent: FC<InputTagProps> = ({
  name,
  rules,
  placeholder,
  filteredTags,
  onNewTags,
}) => {
  const [tags, setTags] = useState(filteredTags || []);
  const [inputValue, setInputValue] = useState("");
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState("");

  const editInputRef = useRef(null);

  return (
    <>
      {tags.map((tag, index) => {
        if (editInputIndex === index) {
          return (
            <Input
              style={{ width: 78 }}
              ref={editInputRef}
              key={tag}
              size="small"
              value={editInputValue}
              onChange={handleEditInputChange}
              onBlur={handleEditInputConfirm}
              onPressEnter={handleEditInputConfirm}
            />
          );
        }

        const isLongTag = tag.length > 20;

        const tagElem = (
          <Tag
            style={{ textTransform: "none" }}
            key={tag}
            closable
            onClose={() => handleClose(tag)}
          >
            <span
              onDoubleClick={(e) => {
                setEditInputIndex(index);
                setEditInputValue(tag);
                setTimeout(() => {
                  /* @ts-ignore */
                  editInputRef && editInputRef.current.focus();
                });
                e.preventDefault();
              }}
            >
              {isLongTag ? `${tag.slice(0, 20)}...` : tag}
            </span>
          </Tag>
        );
        return isLongTag ? (
          <Tooltip title={tag} key={tag}>
            {tagElem}
          </Tooltip>
        ) : (
          tagElem
        );
      })}

      <Form.Item style={{ marginTop: 10 }} name={name} rules={rules}>
        <Input
          suffix={
            <Tooltip title="Al escribir el ID de un usuario presione ENTER para añadirlo. Si desea editar un ID puede hacer doble click.">
              <InfoCircleOutlined style={{ color: "white" }} />
            </Tooltip>
          }
          placeholder={placeholder}
          value={inputValue}
          onChange={handleInputChange}
          onBlur={() => {
            handleInputConfirm();
          }}
          onPressEnter={handleInputConfirm}
          onKeyPress={handleCommaValidation}
        />
      </Form.Item>
    </>
  );

  function handleClose(removedTag: string) {
    const filteredTags = tags.filter((tag) => tag !== removedTag);
    setTags(filteredTags);
    onNewTags && onNewTags(filteredTags);
  }

  function handleInputChange(e: any) {
    setInputValue(e.target.value);
  }

  function handleInputConfirm() {
    if (inputValue && tags.indexOf(inputValue) === -1) {
      const newTags = [...tags, inputValue];
      setTags(newTags);
      onNewTags && onNewTags(newTags);
    }
    setInputValue("");
  }

  function handleEditInputChange(e: any) {
    setEditInputValue(e.target.value);
  }

  function handleEditInputConfirm(e: any) {
    const newTags = [...tags];
    newTags[editInputIndex] = editInputValue;
    setTags(newTags);
    onNewTags && onNewTags(newTags);
    setEditInputIndex(-1);
    setEditInputValue("");
    setInputValue("");
  }

  function handleCommaValidation(e: any) {
    if (/[,]/.test(e.key)) e.preventDefault();
  }
};

InputTagsComponent.defaultProps = {
  filteredTags: [],
  rules: [],
};

export default InputTagsComponent;
