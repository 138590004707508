import React from "react";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { PAYMENT_METHODS, User } from "@devsplan/wealthclub-shared";
import { Badge, Button, Popover, Row, Tooltip, Typography } from "antd";
import moment from "moment";
import { Link } from "react-router-dom";
import { USERS_ROUTE } from "../shared/constants";
import { setFormatCurrency } from "../shared/utils";
import IconCopyText from "./IconCopyText";
import { Span } from "./Span";

export const renderStatus = (value: any) => (
  <Row>
    <Badge color={value === 1 ? "green" : "red"} />
    <span>{value === 1 ? "Activo" : "Inactivo"}</span>
  </Row>
);

export const renderDate = (value: string) =>
  value ? moment(value).format("DD/MM/YY") : "Sin fecha";

export const renderDateFromNow = (value: string) =>
  value ? moment(value).fromNow() : "Sin fecha";

export const renderDateTime = (value: string) =>
  value ? moment(value).format("DD/MM/YY hh:mm A") : "Sin fecha";

export const renderYesNo = (value: any) => (value ? "Si" : "No");

export const renderEmpty = (value: any) => (value ? value : "-");

export const renderCurrency = (value: any) => setFormatCurrency(value);

export const renderSystemApproved = () => (
  <Row justify="start" align="middle">
    <Typography.Text>Aprobado por el sistema</Typography.Text>
    <CheckCircleOutlined
      style={{ marginLeft: 8, color: "green", fontSize: 18 }}
    />
  </Row>
);

export const renderApproved = (value: boolean) => (
  <Row justify="start" align="middle">
    <Typography.Text>{value ? "Aprobado" : "Sin aprobar"}</Typography.Text>
    {value ? (
      <CheckCircleOutlined
        style={{ marginLeft: 8, color: "green", fontSize: 18 }}
      />
    ) : (
      <CloseCircleOutlined
        style={{ marginLeft: 8, color: "red", fontSize: 18 }}
      />
    )}
  </Row>
);

export const renderUpperCase = (value: string) =>
  value ? value.toUpperCase() : "-";

export const renderPaymentTypeSettings = (
  _: any,
  record: User,
  index: number,
  withDetails?: boolean
) => {
  const accountType = record.settings?.account_type;
  const typeDetails: { [key: number]: any } = {
    1: "EFECTIVO",
    2: "PAYPAL",
    3: `WALLET ${record.settings?.crypto?.toUpperCase()}`,
    4: "CRYPTO",
  };
  const accountDetails: { [key: number]: any } = {
    1: record.settings?.address,
    2: record.settings?.paypal_email,
    3: record.settings?.wallet_address,
    4: record.settings?.bank_details,
  };
  const details = accountDetails[accountType || 1];
  const type = typeDetails[accountType || 1];

  return (
    <Row justify="space-between" align="middle">
      {accountType ? (
        <>
          <span style={{ marginRight: 2 }}>{type?.toUpperCase()}</span>
          {withDetails && <span>{details}</span>}
          <IconCopyText value={details} />
        </>
      ) : (
        <span>-</span>
      )}
    </Row>
  );
};

export const renderUserName = (_: any, record: User) => {
  const name = `${record.name} ${record.last_name}`;

  return (
    <Tooltip title={name}>
      <Span ellipsis width={200} lineHeight={1.5}>
        {name}
      </Span>
    </Tooltip>
  );
};

export const renderPaymentType = (value: number) => {
  const name = PAYMENT_METHODS[value];

  if (name) {
    return name.toUpperCase();
  }

  return "-";
};

export const renderVoucher = (_: any, record: any) => {
  if (!record.voucher) {
    return (
      <Row justify="center" align="middle">
        -
      </Row>
    );
  }

  if (record.voucher.file === null) {
    return (
      <Row justify="center" align="middle">
        <Popover title="Texto" content={record.voucher.text} trigger="click">
          <Button type="link" style={{ padding: 0 }}>
            Ver comprobante
          </Button>
        </Popover>
      </Row>
    );
  }

  if (record.voucher.file !== null && record.voucher.text !== "") {
    return (
      <Row justify="center" align="middle">
        <Popover
          title="Texto (click para ver archivo)"
          content={record.voucher.text}
        >
          <a href={record.voucher.file?.url} rel="noreferrer" target="_blank">
            <Button type="link" style={{ padding: 0 }}>
              Ver comprobante
            </Button>
          </a>
        </Popover>
      </Row>
    );
  }

  return (
    <Row justify="center" align="middle">
      <a href={record.voucher.file?.url} rel="noreferrer" target="_blank">
        <Button type="link" style={{ padding: 0 }}>
          Ver comprobante
        </Button>
      </a>
    </Row>
  );
};

export const renderUserLink = (value: number) => (
  <Tooltip title="Ver usuario">
    <Row justify="center" align="middle">
      <Link to={`${USERS_ROUTE}/${value}`}>{value}</Link>
    </Row>
  </Tooltip>
);

export const renderCenter = (value: string) => (
  <Row justify="center" align="middle">
    {value}
  </Row>
);

export const renderTextEmpty = (value?: string) => {
  return value && value !== "" ? value : "-";
};
