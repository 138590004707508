import { createEvent } from "@cobuildlab/react-simple-state";
import { DownloadPagination } from "../../shared/types";

export const OnFetchDownloads = createEvent<DownloadPagination>();
export const OnFetchDownloadsError = createEvent();

export const OnUpdateDownload = createEvent();
export const OnUpdateDownloadError = createEvent();

export const OnCreateDownload = createEvent();
export const OnCreateDownloadError = createEvent();

export const OnDeleteDownload = createEvent();
export const OnDeleteDownloadError = createEvent();
