import { LoginData } from "./login-types";
import { API_URL } from "./../../shared/constants";

const loginUrl = `${API_URL}/api/auth/login/admin`;

export const login = (data: LoginData) => {
  return fetch(loginUrl, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  });
};
