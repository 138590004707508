import { api, apiUrl } from "./../../api/index";

const url = `${apiUrl}/versions`;

export const fetchVersions = async () => {
  return new Promise(async (resolve, reject) => {
    return api(url, {
      method: "GET",
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          reject(res.statusText);
        }
      })
      .then((json) => resolve(json.data));
  });
};

export const createVersion = async (data: {
  name: string;
  type: string;
  description: string;
}) => {
  return api(`${url}/create`, {
    method: "POST",
    body: JSON.stringify(data),
  });
};
