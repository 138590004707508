import { FC, ReactNode } from "react";
import { Route as RouterRoute, Redirect } from "react-router-dom";
import { PERMISSIONS_URL } from "../shared/constants";
import { checkUserPermission, getTokens } from "../shared/utils";

import { useAuth } from "./AuthProvider";

interface Props {
  component: ReactNode;
  needAuth: boolean;
  exact: boolean;
  path: string;
  redirectTo?: string;
}

const Route: FC<Props> = ({
  component: Component,
  needAuth,
  redirectTo,
  ...rest
}) => {
  const tokens = getTokens();
  const { user } = useAuth();

  return (
    <RouterRoute
      {...rest}
      render={(routeProps) => {
        let havePermission;

        if (routeProps.history.location.pathname === "/") {
          havePermission = true;
        } else {
          havePermission =
            user &&
            checkUserPermission(routeProps.history.location.pathname, user);
        }

        if (havePermission) {
          return needAuth && !tokens.token ? (
            <Redirect
              to={{
                pathname: redirectTo ? redirectTo : "/",
                state: { referrer: routeProps.location },
              }}
            />
          ) : tokens.token && routeProps.history.location.pathname === "/" ? (
            <Redirect
              to={{
                pathname: "/dashboard",
                state: { referrer: routeProps.location },
              }}
            />
          ) : (
            // @ts-ignore
            <Component {...routeProps} {...rest} />
          );
        }

        if (user) {
          const firstPermission = user?.permissions?.split(",")[0];
          const url = firstPermission ? PERMISSIONS_URL[firstPermission] : null;

          if (url) {
            return (
              <Redirect
                to={{
                  pathname: url,
                  state: { referrer: routeProps.location },
                }}
              />
            );
          }
        }

        return null;
      }}
    />
  );
};

export default Route;
