import { createEvent } from "@cobuildlab/react-simple-state";
import { TutorialPagination } from "../../shared/types";

export const OnFetchTutorials = createEvent<TutorialPagination>();
export const OnFetchTutorialsError = createEvent();

export const OnUpdateTutorial = createEvent();
export const OnUpdateTutorialError = createEvent();

export const OnCreateTutorial = createEvent();
export const OnCreateTutorialError = createEvent();

export const OnDeleteTutorial = createEvent();
export const OnDeleteTutorialError = createEvent();
