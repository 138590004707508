import { api, apiUrl } from "../../api/index";

const url = `${apiUrl}/downloads`;
export const fileUrl = `${apiUrl}/files`;

export const fetchDownloads = () => {
  return api(url, {
    method: "GET",
  });
};

export const createDownload = ({
  payload,
}: {
  payload: { title: string; url: string };
}) => {
  return api(`${url}/create`, {
    method: "POST",
    body: JSON.stringify(payload),
  });
};

export const updateDownload = ({
  id,
  payload,
}: {
  id: number;
  payload: object;
}) => {
  return api(`${url}/update/${id}`, {
    method: "PUT",
    body: JSON.stringify(payload),
  });
};

export const deleteDownload = ({ id }: { id: number }) => {
  return api(`${url}/destroy/${id}`, {
    method: "DELETE",
  });
};
