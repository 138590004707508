import { api, apiUrl } from "./../../api/index";

const url = `${apiUrl}/reports`;

export const fetchReport = (data: {
  report: string;
  page: number;
  order: string;
  orderType: string;
  pageSize?: number;
  search?: string;
}) => {
  return api(
    `${url}/${data.report}?page=${data.page}&per_page=${
      data.pageSize || 10
    }&order=${data.order}&order_type=${data.orderType}${
      data.search !== "" ? `&search=${data.search}` : ""
    }`,
    {
      method: "GET",
    }
  );
};
