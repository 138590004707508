import { NotificationData } from "./notifications-types";
import {
  createNotification,
  deleteNotification,
  fetchMassiveNotifications,
} from "./notifications-services";
import {
  OnCreateNotification,
  OnCreateNotificationError,
  OnDeleteNotification,
  OnDeleteNotificationError,
  OnFetchMassiveNotifications,
  OnFetchMassiveNotificationsError,
} from "./notifications-events";
import { handleError } from "./../../shared/utils";

export const createNotificationAction = async (data: NotificationData) => {
  let response;

  try {
    response = await createNotification(data);
  } catch (error) {
    handleError(error);
    OnCreateNotificationError.dispatch(error);
  }

  if (response?.ok) {
    const json = await response.json();

    OnCreateNotification.dispatch(json);
  } else {
    handleError({ message: response?.statusText });
    OnCreateNotificationError.dispatch(response?.statusText);
  }
};

export const fetchMassiveNotificationsAction = async () => {
  let response;

  try {
    response = await fetchMassiveNotifications();
  } catch (error) {
    handleError(error);
    OnFetchMassiveNotificationsError.dispatch(error);
  }

  if (response?.ok) {
    const json = await response.json();

    OnFetchMassiveNotifications.dispatch(json);
  } else {
    handleError({ message: response?.statusText });
    OnFetchMassiveNotificationsError.dispatch(response?.statusText);
  }
};

export const deleteNotificationAction = async (id: number) => {
  let response;

  try {
    response = await deleteNotification(id);
  } catch (error) {
    handleError(error);
    OnDeleteNotificationError.dispatch(error);
  }

  if (response?.ok) {
    const json = await response.json();

    OnDeleteNotification.dispatch(json);
  } else {
    handleError({ message: response?.statusText });
    OnDeleteNotificationError.dispatch(response?.statusText);
  }
};
