import { api, apiUrl } from "./../../api/index";

const goldUrl = `${apiUrl}/gold/subsidies`;
const cryptoGoldUrl = `${apiUrl}/crypto_gold/subsidies?crypto=bitcoin`;

export const fetchGoldSubsidies = () => {
  return api(goldUrl, {
    method: "GET",
  });
};

export const fetchCryptoGoldSubsidies = () => {
  return api(cryptoGoldUrl, {
    method: "GET",
  });
};
