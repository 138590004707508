import { fetchKpis } from "./kpis";
import { OnFetchKpisError, OnFetchKpis } from "./kpis-events";

export const fetchKpisAction = async () => {
  let response;

  try {
    response = await fetchKpis();
  } catch (error) {
    OnFetchKpisError.dispatch(error);
  }

  if (response) {
    const json = await response.json();

    OnFetchKpis.dispatch(json);
  }
};
