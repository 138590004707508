import { User } from "@devsplan/wealthclub-shared";
import { notification } from "antd";
import moment from 'moment';
import {
  APP_DOMAIN,
  EMAIL_KEY,
  PERMISSIONS,
  REFRESH_TOKEN_KEY,
  TOKEN_KEY,
} from "./constants";

export const setTokens = (token: string, refreshToken = "", email = "") => {
  localStorage.setItem(TOKEN_KEY, token);

  if (refreshToken !== "") {
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
  }

  if (email !== "") {
    localStorage.setItem(EMAIL_KEY, email);
  }
};

export const getTokens = () => {
  return {
    token: localStorage.getItem(TOKEN_KEY),
    refreshToken: localStorage.getItem(REFRESH_TOKEN_KEY),
    email: localStorage.getItem(EMAIL_KEY),
  };
};

export const removeTokens = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(REFRESH_TOKEN_KEY);
  localStorage.removeItem(EMAIL_KEY);
};

export const handleError = (error: any) => {
  console.error(error);
  notification.error({
    message: "Error",
    description: error.message,
  });
};

/**
 * setFormatCurrency
 *
 * @param {number} number number to set format currency
 * @returns {string} number formatted
 */
export const setFormatCurrency = (
  number: number | undefined | null
): string => {
  if (!number) return "$0";

  const format = new Intl.NumberFormat().format(number);

  return `$${format}`;
};

export const handleResponseError = (response: Response) => {
  if (!response.ok) {
    handleError({
      message: response.statusText,
    });
  }
};

export const checkUserPermission = (url: string, user: User): boolean => {
  const userPermissions = user?.permissions?.split(",");
  const permissionUrl = PERMISSIONS[url.replace(/\d+/g, "").replace(/\/$/, "")]; // esto evita las url con id's
  let havePermission = false;

  if (permissionUrl) {
    havePermission = userPermissions?.indexOf(permissionUrl) !== -1;
  }

  return user.permissions === "all" || havePermission;
};

export const goToViewGoldMatrix = (id: number) => {
  window.location.replace(`${APP_DOMAIN}/gold/${id}`);
};
export const sortData = (data: any) => {
  return data.sort((x: any, y: any) => {
    const dateA = moment(x.created_at);
    const dateB = moment(y.created_at);

    return dateB.diff(dateA, "seconds");
  });
};

export const generateFileCsv = (rows: string[][]) => {
  const content =
    "data:text/csv;charset=utf-8," + rows.map((e) => e.join(",")).join("\n");

  const encodedUri = encodeURI(content);

  window.open(encodedUri);
};
