import { createEvent } from "@cobuildlab/react-simple-state";
import { Ticket } from "@devsplan/wealthclub-shared";

export const OnFetchTickets = createEvent<Ticket[]>();
export const OnFetchTicketsError = createEvent();

export const OnDeleteTicket = createEvent();
export const OnDeleteTicketError = createEvent();

export const OnUpdateTicket = createEvent();
export const OnUpdateTicketError = createEvent();
