import React, { FC } from "react";
import { Pagination, Row } from "antd";
import { Pagination as PaginationType } from "../shared/types";

interface Props {
  pagination: PaginationType;
  onChangePage(page: number, pageSize: number): void;
}

const TablePagination: FC<Props> = ({ pagination, onChangePage }) => {
  return (
    <Row justify="end" style={{ marginTop: 16 }}>
      <Pagination
        current={pagination?.current_page}
        total={pagination?.total}
        onChange={(page, pageSize) => {
          onChangePage(page, pageSize || 10);
        }}
      />
    </Row>
  );
};

export default TablePagination;
